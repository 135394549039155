<template>
  <div class="modal">
    <div class="modal__body">
      <div class="modal__methods">
        <div class="modal__methods-title">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.3" d="M20.5 14C21.3284 14 22 13.3284 22 12.5C22 11.6716 21.3284 11 20.5 11C19.6716 11 19 11.6716 19 12.5C19 13.3284 19.6716 14 20.5 14Z" fill="white"/>
            <path opacity="0.3" d="M18.8216 1.04868L3.3668 5.18979C2.83334 5.33273 2.51676 5.88107 2.6597 6.41453L3.95379 11.2442C4.09673 11.7776 4.64507 12.0942 5.17854 11.9513L20.6334 7.81017C21.1668 7.66722 21.4834 7.11889 21.3405 6.58542L20.0464 1.75579C19.9034 1.22233 19.3551 0.905743 18.8216 1.04868Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M22 9.33682C21.5454 9.12085 21.0368 9 20.5 9C18.567 9 17 10.567 17 12.5C17 14.433 18.567 16 20.5 16C21.0368 16 21.5454 15.8792 22 15.6632V18C22 19.1046 21.1046 20 20 20H4C2.89543 20 2 19.1046 2 18V6C2 4.89543 2.89543 4 4 4H20C21.1046 4 22 4.89543 22 6V9.33682Z" fill="white"/>
          </svg>
          {{ $t('deposit.title') }}
        </div>
        <div class="modal__methods-label">{{ $t('deposit.select_method') }}</div>

        <template v-if="config.getPaymentConfig.freekassa">
          <input type="radio" v-model="method" id="method1" value="freekassa" name="method">
          <label for="method1" class="modal__method">
            <span class="modal__method-title">
              <span class="square">
                <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.3035 0.764253C10.5718 0.448647 11.0451 0.410269 11.3607 0.678534C11.6763 0.946798 11.7147 1.42012 11.4464 1.73572L5.07144 9.23572C4.79896 9.55628 4.31616 9.59005 4.00171 9.31055L0.626712 6.31055C0.317125 6.03536 0.289239 5.5613 0.564427 5.25172C0.839616 4.94213 1.31367 4.91424 1.62326 5.18943L4.42515 7.68L10.3035 0.764253Z" fill="white"/>
                </svg>
              </span>
              <span class="label">Freekassa</span>
            </span>
            <span class="modal__method-shops">
              <span class="modal__method-shop">
               <img src="/img/p2.png" alt="">
              </span>
              <span class="modal__method-shop">
                <img src="/img/p1.png" alt="">
              </span>
              <span class="modal__method-shop">
                <img src="/img/p3.png" alt="">
              </span>
              <span class="modal__method-shop">
                <img src="/img/p4.png" alt="">
              </span>
            </span>
          </label>
        </template>
        <template v-if="config.getPaymentConfig.enot">
          <input type="radio" v-model="method" value="enot" id="method2" name="method">
          <label for="method2" class="modal__method">
          <span class="modal__method-title">
            <span class="square">
              <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.3035 0.764253C10.5718 0.448647 11.0451 0.410269 11.3607 0.678534C11.6763 0.946798 11.7147 1.42012 11.4464 1.73572L5.07144 9.23572C4.79896 9.55628 4.31616 9.59005 4.00171 9.31055L0.626712 6.31055C0.317125 6.03536 0.289239 5.5613 0.564427 5.25172C0.839616 4.94213 1.31367 4.91424 1.62326 5.18943L4.42515 7.68L10.3035 0.764253Z" fill="white"/>
              </svg>
            </span>
            <span class="label">Enot.io</span>
          </span>
            <span class="modal__method-shops">
            <span class="modal__method-shop">
             <img src="/img/p5.png" alt="">
            </span>
            <span class="modal__method-shop">
              <img src="/img/p6.png" alt="">
            </span>
            <span class="modal__method-shop">
              <img src="/img/p7.png" alt="">
            </span>
            <span class="modal__method-shop">
              <img src="/img/p1.png" alt="">
            </span>
          </span>
          </label>
        </template>

        <div class="skinback" @click="skinback">
          <div class="skinback-icon">
            <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17.9611 0.878906C17.7346 0.996094 17.6565 1.1875 17.6565 1.61719V1.96875L12.4533 1.97656L7.25411 1.98828L6.69161 3.66797C6.38302 4.58984 6.1213 5.37891 6.10958 5.41406C6.09005 5.48047 6.43771 5.48438 13.0432 5.48438H20.0002V3.96094C20.0002 2.50781 19.9963 2.42578 19.9221 2.28125C19.801 2.04688 19.6174 1.96875 19.1799 1.96875H18.8283V1.61719C18.8283 1.17969 18.7502 0.996094 18.5158 0.875C18.3244 0.777344 18.1486 0.777344 17.9611 0.878906Z" fill="white"/>
              <path d="M1.47656 2.05078C1.30859 2.13672 1.17187 2.36719 1.17187 2.55469C1.17187 2.85156 0.882812 3.14062 0.585937 3.14062C0.390625 3.14062 0.167969 3.27734 0.078125 3.45312C0.0078125 3.59375 0 3.67969 0 4.54688V5.48438L1.25781 5.47656L2.51172 5.46484L3.07422 3.78516C3.38281 2.85938 3.64453 2.07422 3.65625 2.03516C3.67578 1.97266 3.60937 1.96875 2.64844 1.96875C1.70312 1.97266 1.60937 1.97656 1.47656 2.05078Z" fill="white"/>
              <path d="M4.90234 2.01562C4.86719 2.10547 3.75 5.46484 3.75 5.47656C3.75 5.48047 4 5.48047 4.30469 5.47656L4.85547 5.46484L5.41797 3.78516C5.72656 2.85938 5.98828 2.07422 6 2.03516C6.01953 1.97266 5.97656 1.96875 5.46875 1.96875C5.06641 1.96875 4.91406 1.98047 4.90234 2.01562Z" fill="white"/>
              <path d="M0 7.00782C0 7.57032 0.144531 7.76563 0.613281 7.83204C0.855469 7.86719 1.01172 7.96485 1.11328 8.15235L1.18359 8.28907L0.691406 11.7773C0.417969 13.6992 0.195312 15.3672 0.195312 15.4922C0.195312 16.2656 0.8125 17.0039 1.58984 17.1641C1.71875 17.1914 2.28906 17.2031 3.19141 17.1953C4.38672 17.1836 4.62109 17.1719 4.79297 17.1133C5.21484 16.9727 5.63281 16.5938 5.83594 16.168C5.94922 15.9258 5.97656 15.7734 6.20703 13.9609L6.38672 12.5352L7.77344 12.5156C9.09375 12.4961 9.17188 12.4883 9.37891 12.4063C9.84375 12.2188 10.1562 11.9375 10.3555 11.5195C10.5195 11.1719 10.5469 10.957 10.5469 9.9336V9H13.8477C17.4023 9 17.3008 9.00391 17.5 8.80079C17.5703 8.72266 18.5938 6.73829 18.5938 6.67579C18.5938 6.66407 14.4102 6.65625 9.29688 6.65625H0V7.00782ZM7.05469 9.06641C6.98438 9.30469 6.94922 9.57032 6.94922 9.87891C6.95312 10.4023 7.02344 10.7109 7.24609 11.1484L7.35156 11.3555L7.04297 11.3359C6.67188 11.3125 6.43359 11.2148 6.21094 10.9922C5.59766 10.3789 5.83984 9.35547 6.66016 9.07032C6.92578 8.97657 7.08203 8.97657 7.05469 9.06641ZM9.375 9.91797C9.375 10.9023 9.35938 11.0078 9.17578 11.1758C9.00391 11.332 8.90234 11.3281 8.69922 11.1445C8.25391 10.7539 8.05469 10.2422 8.125 9.67188C8.14453 9.51563 8.19922 9.30079 8.24609 9.19532L8.32812 9H8.85156H9.375V9.91797Z" fill="white"/>
            </svg>
          </div>
          <span>{{ $t('deposit.deposit_skins') }}</span>
          <div v-show="loading" class="loader">
            <img src="/img/load.png" alt="">
          </div>
        </div>

        <div class="ranges">
          <div class="ranges-inputs">
            <div class="input">
              <label for="">
                <svg width="25" height="18" viewBox="0 0 25 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.2812 3.27271C11.9891 3.27271 10.9375 4.37398 10.9375 5.72725C10.9375 7.08052 11.9891 8.1818 13.2812 8.1818C14.5734 8.1818 15.625 7.08052 15.625 5.72725C15.625 4.37398 14.5734 3.27271 13.2812 3.27271ZM13.2812 6.54543C12.8516 6.54543 12.5 6.17889 12.5 5.72725C12.5 5.27561 12.8516 4.90907 13.2812 4.90907C13.7109 4.90907 14.0625 5.27561 14.0625 5.72725C14.0625 6.17889 13.7109 6.54543 13.2812 6.54543ZM17.9687 9.81816C16.6766 9.81816 15.625 10.9194 15.625 12.2727C15.625 13.626 16.6766 14.7273 17.9687 14.7273C19.2609 14.7273 20.3125 13.626 20.3125 12.2727C20.3125 10.9194 19.2609 9.81816 17.9687 9.81816ZM17.9687 13.0909C17.5391 13.0909 17.1875 12.7243 17.1875 12.2727C17.1875 11.8211 17.5391 11.4545 17.9687 11.4545C18.3984 11.4545 18.75 11.8211 18.75 12.2727C18.75 12.7243 18.3984 13.0909 17.9687 13.0909ZM20.0328 3.46089C19.7016 3.17616 19.2094 3.2187 18.9328 3.56561L11.1203 13.3838C10.8422 13.7323 10.8875 14.2494 11.2187 14.5374C11.3589 14.6604 11.5359 14.7276 11.7187 14.7273C11.9422 14.7273 12.1641 14.6274 12.3187 14.4327L20.1312 4.61452C20.4078 4.26761 20.3625 3.75052 20.0328 3.46089ZM6.25 3.27271H7.8125V4.90907H6.25V3.27271ZM6.25 13.0909H7.8125V14.7273H6.25V13.0909ZM6.25 9.81816H7.8125V11.4545H6.25V9.81816ZM6.25 6.54543H7.8125V8.1818H6.25V6.54543Z" fill="white"/>
                  <path d="M23.4375 0H1.5625C0.685938 0 0 0.718364 0 1.63636V5.72727C0 6.17891 0.35 6.54545 0.78125 6.54545C2.07344 6.54545 3.125 7.64673 3.125 9C3.125 10.3533 2.07344 11.4545 0.78125 11.4545C0.35 11.4545 0 11.8211 0 12.2727V16.3636C0 17.2816 0.685938 18 1.5625 18H23.4375C24.3141 18 25 17.2816 25 16.3636V1.63636C25 0.718364 24.3141 0 23.4375 0ZM23.4375 16.3636H1.5625V13.0091C3.34375 12.6295 4.6875 10.9767 4.6875 9C4.6875 7.02327 3.34375 5.37218 1.5625 4.99091V1.63636H23.4375V16.3636Z" fill="white"/>
                </svg>
                {{ $t('deposit.promocode') }}:
              </label>

            </div>

<!--            <div class="label_s">-->
<!--              +10% к сумме пополнения-->
<!--            </div>-->
            <div class="input_wrp">
              <input type="text" v-model="promocode" :placeholder="$t('deposit.promocode')">
              <!-- <input :disabled="bonus && bonus.type === 'percent'" v-model="promocode" type="text" :placeholder="$t('deposit.promocode')"> -->
              <button :disabled="!promocode || loadingPromocode" :class="{'aproove': bonus && bonus.type === 'percent'}"  @click="usePromocode">
              <!-- <button :class="{'aproove': bonus && bonus.type === 'percent'}" @click="usePromocode" :disabled="!promocode || loadingPromocode || (bonus && bonus.type === 'percent')"> -->
                <BlockLoader v-if="loadingPromocode"></BlockLoader>
                <!-- <template v-if="!bonus || bonus.type === 'deposit'"> -->
                  {{ $t('deposit.active') }}
                <!-- </template>
                <template v-else>
                  Применен
                </template> -->
              </button>
            </div>

          </div>
        </div>
      </div>



      <div class="form">
        <div class="close" @click="toggleDeposit(false)">
          <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.3032 7.92888L7.92881 20.3033C7.44065 20.7914 7.44065 21.5829 7.92881 22.071C8.41696 22.5592 9.20842 22.5592 9.69657 22.071L22.0709 9.69665C22.5591 9.20849 22.5591 8.41704 22.0709 7.92888C21.5828 7.44073 20.7913 7.44073 20.3032 7.92888Z" fill="white"/>
            <path opacity="0.3" d="M22.0712 20.3033L9.69687 7.92893C9.20872 7.44077 8.41726 7.44077 7.9291 7.92893C7.44095 8.41708 7.44095 9.20854 7.9291 9.69669L20.3035 22.0711C20.7916 22.5592 21.5831 22.5592 22.0712 22.0711C22.5594 21.5829 22.5594 20.7915 22.0712 20.3033Z" fill="white"/>
          </svg>
        </div>
        <div class="form-title">
          {{ $t('deposit.pay') }}
        </div>

        <div v-if="method === 'freekassa'">
            <div class="custom-title">
              {{ $t('deposit.contacts') }}
            </div>
            <div class="email-block">
              <label for="">Email</label>
              <input type="email" v-model="email" placeholder="your@email.com">
            </div>

            <div class="remember">
              <input type="checkbox" v-model="rememberEmail" name="remember" id="remember">
              <label for="remember">
                <span class="square">
                  <svg data-v-4ce7f0d8="" width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path data-v-4ce7f0d8="" d="M10.3035 0.764253C10.5718 0.448647 11.0451 0.410269 11.3607 0.678534C11.6763 0.946798 11.7147 1.42012 11.4464 1.73572L5.07144 9.23572C4.79896 9.55628 4.31616 9.59005 4.00171 9.31055L0.626712 6.31055C0.317125 6.03536 0.289239 5.5613 0.564427 5.25172C0.839616 4.94213 1.31367 4.91424 1.62326 5.18943L4.42515 7.68L10.3035 0.764253Z" fill="white"></path></svg>
                </span>
                <span class="name">{{ $t('open.remember_email') }}</span>
              </label>
            </div>
          </div>

        <template v-if="method">
          <div class="custom-title">
            {{ $t('deposit.amount') }}
          </div>

          <div class="ranges">
            <div class="ranges-list">
              <div class="range-item" :class="{'active': sum === 100}" @click="sum = 100">
                ₽100.00
              </div>
              <div class="range-item" :class="{'active': sum === 200}" @click="sum = 200">
                ₽200.00
              </div>
              <div class="range-item" :class="{'active': sum === 300}" @click="sum = 300">
                ₽300.00
              </div>
              <div class="range-item" :class="{'active': sum === 500}" @click="sum = 500">
                ₽500.00
              </div>
              <div class="range-item" :class="{'active': sum === 1500}" @click="sum = 1500">
                ₽1500.00
              </div>
              <div class="range-item" :class="{'active': sum === 3000}" @click="sum = 3000">
                ₽3000.00
              </div>
            </div>
            <div class="ranges-inputs">
              <div class="input">
                <label for="">{{ $t('deposit.amount_set') }}:</label>
                <div class="input__body">
                  <div class="pre">₽</div>
                  <input v-model.number="sum" type="number" placeholder="0.00">

                  <div class="input__bonus" v-if="bonus && bonus.prize && bonus.type === 'percent'">
                    +{{ bonus.prize }}% к пополнению
                  </div>
                </div>
              </div>
              <div class="input">
                <label for="">{{ $t('deposit.gives') }}:</label>
                <div class="input__body">
                  <div class="pre" style="color: #fff">₽</div>
                  <input readonly :value="getSum" type="number">
                </div>
              </div>
              <button @click="deposit">{{ $t('deposit.go') }}</button>
            </div>
          </div>
        </template>

        <div class="select__method" v-else>
          {{ $t('deposit.select_payes') }}
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import { CREATE_FREEKASSA, CREATE_ENOT, CREATE_SKINBACK, USE_PROMOCODE } from '../../queries/mutations'
import { mapActions, mapGetters } from 'vuex';
import BlockLoader from '../BlockLoader'
import {GET_BONUS} from "@/queries";
export default {
  data () {
    return {
      aproovePromocode: false,
      loading: false,
      loadingPromocode: false,
      promocode: '',
      sum: 0,
      email: null,
      rememberEmail: false,
      method: null
    }
  },
  apollo: {
    bonus: {
      fetchPolicy: 'network-only',
      query: GET_BONUS,
      update: data => data.getPaymentBonus
    }
  },
  methods: {
    ...mapActions({
      toggleDeposit: 'main/toggleDeposit'
    }),
    async usePromocode () {
      this.loadingPromocode = true
      await this.$apollo.mutate({
        mutation: USE_PROMOCODE,
        variables: {
          code: this.promocode
        }
      }).then(({ data: { usePromocode: { prize, type, code } } }) => {
        if (type === 'percent') {
          this.$toast.success(this.$t('deposit.success_percent', { price: prize }));
          this.bonus = {
            prize,
            type,
            code
          }
        } else {
          this.promocode = null
          this.$toast.success(this.$t('deposit.success', { price: prize }));
        }
      }).catch((error) => {
        this.promocode = ''
        error.graphQLErrors.map(item => {
          this.$toast.error(item.message);
        })
      }).finally(() => {
        this.loadingPromocode = false
      })
    },
    async deposit () {
      if (!this.sum) {
        return this.$toast.error(this.$t('deposit.amount_set'));
      }
      if (this.email && this.rememberEmail) {
        localStorage.setItem('email', this.email)
      }

      if (this.rememberEmail === false) {
        localStorage.removeItem('email');
      }

      await this[this.method]();

      this.loading = false
    },
    async freekassa () {
      if (!this.email) {
        return this.$toast.error(this.$t('deposit.input_email'))
      }
      await this.$apollo.mutate({
        variables: {
          sum: this.sum,
          email: this.email
        },
        mutation: CREATE_FREEKASSA,
      }).then((data) => {
        window.open(data.data.freekassaPayCreate, '_blank')
        this.toggleDeposit(false)
      }).catch((error) => {
        error.graphQLErrors.map(item => {
          this.$toast.error(item.message);
        })
      })
    },
    async enot () {
      await this.$apollo.mutate({
        variables: {
          sum: this.sum
        },
        mutation: CREATE_ENOT,
      }).then((data) => {
        window.open(data.data.enotPayCreate, '_blank')
        this.toggleDeposit(false)
      }).catch((error) => {
        error.graphQLErrors.map(item => {
          this.$toast.error(item.message);
        })
      })
    },
    async skinback () {
      this.loading = true
      await this.$apollo.mutate({
        mutation: CREATE_SKINBACK,
      }).then((data) => {
        window.open(data.data.skinsbackPayCreate, '_blank')
        this.toggleDeposit(false)
      }).catch((error) => {
        error.graphQLErrors.map(item => {
          this.$toast.error(item.message);
        })
      })
      this.loading = false
    }
  },
  components: {
    BlockLoader
  },
  mounted() {
    if (this.config.getPaymentConfig.freekassa) {
      this.method = 'freekassa'
    } else if (this.config.getPaymentConfig.enot) {
      this.method = 'enot'
    }
    const email = localStorage.getItem('email') ?? null;

    if (email) {
      this.rememberEmail = true;
      this.email = email
    }
  },
  watch: {
    bonus () {
      if (this.bonus && this.bonus.type === 'percent') {
        //this.promocode = this.bonus.code;
      }
    }
  },
  computed: {
    ...mapGetters({
      config: 'main/config'
    }),
    getSum () {
      if (!this.sum) return 0.00;
      let sum = this.sum
      if (this.bonus && this.bonus.type === 'percent') {
        sum = sum + (sum * Number(this.bonus.prize) / 100);
      }

      return parseFloat(sum).toFixed(2)
    }
  }
}
</script>
<style scoped lang="scss">
.remember {
  display: flex;
  cursor: pointer;
  width: 500px;
  margin: 0 auto;
  max-width: 90%;
  margin-bottom: 15px;
  input {
    display: none;
  }
  label {
    display: flex;
    align-items: center;
    cursor: pointer;
    .square {
      width: 20px;
      height: 20px;
      margin-right: 15px;
      background: #272930;
      display: flex;
      align-items: center;
      transition: 0.1s;
      justify-content: center;
      border-radius: 3px;
      svg {
        transition: 0.1s;
        opacity: 0;
      }
    }
    .name {
      font-size: 14px;
      display: flex;
      align-items: center;
      line-height: 13px;
    }
  }
  input:checked + label {
    .square {
      background: #66BF57;
      svg {
        opacity: 1;
      }
    }
  }
}
.modal {
  background: #1D1E23;
  background: rgba(23, 24, 29, 0.15);
  backdrop-filter: blur(22.5px);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  .modal__body {
    width: 80%;
    background: #18191D;
    display: flex;
    @media (max-width: 1400px) {
      width: 95%;
    }
    @media (max-width: 768px) {
      width: 95%;
      height: 95vh;
      overflow-y: auto;
    }
    .modal__methods {
      width: 387px;
      background: #1D1E23;
      padding: 20px 16px;
      max-width: 90%;
      input[type="radio"] {
        display: none;
      }
      .modal__methods-title {
        margin-bottom: 26px;
        display: flex;
        align-items: center;
        svg {
          margin-right: 10px;
        }
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
      }
      .modal__methods-label {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #7A7B7E;
      }
      .modal__method {
        margin-top: 17px;
        border: 2px solid #31333B;
        display: block;
        border-radius: 4px;
        padding: 20px 18px;
        transition: 0.2s;
        cursor: pointer;
        padding-bottom: 10px;
        .modal__method-title {
          display: flex;
          width: 100%;
          align-items: center;
          .square {
            width: 18px;
            height: 18px;
            margin-right: 17px;
            transition: 0.2s;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #2F3037;
            border-radius: 4px;
            svg {
              transition: 0.2s;
              opacity: 0;
            }
          }
          .label {
            font-weight: 600;
            font-size: 22px;
            line-height: 27px;
          }
        }
        .modal__method-shops {
          display: flex;
          margin-top: 20px;
          .modal__method-shop {
            flex: 1;
            background: #26282F;
            border-radius: 4px;
            display: flex;
            svg {
              max-width: 80%;
            }
            img {
              max-width: 85%;
            }
            align-items: center;
            justify-content: center;
            height: 41px;
          }
          .modal__method-shop + .modal__method-shop {
            margin-left: 10px;
          }
        }
        &:hover {
          background: rgba(255,255,255, 0.01);
        }
      }
      input:checked + .modal__method {
        .modal__method-title {
          .square {
            svg {
              opacity: 1;
            }
            background: #66BF57;
          }
        }
      }
      .skinback {
        display: flex;
        background: linear-gradient(90deg, #5242AF 2.41%, #262339 98.58%);
        border-radius: 4px;
        align-items: center;
        padding: 12px;
        margin-top: 17px;
        transition: 0.2s;
        cursor: pointer;
        position: relative;
        .loader {
          position: absolute;
          right: 23px;
          animation: loading 1s linear infinite;
          top: calc(50% - 12px);
        }
        &:hover {
          opacity: 0.8;
        }
        .skinback-icon {
          width: 48px;
          display: flex;
          align-items: center;
          margin-right: 15px;
          justify-content: center;
          height: 48px;
          background: rgba(255,255,255, 0.1);
          border-radius: 6px;
        }
        span {
          font-weight: 600;
          font-size: 18px;
          line-height: 22px;
          @media (max-width: 1200px) {
            font-size: 15px;
          }
        }
      }
      .ranges {
         padding: 40px 0;
         width: 680px;
         margin: 0 auto;
         max-width: 100%;
        margin-top: 17px;
        padding: 20px 18px;
         border: 2px solid #31333B;
         border-radius: 4px;

         .ranges-list {
           display: flex;
           .range-item {
             padding: 7px 10px;
             background: #272930;
             border-radius: 4px;
             cursor: pointer;
             font-weight: 600;
             font-size: 14px;
             transition: 0.2s;
             line-height: 17px;
             &.active {
               background: #66BF57;
             }
             &:hover {
               background: #66BF57;
             }
           }
           .range-item + .range-item {
             margin-left: 10px;
           }
         }
         .ranges-inputs {
           display: flex;
           flex-direction: column;
           @media (max-width: 1200px) {
             flex-wrap: wrap;
           }
           .input {
             flex: 1;

             @media (max-width: 1200px) {
               margin-bottom: 10px;
               flex: unset;
               width: 100%;
               margin-left: 0 !important;
             }
             label {
               display: flex;
               align-items: center;
               svg {
                 margin-right: 10px;
               }

               width: 100%;
               font-weight: 600;
               font-size: 22px;
               line-height: 27px;
               margin-bottom: 13px;
             }

             .input__body {
               border: 1px solid #2D2E35;
               border-radius: 8px;
               display: flex;
               align-items: center;
               position: relative;
               padding: 0 19px;
               height: 48px;
               .coupons {
                 position: absolute;
                 top: calc(100% + 15px);
                 display: flex;
                 font-weight: 500;
                 font-size: 14px;
                 line-height: 17px;
                 align-items: center;
                 left: 0;
                 img {
                   margin: 0 5px;
                 }
                 width: 100%;
                 justify-content: flex-end;
               }
               .pre {
                 margin-right: 15px;
                 color: #565D69;
               }
               input {
                 width: 100%;
                 border: none;
                 height: 48px;
                 outline: none;
                 font-weight: 700;
                 font-size: 16px;
                 line-height: 19px;
                 color: #fff;
                 background: transparent;
                 &::placeholder {
                   color: #565D69;
                 }
               }

             }
           }
           .input + .input {
             margin-left: 14px;
           }
           button {
             padding: 15px 28px;
             font-weight: 700;
             font-size: 14px;
             line-height: 17px;
             text-align: center;
             position: relative;
             letter-spacing: 0.05em;
             background: #66BF57;
             border-radius: 4px;
             margin-top: 14px;
             @media (max-width: 1200px) {
               width: 100%;
               margin-top: 4px;
             }
           }
         }
        .label_s {
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          margin-top: 23px;
          color: #7A7B7E;
        }
        .input_wrp {
          display: flex;
          width: 100%;
          margin-top: 7px;
          input {
            flex-grow: 1;
            overflow: hidden;
            padding: 0px 16px;
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            font-family: 'Inter';
            margin-right: 20px;
            height: auto;
            outline: none;
            background: #26282F;
            border-radius: 4px;
            border: none;
          }
          button {
            min-width: 100px;
            font-weight: 700;
            margin: 0;
            font-size: 12px;
            padding: 14px 0px;
            line-height: 15px;
            text-align: center;
            letter-spacing: 0.05em;
            background: #66BF57;
            border-radius: 4px;
            width: 100px;
            &.aproove {
              background: #FF9900 !important;
            }
          }
        }
       }
      @media (max-width: 768px) {
        width: 100%;
        max-width: 100%;
      }
    }
    .form {
      max-height: 90vh;
      overflow-y: auto;
      overflow-x: hidden;
      position: relative;
      flex-grow: 1;
      padding-bottom: 100px;
      .select__method {
        text-align: center;
        font-size: 18px;
        padding: 10px;
        background: #FE5E3B;
      }
      .close {
        position: absolute;
        right: 44px;
        top: 37px;
        cursor: pointer;
        transition: 0.2s;
        &:hover {
          opacity: 0.5;
        }
        @media (max-width: 768px) {
          right: 25px;
          top: 40px;
          background: #2B2C33 !important;
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .form-title {
        margin-top: 40px;
        margin-bottom: 30px;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        width: 100%;
      }
      .custom-title {
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        padding: 9px 15px;
        margin: 0 auto;
        background: #18191D;
        border: 1px solid #25262D;
        border-radius: 100px;
        width: fit-content;
        position: relative;
        display: flex;
        align-items: center;
        &::after, &::before {
          position: absolute;
          content: "";
          width: 2000px;
          height: 1px;
          background: #25262D;
        }
        &::after {
          right: 100%;
        }
        &::before {
          left: 100%;
        }
      }

      .email-block {
        padding: 21px 0;
        width: 500px;
        margin: 0 auto;
        max-width: 90%;
        label {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          margin-bottom: 16px;
          display: block;
        }
        input {
          width: 100%;
          background: #1D1E23;
          border-radius: 6px;
          padding: 15px 20px;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #fff;
          outline: none;
          border: none;
          &::placeholder {
            color: #484A54;
          }
        }
      }
      .email-checkbox {
        margin: 0 auto;
        max-width: 90%;
        input {
          display: none;
        }
        label {
          display: flex;
          align-items: center;
          width: 100%;
          cursor: pointer;
          margin-top: 10px;
          .square {
            background: #2F3037;
            border-radius: 4px;
            margin-right: 6px;
            width: 18px;
            display: flex;
            align-items: center;
            transition: 0.2s;
            justify-content: center;
            height: 18px;
            svg {
              transition: 0.2s;
              opacity: 0;
            }
          }
          .name {
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
          }
        }
        input:checked + label {
          .square {
            background: #66BF57;
            svg {
              opacity: 1;
            }
          }
        }
      }
      .ranges {
        padding: 40px 0;
        width: 680px;
        margin: 0 auto;
        max-width: 90%;
        @media (max-width: 1200px) {
          padding: 20px 0;
        }

        .ranges-list {
          display: flex;
          @media (max-width: 950px) {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 10px;
          }
          .range-item {
            padding: 7px 10px;
            background: #272930;
            border-radius: 4px;
            cursor: pointer;
            font-weight: 600;
            font-size: 14px;
            transition: 0.2s;
            line-height: 17px;
            &.active {
              background: #66BF57;
            }
            &:hover {
              background: #66BF57;
            }
          }
          .range-item + .range-item {
            margin-left: 10px;
            @media (max-width: 950px) {
              margin-left: 0;
            }
          }
        }
        .ranges-inputs {
          display: flex;
          align-items: flex-end;
          margin-top: 20px;
          @media (max-width: 1200px) {
            flex-wrap: wrap;
          }
          .input {
            flex: 1;

            @media (max-width: 1200px) {
              margin-bottom: 10px;
              flex: unset;
              width: 100%;
              margin-left: 0 !important;
            }
            label {
              display: block;
              width: 100%;
              font-weight: 500;
              font-size: 14px;
              line-height: 17px;
              color: #434651;
              margin-bottom: 13px;
            }

            .input__body {
              border: 1px solid #2D2E35;
              border-radius: 8px;
              display: flex;
              align-items: center;
              position: relative;
              padding: 0 19px;
              .input__bonus {
                font-size: 13px;
                white-space: nowrap;
                color: #434651
              }
              .coupons {
                position: absolute;
                top: calc(100% + 15px);
                display: flex;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                align-items: center;
                left: 0;
                img {
                  margin: 0 5px;
                }
                width: 100%;
                justify-content: flex-end;
              }
              .pre {
                margin-right: 15px;
                color: #565D69;
              }
              input {
                width: 100%;
                border: none;
                height: 48px;
                outline: none;
                font-weight: 700;
                font-size: 16px;
                line-height: 19px;
                color: #fff;
                background: transparent;
                &::placeholder {
                  color: #565D69;
                }
              }
            }
          }
          .input + .input {
            margin-left: 14px;
          }
          button {
            padding: 15px 28px;
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            position: relative;
            letter-spacing: 0.05em;
            background: #66BF57;
            border-radius: 4px;
            margin-left: 14px;
            @media (max-width: 1200px) {
              width: 100%;
              margin-left: 0;
            }
          }
        }
      }
      .promocode {

        margin-top: 30px;
        @media (max-width: 1200px) {
          margin-top: 0;
        }
        .ranges {
          padding-top: 20px;
        }
        .ranges-inputs {
          margin-top: 0;
        }
      }
      @media (max-width: 768px) {
        width: 100%;
        max-width: 100%;
        position: static;
        overflow-y: hidden;
        margin-bottom: 30px;
      }
    }

    @media (max-width: 768px) {
      flex-wrap: wrap;

    }
  }
}
@keyframes loading {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
