<template>
  <div class="case__finish">
    <div class="case__finish-congrat">
      <img src="/img/congrat.png" alt="">
    </div>
    <div class="case__finish-block">
      <div class="case__finish-block_case">
        <strong>{{ $t('open.congratulate') }}</strong>
        <span>{{ $t('open.you_win') }}</span>
        <div class="case__finish-block_item" :style="{
          background: `linear-gradient(180deg, ${hexToRgbA('#' + item.item.color, 0.05)} 0%, ${hexToRgbA('#' + item.item.color, 0.25)} 100%), url('/img/item-logo.svg') no-repeat center / 100% 100%, url('/img/bg_gradient.png') no-repeat center / 100% 100%`,
          '--after': `${hexToRgbA('#' + item.item.color, 1)}`
        }">
          <div class="case__finish-block_item-img">
            <img :src="`${item.item.getImage}/120fx120f`" alt="">
          </div>
          <span>{{ item.item.name }}</span>
        </div>
      </div>

      <button v-if="!caseItem.isEvent" class="repeat" @click="$emit('close')">
        {{ $t('open.open_more') }}
      </button>

      <button v-else class="repeat" @click="$router.push('/')">
        {{ $t('open.backToMainPage') }}
      </button>
      <div class="case__finish-control">
        <button @click="sell" :disabled="loading">
          <BlockLoader v-if="loading"></BlockLoader>
          {{ $t('open.sell_by') }} {{ parseFloat(item.price).toFixed(2) }}₽
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { SELL_ITEMS } from '@/queries/mutations'
import BlockLoader from '../BlockLoader'
import hexToRgbA from "@/helpers/hexToRgba";
export default {
  data () {
    return {
      loading: false
    }
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    caseItem: {
      type: Object,
      required: true
    }

  },
  methods: {
    async sell () {
      this.loading = true

      await this.$apollo.mutate({
        mutation: SELL_ITEMS,
        variables: {
          openIds: [this.item.id],
        },
      }).then((data) => {
        this.$toast.success(this.$t('open.success_sell', {
          amount: parseFloat(data.data.inventoryItemSell[0].price).toFixed(2)
        }));
        this.$emit('close')
      }).catch((error) => {
        error.graphQLErrors.map(item => {
          this.$toast.error(item.message);
        })
      }).finally(() => {
        this.loading = false
      })
    },
    hexToRgbA
  },
  components: {
    BlockLoader
  }
}
</script>
<style scoped lang="scss">
  .case__finish {
    display: flex;
    padding-bottom: 20px;
    position: relative;
    justify-content: center;
    margin-top: 20px;
    .case__finish-congrat {
      position: absolute;
      display: flex;
      justify-content: center;
    }
    .case__finish-block {
      margin-top: 31px;
      width: 300px;
      .case__finish-block_case {
        background: rgba(19, 20, 25, 0.01);
        backdrop-filter: blur(22.5px);
        padding: 0 50px;
        padding-top: 30px;
        position: relative;
        height: 332px;
        margin-bottom: 17px;
        &::after {
          top: 0;
          left: calc(50% - 90px);
          width: 180px;
          height: 1.6px;
          background: #66BF57;
          content: "";
          position: absolute;
          display: block;
        }
        strong {
          position: relative;
          font-weight: 600;
          font-size: 16px;
          line-height: 16px;
          text-align: center;
          margin-bottom: 14px;
          width: 100%;
          display: block;
          color: #66BF57;
          &::after {
            content: "";
            display: block;
            background: url(/img/span-green-bg.png);
            width: 71px;
            height: 36px;
            position: absolute;
            top: calc(50% - 18px);
            left: calc(50% - 39px)
          }
        }
        span {
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          width: 100%;
          text-align: center;
          display: block;
        }
        .case__finish-block_item {
          width: 100%;
          margin-top: 16px;
          height: 200px;
          //background: #131419;
          //background-image: linear-gradient(180deg, rgba(130, 69, 232, 0) 23.27%, rgba(130, 69, 232, 0.25) 100%);
          border-radius: 4px;
          position: relative;
          .case__finish-block_item-img {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            position: absolute;
            padding-bottom: 30px;
            img {
              max-width: 90%;
              max-height: 70%;
            }
          }
          span {
            bottom: 30px;
            position: absolute;
            display: block;
            width: 100%;
            text-align: center;
          }
          &::after {
            position: absolute;
            bottom: 0;
            width: 76px;
            left: calc(50% - 38px);
            border-radius: 10px;
            content: "";
            display: block;
            height: 2.35px;
            background: var(--after);
          }
        }
      }
      .repeat {
        width: 100%;
        position: relative;
        z-index: 9;
        background: #66BF57;
        border-radius: 8px;
        padding: 18px 0;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        text-transform: uppercase;
      }
      .case__finish-control {
        margin-top: 10px;
        position: relative;
        z-index: 9;
        display: flex;
        button {
          background: #FE5E3B;
          position: relative;
          border-radius: 8px;
          flex: 1;
          padding: 20px 0;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          text-align: center;
          text-transform: uppercase;
          &.upgrade {
            background: #418DFF;
          }
        }
        button + button {
          margin-left: 10px;
        }
      }
    }
  }
</style>
