<template>
  <div class="open-block">
    <div class="x-items">
      <div class="x-item" @click="countOpen = 1" :class="{'active': countOpen === 1}">1X</div>
      <div class="x-item" @click="countOpen = 2" :class="{'active': countOpen === 2}">2X</div>
      <div class="x-item" @click="countOpen = 3" :class="{'active': countOpen === 3}">3X</div>
      <div class="x-item" @click="countOpen = 4" :class="{'active': countOpen === 4}">4X</div>
      <div class="x-item" @click="countOpen = 5" :class="{'active': countOpen === 5}">5X</div>
    </div>
    <div class="label">
      {{ $t('open.count_opens') }}
    </div>

    <button :disabled="loading" @click="$emit('start')">
      <span>{{ $t('open.open_for') }}</span>
      <em>{{ getPrice }}₽</em>
      <transition name="fade">
        <BlockLoader v-if="loading"></BlockLoader>
      </transition>
    </button>

    <button class="secondary" :disabled="loading" @click="$emit('fastStart')">
      <span>{{ $t('open.open_fast') }}</span>
      <em>{{ getPrice }}₽</em>
      <transition name="fade">
        <BlockLoader v-if="loading"></BlockLoader>
      </transition>
    </button>
  </div>
</template>
<script>
import BlockLoader from '../../components/BlockLoader'
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    price: {
      type: Number,
      required: true
    }
  },
  computed: {
    getPrice () {
      return parseFloat(this.price * this.countOpen).toFixed(2)
    }
  },
  data () {
    return {
      countOpen: 1
    }
  },
  components: {
    BlockLoader
  },
  watch: {
    countOpen () {
      this.$emit('updateCount', this.countOpen)
    }
  }
}
</script>
<style scoped lang="scss">
.open-block {
  margin-top: 16px;
  .x-items {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 4.15px;
    .x-item {
      background: #202125;
      border-radius: 4px;
      align-items: center;
      justify-content: center;
      height: 40px;
      display: flex;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      cursor: pointer;
      transition: 0.2s;
      &:hover, &.active {
        background: #66BF57;
      }
    }
  }
  .label {
    margin: 15px 0;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    width: 100%;
    color: #3D3E45;
  }
  button {
    background: #FE5E3B;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    position: relative;
    margin: 0 auto;
    align-items: center;
    padding: 8px;
    width: 250px;
    span {
      font-weight: 700;
      white-space: nowrap;
      font-size: 14px;
      line-height: 17px;
      text-transform: uppercase;
      margin-left: 21px;
      margin-right: 13px;
    }
    em {
      background: #18191D;
      border-radius: 6px;
      padding: 10px 15px;
      font-weight: 700;
      font-size: 14px;
      white-space: nowrap;
      line-height: 17px;
      font-style: normal;
      text-align: center;
      text-transform: uppercase;
    }
    &[disabled] {
      opacity: 0.8;
    }
    &.secondary {
      margin-top: 15px;
      justify-content: space-between;
      background: #202125;
    }
  }
}
</style>
