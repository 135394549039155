<template>
  <div class="trade__link">
    <div class="trade__link-label">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1_21676)">
          <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M9.87876 12.7071L7.75744 14.8284C7.36692 15.2189 7.36692 15.8521 7.75744 16.2426C8.14797 16.6331 8.78113 16.6331 9.17165 16.2426L11.293 14.1213L12.0001 14.8284C12.7811 15.6095 12.7811 16.8758 12.0001 17.6568L9.17165 20.4853C8.39061 21.2663 7.12428 21.2663 6.34323 20.4853L3.5148 17.6568C2.73375 16.8758 2.73375 15.6094 3.5148 14.8284L6.34323 12C7.12428 11.2189 8.39061 11.2189 9.17165 12L9.87876 12.7071Z" fill="#FE5E3B"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M14.1212 11.2929L16.2425 9.1716C16.6331 8.78107 16.6331 8.14791 16.2425 7.75738C15.852 7.36686 15.2189 7.36686 14.8283 7.75739L12.707 9.87871L11.9999 9.1716C11.2189 8.39055 11.2189 7.12422 11.9999 6.34317L14.8283 3.51474C15.6094 2.7337 16.8757 2.7337 17.6568 3.51474L20.4852 6.34317C21.2662 7.12422 21.2662 8.39055 20.4852 9.1716L17.6568 12C16.8757 12.7811 15.6094 12.7811 14.8283 12L14.1212 11.2929Z" fill="#FE5E3B"/>
          <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M4.22172 4.22182C4.61224 3.8313 5.24541 3.8313 5.63593 4.22182L7.05015 5.63604C7.44067 6.02656 7.44067 6.65973 7.05015 7.05025C6.65962 7.44077 6.02646 7.44077 5.63593 7.05025L4.22172 5.63604C3.8312 5.24551 3.8312 4.61235 4.22172 4.22182ZM9.34304 1.51472C9.89532 1.51472 10.343 1.96243 10.343 2.51472L10.343 4.51472C10.343 5.067 9.89532 5.51472 9.34304 5.51472C8.79076 5.51472 8.34304 5.067 8.34304 4.51472L8.34304 2.51471C8.34304 1.96243 8.79076 1.51472 9.34304 1.51472ZM1.68619 9.17157C1.68619 8.61928 2.1339 8.17157 2.68619 8.17157L4.68619 8.17157C5.23847 8.17157 5.68619 8.61928 5.68619 9.17157C5.68619 9.72385 5.23847 10.1716 4.68619 10.1716L2.68619 10.1716C2.1339 10.1716 1.68619 9.72385 1.68619 9.17157Z" fill="#FE5E3B"/>
          <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M19.6568 19.6569C20.0473 19.2664 20.0473 18.6332 19.6568 18.2427L18.2426 16.8285C17.852 16.4379 17.2189 16.4379 16.8284 16.8285C16.4378 17.219 16.4378 17.8521 16.8284 18.2427L18.2426 19.6569C18.6331 20.0474 19.2663 20.0474 19.6568 19.6569ZM22.3639 14.5356C22.3639 13.9833 21.9162 13.5356 21.3639 13.5356H19.3639C18.8116 13.5356 18.3639 13.9833 18.3639 14.5356C18.3639 15.0878 18.8116 15.5356 19.3639 15.5356H21.3639C21.9162 15.5356 22.3639 15.0878 22.3639 14.5356ZM14.707 22.1924C15.2593 22.1924 15.707 21.7447 15.707 21.1924V19.1924C15.707 18.6401 15.2593 18.1924 14.707 18.1924C14.1547 18.1924 13.707 18.6401 13.707 19.1924V21.1924C13.707 21.7447 14.1547 22.1924 14.707 22.1924Z" fill="#FE5E3B"/>
        </g>
        <defs>
          <clipPath id="clip0_1_21676">
            <rect width="24" height="24" fill="white"/>
          </clipPath>
        </defs>
      </svg>

      <span>{{ $t('trade.name') }}</span>
    </div>
    <div class="trade__link-content">
      <input type="url" v-model="user.tradeLink" placeholder="Trade link">
      <p v-html="$t('trade.where', {steamId: user.steamId})"></p>
    </div>
    <div class="trade__link-save">
      <button @click="save" :disabled="!validTradeLink || isLoading">
        <BlockLoader v-if="isLoading"></BlockLoader>
        {{ $t('trade.save') }}
      </button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { SAVE_TRADE_LINK } from '../../queries/mutations'
import BlockLoader from '../BlockLoader'
export default {
  data () {
    return {
      isLoading: false,
      tradeLink: null
    }
  },
  methods: {
    async save () {
      if (!this.validTradeLink) return;

      this.isLoading = true

      await this.$apollo.mutate({
        mutation: SAVE_TRADE_LINK,
        variables: {
          tradeLink: this.user.tradeLink
        }
      }).then(() => {
        this.$toast.success(this.$t('trade.success_save'));
      }).catch((e) => {
        const errors = e.graphQLErrors

        errors.forEach(error => {
          this.$toast.error(error.message);
        })
      }).finally(() => {
        this.isLoading = false
      })
    }
  },
  computed: {
    validTradeLink () {
      const urlPattern = new RegExp(/https?:\/\/steamcommunity.com\/tradeoffer\/new\/\?partner=(\d+)&token=(.{8})$/,'i'); // validate fragment locator
      return !!urlPattern.test(this.user.tradeLink);
    },
    ...mapGetters({
      user: 'auth/user'
    })
  },
  components: {
    BlockLoader
  }

}
</script>
<style scoped lang="scss">
.trade__link {
  background: #1D1E23;
  margin-top: 20px;
  margin-bottom: 15px;
  padding: 20px;
  display: flex;
  align-items: flex-start;
  padding-left: 30px;
  @media (max-width: 1400px) {
    flex-wrap: wrap;
  }
  @media (max-width: 1200px) {
    padding-left: 20px;
  }
  .trade__link-label {
    margin-top: 10px;
    margin-right: 28px;
    display: flex;
    align-items: center;
    @media (max-width: 1400px) {
      width: 100%;
      margin-bottom: 20px;
    }
    span {
      white-space: nowrap;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      margin-left: 10px;
    }
  }
  .trade__link-content {
    flex-grow: 1;
    overflow: hidden;

    input {
      width: 100%;
      background: #131419;
      border-radius: 4px;
      padding: 15px 20px;
      font-weight: 400;
      font-size: 12px;
      outline: none;
      border: none;
      line-height: 15px;
      letter-spacing: 0.05em;
      color: #44454B;

    }
    p {
      display: block;
      font-weight: 400;
      margin-top: 15px;
      font-size: 14px;
      line-height: 17px;
      color: #676974;
      @media (max-width: 1400px) {
        margin-bottom: 10px;
        width: 100%;
      }
      ::v-deep {
        a {
          color: #418DFF;
          text-decoration: underline;
          font-size: 14px;
          line-height: 17px;
        }
      }
    }
  }
  .trade__link-save {
    margin-left: 13px;
    width: 140px;
    min-width: 140px;
    @media (max-width: 1400px) {
      margin-left: auto;
    }
    button {
      position: relative;
      padding: 13.5px 0;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      width: 100%;
      background: #FE5E3B;
      border-radius: 4px;
      text-align: center;
      letter-spacing: 0.05em;
    }
    @media (max-width: 575px) {
      width: 100%;
      margin-top: 5px;
      button {
        width: 100%;
      }
    }
  }
}
</style>
