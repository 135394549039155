<template>
  <div class="notifications__wrapper">
    <div class="notifications__arrow"></div>
    <div class="notifications">
      <div v-for="(notification,i) in notifications" :key="i" class="notification__item">
        <div class="notification__item-status" :class="[notification.type]">
          <template v-if="notification.type === 'success'">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path opacity="0.3" d="M17.2817 4.46024C17.5798 4.10957 18.1057 4.06692 18.4564 4.365C18.807 4.66307 18.8497 5.18898 18.5516 5.53965L11.4683 13.873C11.1655 14.2292 10.6291 14.2667 10.2797 13.9561L6.52968 10.6228C6.18569 10.317 6.15471 9.7903 6.46047 9.44631C6.76624 9.10232 7.29297 9.07134 7.63695 9.37711L10.7502 12.1444L17.2817 4.46024Z" fill="#61AC4E"/>
              <path d="M13.1147 5.29361C13.4128 4.94294 13.9387 4.9003 14.2894 5.19837C14.64 5.49644 14.6827 6.02235 14.3846 6.37303L7.30127 14.7064C6.99852 15.0625 6.46207 15.1001 6.11269 14.7895L2.36269 11.4562C2.0187 11.1504 1.98772 10.6237 2.29348 10.2797C2.59925 9.9357 3.12597 9.90471 3.46996 10.2105L6.58317 12.9778L13.1147 5.29361Z" fill="#61AC4E"/>
            </svg>
          </template>
          <template v-else-if="notification.type === 'warn'">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path opacity="0.3" d="M10.0003 18.3333C14.6027 18.3333 18.3337 14.6023 18.3337 9.99996C18.3337 5.39759 14.6027 1.66663 10.0003 1.66663C5.39795 1.66663 1.66699 5.39759 1.66699 9.99996C1.66699 14.6023 5.39795 18.3333 10.0003 18.3333Z" fill="#FF8D04"/>
              <path d="M10.8337 6.66671C10.8337 6.20647 10.4606 5.83337 10.0003 5.83337C9.54009 5.83337 9.16699 6.20647 9.16699 6.66671V11.6667C9.16699 12.1269 9.54009 12.5 10.0003 12.5C10.4606 12.5 10.8337 12.1269 10.8337 11.6667V6.66671Z" fill="#FF8D04"/>
              <path d="M10.8337 14.1667C10.8337 13.7065 10.4606 13.3334 10.0003 13.3334C9.54009 13.3334 9.16699 13.7065 9.16699 14.1667C9.16699 14.6269 9.54009 15 10.0003 15C10.4606 15 10.8337 14.6269 10.8337 14.1667Z" fill="#FF8D04"/>
            </svg>
          </template>
          <template v-else-if="notification.type === 'error'">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.5361 5.28592L5.28652 13.5355C4.96108 13.8609 4.96108 14.3886 5.28652 14.714C5.61196 15.0394 6.1396 15.0394 6.46503 14.714L14.7146 6.46443C15.04 6.139 15.04 5.61136 14.7146 5.28592C14.3892 4.96048 13.8615 4.96048 13.5361 5.28592Z" fill="#EC4C56"/>
              <path opacity="0.3" d="M14.7148 13.5355L6.46523 5.28591C6.1398 4.96047 5.61216 4.96047 5.28672 5.28591C4.96128 5.61135 4.96128 6.13899 5.28672 6.46442L13.5363 14.714C13.8617 15.0394 14.3894 15.0394 14.7148 14.714C15.0402 14.3886 15.0402 13.8609 14.7148 13.5355Z" fill="#EC4C56"/>
            </svg>
          </template>
        </div>
        <div class="notification__item-detail">
          <div class="notification__item-detail_top">
            <span>{{ notification.title[$root.currentLanguage] }}</span>
            <div class="date">{{ getTime(notification.createdAt) }}</div>
          </div>
          <div class="notification__item-text">
            {{ notification.text[$root.currentLanguage] }}
          </div>
          <button class="accept" v-if="notification.tradeLink" @click="goToTrade(notification.tradeLink)">{{ $t('profile.accept_trade') }}</button>

        </div>
      </div>
    </div>
    <div class="empty" v-if="!notifications.length">
      {{ $t('notifications_empty') }}
    </div>
  </div>
</template>
<script>
import moment from 'moment'
export default {
  props: {
    notifications: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    goToTrade (link) {
      window.open(link, '_blank')
    },
    getTime (date) {
      return moment(date).format('HH:mm');
    },
  },
  components: {
  }
}
</script>
<style scoped lang="scss">
.accept {
  background: #FE5E3B;
  border-radius: 4px;
  padding: 6px 0px;
  width: 100%;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin-left: 5px;
  }

  margin-top: 5px;
}
.empty {
  color: #7A7B7E;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.notifications__wrapper {
  position: absolute;
  top: calc(100% + 15px);
  right: 0;
  width: 350px;
  height: 324px;
  z-index: 9999999;
  background: #212227;
  backdrop-filter: blur(12.5px);
  border-radius: 6px;
  padding: 15px;
  padding-right: 7px;
  @media (max-width: 650px) {
    right: -250px;
  }
  @media (max-width: 390px) {
    width: 290px;
    right: -170px;
  }
  .notifications__arrow {
    position: absolute;
    top: -15px;
    width: 100%;
    height: 15px;
    left: 0;
    z-index: 9;
    &::after {
      content: "";
      display: block;
      width: 15px;
      height: 15px;
      position: absolute;
      bottom: -10px;
      right: 20px;
      background: #212227;
      transform: rotate(45deg);
      @media (max-width: 650px) {
        right: 77%
      }
      @media (max-width: 390px) {
        right: 65%;
      }
    }

  }
  .notifications {
    padding-right: 5px;
    max-height: 100%;
    overflow-y: auto;
    .notification__item {
      background: rgba(255, 255, 255, 0.05);
      backdrop-filter: blur(12.5px);
      border-radius: 4px;
      padding: 10px;
      display: flex;
      .notification__item-status {
        margin-right: 11px;
        width: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 34px;
        background: rgba(97, 172, 78, 0.1);
        border-radius: 6px;
        height: 34px;
        &.error {
          background: rgba(236, 76, 86, 0.1);
        }
        &.warn {
          background: rgba(255, 141, 4, 0.1);
        }
      }
      .notification__item-detail {
        flex-grow: 1;
        overflow: hidden;
        .notification__item-detail_top {
          position: relative;
          margin-bottom: 5px;
          display: flex;
          align-items: center;
          width: 100%;
          span {
            white-space: nowrap;
            display: block;
            overflow: hidden;
            max-width: 80%;
            text-overflow: ellipsis;
            font-weight: 700;
            font-size: 12px;
            line-height: 15px;
          }
          .date {
            position: absolute;
            right: 0;
            font-weight: 600;
            font-size: 10px;
            line-height: 12px;
            text-align: right;
            color: #65666C;
            opacity: 0.45;
          }
        }
        .notification__item-text {
          font-weight: 500;
          font-size: 10px;
          line-height: 12px;
          color: #65666C;
        }
      }
    }
    .notification__item + .notification__item {
      margin-top: 6px;
    }
    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 0.15);
      border-radius: 6px;
    }
    &::-webkit-scrollbar-track {
      background: rgba(255, 255, 255, 0.05);
    }
  }

}
</style>
