export default function chatMediaVisibility({ $root }) {
    const chatMediaQuery = window.matchMedia('(max-width: 1200px)')
    const changeChatvisibilityBasedOnMatchMediaQuery = (mediaEvent) => {
        if (mediaEvent.matches) {
            localStorage.setItem('chat', 'false')
            $root.$emit('openChat', true)
        } else {
            localStorage.setItem('chat', 'true')
            $root.$emit('openChat')
        }
    }
    chatMediaQuery.onchange = (e) => {
        changeChatvisibilityBasedOnMatchMediaQuery(e)
    }
    changeChatvisibilityBasedOnMatchMediaQuery(chatMediaQuery)
}
