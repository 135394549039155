import Vue from 'vue'
import App from './App.vue'
import store from './store'
import { i18n } from './plugins/i18n'
import router from './router/index'
import VTooltip from 'v-tooltip'
import Toast from "vue-toastification";
import { createProvider } from './vue-apollo'
import vueDebounce from 'vue-debounce'
import "vue-toastification/dist/index.css";
import VueMeta from "vue-meta";
Vue.use(VTooltip)
Vue.use(Toast)
Vue.use(vueDebounce)
Vue.use(VueMeta)
let currentLanguage = Vue.observable(localStorage.getItem('lang') ? localStorage.getItem('lang') : 'ru');
if (fromCookie('token')) {
  localStorage.setItem('apollo-token', fromCookie('token'))
}
import { mapGetters } from 'vuex'
import { fromCookie } from "@/helpers/fromCookie";
new Vue({
  data () {
    return {
      currentLanguage,
    }
  },
  methods: {
    setLanguage (lang) {
      currentLanguage = lang
      this.currentLanguage = lang
      localStorage.setItem('lang', lang)
      this.$i18n.locale = lang
    }
  },
  computed: {
    ...mapGetters({
      authStatus: 'auth/authStatus'
    }),
    isAuth () {
      return this.authStatus
    }
  },
  store,
  i18n,
  router,
  apolloProvider: createProvider(),
  render: h => h(App)
}).$mount('#app')

export {
  currentLanguage
}
