<template>
  <div class="profile__information">
    <div class="profile__information-item">
      <div class="information__title">
        {{ $t('profile.best_case') }}
      </div>
      <div class="information__best-case" v-if="profile.getFavoriteCase">
        <div class="information__best-case_img">
          <img :src="`https://rustfast.org/cdn-cgi/imagedelivery/IWwC2L3JFPDEcVB1-rHJPQ/${profile.getFavoriteCase.image}/500fx500f`" alt="">
        </div>
        <span>{{ profile.getFavoriteCase.name[$root.currentLanguage] }}</span>
      </div>
      <CaseNotOpenedBlock :pub="pub" v-else />
    </div>
    <div class="profile__information-item main">
      <div class="profile__information-user">
        <div class="profile__information-item_user">
          <div class="profile__information-item_user-avatar">
            <img :src="profile.avatar" alt="">
          </div>
          <div class="profile__information-item_user-nickname">
            {{ profile.name }}
            <a :href="`https://steamcommunity.com/profiles/${profile.steamId}/`" target="_blank">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1_18809)">
                  <path d="M0.555521 12.8314C1.77214 16.888 5.53491 19.8462 9.98752 19.8462C15.4254 19.8462 19.8337 15.4379 19.8337 10C19.8337 4.56218 15.4254 0.15387 9.98752 0.15387C4.76967 0.15387 0.499828 4.21325 0.162598 9.34587C0.800752 10.4157 1.04844 11.0776 0.554905 12.8314H0.555521Z" fill="#202127"/>
                  <path d="M9.47992 7.53387L9.48085 7.58249L7.07162 11.0825C6.68146 11.0646 6.29008 11.1333 5.91839 11.2862C5.75658 11.3519 5.6021 11.4344 5.45746 11.5323L0.166693 9.35172C0.166693 9.35172 0.0442319 11.3659 0.554386 12.8668L4.29469 14.4102C4.47931 15.2496 5.05777 15.9856 5.90762 16.3394C6.57622 16.6172 7.32774 16.6183 7.99714 16.3424C8.66653 16.0665 9.19906 15.5363 9.47777 14.868C9.62646 14.5121 9.69818 14.1287 9.68823 13.7431L13.1322 11.28L13.2168 11.2816C15.2799 11.2816 16.9568 9.59972 16.9568 7.53387C16.9568 5.46803 15.283 3.79233 13.2168 3.78802C11.1547 3.78802 9.47685 5.46803 9.47685 7.53387H9.47992ZM8.90146 14.6262C8.45408 15.7031 7.21962 16.2099 6.14669 15.7628C5.67058 15.5627 5.28465 15.1945 5.06239 14.7283L6.27992 15.2329C6.46801 15.3112 6.66968 15.3517 6.87341 15.352C7.07714 15.3524 7.27893 15.3125 7.46727 15.2348C7.6556 15.1571 7.82678 15.0431 7.97102 14.8992C8.11527 14.7554 8.22975 14.5845 8.30792 14.3963C8.46623 14.0162 8.46716 13.5888 8.31053 13.2079C8.15389 12.8271 7.85249 12.524 7.47254 12.3653L6.211 11.8437C6.69654 11.6591 7.24854 11.6529 7.76485 11.8674C8.28792 12.0837 8.68792 12.4911 8.90208 13.012C9.11623 13.5329 9.115 14.1074 8.899 14.6262M13.2202 10.0308C12.5591 10.029 11.9257 9.76528 11.4587 9.29739C10.9917 8.82949 10.7291 8.19558 10.7285 7.53449C10.7293 6.8735 10.9919 6.23974 11.4589 5.77198C11.9259 5.30422 12.5593 5.04058 13.2202 5.03879C13.8813 5.04042 14.5148 5.30399 14.982 5.77177C15.4491 6.23955 15.7118 6.8734 15.7125 7.53449C15.712 8.19568 15.4493 8.82969 14.9822 9.2976C14.515 9.76551 13.8814 10.0292 13.2202 10.0308ZM11.3532 7.53049C11.3527 7.03378 11.5496 6.55723 11.9004 6.20557C12.2512 5.85391 12.7272 5.65592 13.2239 5.6551C14.2562 5.6551 15.0959 6.4951 15.0959 7.53049C15.0962 8.0273 14.8991 8.50388 14.5481 8.85544C14.197 9.207 13.7207 9.40477 13.2239 9.40526C12.7273 9.40452 12.2512 9.20665 11.9004 8.85511C11.5495 8.50358 11.3526 8.02714 11.3528 7.53049H11.3532Z" fill="white"/>
                </g>
                <defs>
                  <clipPath id="clip0_1_18809">
                    <rect width="20" height="20" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            </a>
          </div>
        </div>
        <div class="profile__information-item_indicators">
          <div class="profile__information-item_indicator opens">
            <span>{{ $t('profile.count_opened') }}</span>
            <strong>{{ profile.opens }}</strong>
          </div>
          <div class="profile__information-item_indicator upgrades">
            <span>{{ $t('profile.count_upgrades') }}</span>
            <strong>{{ profile.upgrades }}</strong>
          </div>
        </div>
      </div>
    </div>
    <div class="profile__information-item">
      <div class="information__title">
        {{ $t('profile.best_drop') }}
      </div>
      <div class="information__best-drop" v-if="profile.getBestDrop">
        <div class="information__best-drop_img">
          <img :src="`https://community.akamai.steamstatic.com/economy/image/${profile.getBestDrop.item.image}/360fx360f`" alt="">
        </div>
        <span>{{ profile.getBestDrop.item.name }}</span>
        <em>₽ {{ parseFloat(profile.getBestDrop.price).toFixed(2) }}</em>
      </div>
      <CaseNotOpenedBlock :pub="pub" v-else />
    </div>
  </div>
</template>
<script>
import CaseNotOpenedBlock from './CaseNotOpenedBlock'
export default {
  props: {
    pub: {
      type: Boolean,
      default: false,
    },
    profile: {
      type: Object,
      required: true
    }
  },
  components: {
    CaseNotOpenedBlock
  }
}
</script>
<style scoped lang="scss">
.profile__information {
  margin-top: 77px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  @media (max-width: 768px) {
    display: flex;
    flex-wrap: wrap;
  }
  .profile__information-item {
    background: #1D1E23;
    @media (max-width: 768px) {
      padding-bottom: 20px;
    }
    .profile__information-user {
      .profile__information-item_user {
        background: #131419;
        padding-bottom: 50px;
        @media (max-width: 992px) {
          padding-bottom: 20px;
        }
        .profile__information-item_user-avatar {
          width: 150px;
          height: 150px;
          border-radius: 100%;
          position: relative;
          margin: 0 auto;
          img {
            border-radius: 100%;
            top: -40px;
            position: absolute;
            width: 100%;
            height: 100%;
          }
        }
        .profile__information-item_user-nickname {
          font-weight: 600;
          font-size: 20px;
          line-height: 16px;
          display: flex;
          align-items: center;
          overflow: hidden;
          text-overflow: ellipsis;
          @media (max-width: 992px) {
            font-size: 16px;
          }
          a {
            display: flex;
            align-items: center;
            margin-left: 10px;
            transition: 0.2s;
            &:hover {
              opacity: 0.6;
            }
          }
          justify-content: center;
          text-align: center;
          width: 100%;
          margin-top: -10px;
        }
      }
      .profile__information-item_indicators {
        display: flex;
        //@media (max-width: 992px) {
        //  flex-wrap: wrap;
        //}
        .profile__information-item_indicator {
          flex: 1;
          padding: 24px 0;
          @media (max-width: 992px) {
            flex: unset;
            width: 100%;
            padding: 10px 0;
          }
          &.opens {
            background: #202127;
          }
          &.upgrades {
            background: #23242A;
          }
          span, strong {
            display: block;
            width: 100%;
            text-align: center;
          }
          span {
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            color: #676974;
            margin-bottom: 5px;
            @media (max-width: 1450px) {
              font-size: 12px;
            }
            @media (max-width: 992px) {
              margin-bottom: 0;
            }
          }
          strong {
            font-weight: 600;
            font-size: 24px;
            line-height: 29px;
            text-align: center;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            color: #FE5E3B;
            @media (max-width: 992px) {
              font-size: 20px;
            }
          }
        }
      }
      @media (max-width: 768px) {
        width: 100%;
      }
    }
    .information__title {
      margin-bottom: 30px;
      width: 100%;
      margin-top: 30px;
      justify-content: center;
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 16px;
      line-height: 16px;
      text-align: center;
      color: #FE5E3B;
      position: relative;

      &::after, &::before {
        content: "";
        display: block;
        width: 26px;
        height: 5.56px;
        background: url(/img/title-mask.png);
      }
      &::before {
        margin-right: 19px;
      }
      &::after {
        transform: scale(1, -1) rotate(180deg);
        margin-left: 19px;
      }
      @media (max-width: 1400px) {
        font-size: 14px;
        &::after {
          margin-left: 10px;
        }
        &::before {
          margin-right: 10px;
        }
      }

      @media (max-width: 768px) {
        &::before, &::after {
          display: none;
        }
        font-size: 12px !important;
      }
    }
    .information__best-case {
      .information__best-case_img{
        display: flex;
        justify-content: center;
        width: 100%;
        position: relative;
        img {
          max-width: 180px;
          margin: 0 auto;
          display: block;
          margin-bottom: 10px;
          @media (max-width: 1500px) {
            max-width: 160px;
          }
          @media (max-width: 768px) {
            height: 100px;
          }
        }
        &::after {
          content: "";
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          background: linear-gradient(180deg, rgba(29, 30, 35, 0.1) 0%, #1D1E23 100%);
          height: 100px;
        }
      }
      span {
        width: 100%;
        display: block;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        @media (max-width: 768px) {
          font-size: 12px;
        }
      }
      @media (max-width: 768px) {
        order: 1;
      }
    }
    .information__best-drop {
      .information__best-drop_img {
        display: flex;
        width: 100%;
        justify-content: center;
        position: relative;
        img {
          max-width: 80%;
          max-height: 170px;
        }
        &::after {
          content: "";
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          background: linear-gradient(180deg, rgba(29, 30, 35, 0.03) 0%, #1D1E23 100%);
          height: 100px;
        }
        @media (max-width: 768px) {
          height: 100px;
          margin: 0 auto;
        }
      }
      span {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        width: 100%;
        display: block;
        margin-bottom: 5px;
        @media (max-width: 768px) {
          font-size: 12px;
        }
      }
      em {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: #FE5E3B;
        font-style: normal;
        text-align: center;
        display: block;
        @media (max-width: 768px) {
          font-size: 12px;
        }
      }
      @media (max-width: 768px) {
        order: 2;
      }
    }
    @media (max-width: 1500px) {
      &.main {
        min-width: 300px;
      }
    }
    @media (max-width: 992px) {
      &.main {
        min-width: unset;
      }
    }
    @media (max-width: 768px) {
      order: 1;
      width: 50%;
      &.main {
        width: 100%;
        order: 0;
      }
    }
  }
}
</style>
