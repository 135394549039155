import BaseModel from './BaseModel'

export default class UserModel extends BaseModel {
    get schema () {
        return {
            steamId: undefined,
            name: undefined,
            avatar: undefined,
            balance: undefined,
            tradeLink: undefined,
            isAdmin: undefined,
            chatMuted: undefined,
            isModerator: undefined,
            isBanned: undefined,
            notifications: undefined
        }
    }

    getBalance () {
        return parseFloat(this.balance).toFixed(2)
    }

    prepareCreate () {
        this.baseClear()

        return this
    }

    prepareUpdate () {
        this.baseClear()

        return this
    }
}
