<template>
  <div class="upgrade__game">
    <div class="upgrade__game-item">
      <div class="upgrade__game-item_title">
        {{ $t('upgrade.products_for_upgrade') }}
      </div>
      <div class="upgrade__game-item_instance-money" v-if="betType === BET_BALANCE_TYPE">
        <img src="/img/money-instance.png" alt="">
      </div>
      <div class="upgrade__game-item_instance" v-else>
        <div class="upgrade__game-item_instance-image" :class="{'non-bg': betType === BET_BALANCE_TYPE}">
          <img v-if="getSelectImage" :src="`${getSelectImage}/360fx360f`" alt="">
        </div>
        <strong>{{ getSelectName }}</strong>
        <span>{{ getSelectPrice }}</span>
      </div>
    </div>
    <div class="upgrade__game-circle">
      <div class="upgrade__game-border-1">
        <div class="upgrade__game-border-2" :class="{'active': status === 'process'}">
          <LoseUpgradeBlock :item="finishItem" v-if="isWin === false && status === 'finish'"></LoseUpgradeBlock>
          <WinUpgradeBlock :item="finishItem" v-if="isWin === true && status === 'finish'"></WinUpgradeBlock>
          <BarComponent v-if="!(isWin === false && status === 'finish') && status !== 'finish'" :percent="upgradePercent"></BarComponent>
          <template v-if="status !== 'finish'">
            <div class="upgrade__cursor" ref="cursor">
              <div class="top-arrow">
                <svg width="18" height="42" viewBox="0 0 18 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_1_37973)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.00702 0.00500488L9 1.25699L15.993 0.00500488L9 7L2.00702 0.00500488ZM0 5L9 11.257L18 5L9 17L0 5Z" fill="#FE5E3B"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8 21H10V42H8V21Z" fill="#FE5E3B"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_1_37973">
                      <rect width="18" height="42" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </div>
            <div class="upgrade__game-percentage">
              <div>
                <strong>{{ upgradePercent }}%</strong>
                <span>{{ $t('upgrade.chance') }}</span>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="upgrade__game-item">
      <div class="upgrade__game-item_title">
        {{ $t('upgrade.product_give') }}
      </div>
      <div class="upgrade__game-item_instance">
        <div class="upgrade__game-item_instance-image">
          <img v-if="getUpgradeImage" :src="`${getUpgradeImage}/360fx360f`" alt="">
        </div>
        <strong>{{ getUpgradeName }}</strong>
        <span>{{ getUpgradePrice }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { BET_BALANCE_TYPE } from '../../const/UPGRADE_VARIABLES'
import { CREATE_ITEM_UPGRADE, CREATE_BALANCE_UPGRADE } from '../../queries/mutations'
import { mapGetters, mapActions } from 'vuex'
import LoseUpgradeBlock from './LoseUpgradeBlock'
import WinUpgradeBlock from './WinUpgradeBlock'
import BarComponent from './BarComponent'
export default {
  data () {
    return {
      finishItem: null,
      isWin: null,
      BET_BALANCE_TYPE
    }
  },
  computed: {
    ...mapGetters({
      status: 'upgrade/status',
      selectInventory: 'upgrade/selectInventory',
      betType: 'upgrade/betType',
      selectUpgrade: 'upgrade/selectUpgrade',
      useSelectBalance: 'upgrade/useSelectBalance',
      upgradePercent: 'upgrade/upgradePercent',
    }),
    getSelectName () {
      return this.selectInventory ? this.selectInventory.item.name : this.$t('upgrade.call_select')
    },
    getSelectPrice () {
      return this.selectInventory ? this.selectInventory.price + '₽' : this.$t('upgrade.upgrade_title')
    },
    getSelectImage () {
      return this.selectInventory ? this.selectInventory.item.getImage : null
    },

    getUpgradeName () {
      return this.selectUpgrade ? this.selectUpgrade.name : this.$t('upgrade.call_select')
    },
    getUpgradePrice () {
      return this.selectUpgrade ? this.selectUpgrade.price + '₽' : this.$t('upgrade.upgrade_title')
    },
    getUpgradeImage () {
      return this.selectUpgrade ? this.selectUpgrade.getImage : null
    },
  },
  methods: {
    ...mapActions({
      clearUpgradeState: 'upgrade/clearUpgradeState',
      setStatus: 'upgrade/setStatus'
    }),
    async sendUpgrade () {
      await this.$apollo.mutate({
        mutation: CREATE_ITEM_UPGRADE,
        variables: {
          openId: this.selectInventory.id,
          itemId: this.selectUpgrade.id
        }
      }).then((data) => {
        this.startAnimation(data.data.upgradeItem)
      }).catch((error) => {
        this.clearUpgradeState()
        error.graphQLErrors.map(item => {
          this.$toast.error(item.message);
        })
      })
    },
    async sendBalanceUpgrade () {
      await this.$apollo.mutate({
        mutation: CREATE_BALANCE_UPGRADE,
        variables: {
          sum: this.useSelectBalance,
          itemId: this.selectUpgrade.id
        }
      }).then((data) => {
        this.startAnimation(data.data.upgradeBalance)
      }).catch((error) => {
        this.clearUpgradeState()
        error.graphQLErrors.map(item => {
          this.$toast.error(item.message);
        })
      })
    },
    async startGame () {
      if (this.betType === BET_BALANCE_TYPE) {
        await this.sendBalanceUpgrade()
      } else {
        await this.sendUpgrade()
      }
    },

    async startAnimation (result) {
      await this.setStatus('process');
      const isWin = result.isWin
      this.isWin = isWin
      this.finishItem = result.open.item
      const cursor = this.$refs.cursor;
      const baseRotate = 3600;

      const getRandomArbitrary = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1) + min);
      }

      let rotate = baseRotate;
      if (!isWin) {
        const freePercent = 100 - this.upgradePercent
        const countOfPercent = (360 / 100) * freePercent
        const res = 360 - getRandomArbitrary(0, countOfPercent)
        rotate += res
      } else {
        const countOfPercent = (360 / 100) * this.upgradePercent
        rotate += getRandomArbitrary(0, countOfPercent)
      }

      cursor.style.transform = `rotate(${rotate}deg)`;
      setTimeout(() => {
        this.setStatus('finish')
      }, 5000)
    }
  },
  mounted () {
    this.$root.$on('startUpgrade', () => {
      this.startGame()
    })
  },
  components: {
    LoseUpgradeBlock,
    WinUpgradeBlock,
    BarComponent
  }
}
</script>
<style scoped lang="scss">
.upgrade__game {
  height: 300px;
  background: #1D1E23;
  width: 100%;
  margin-top: 75px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1400px) {
    height: 270px;
    margin-top: 30px;
  }
  @media (max-width: 992px) {
    height: 250px;
  }
  @media (max-width: 768px) {
    flex-wrap: wrap;
    height: auto;
    padding-bottom: 30px;
  }
  .upgrade__game-circle {
    width: 420px;
    height: 420px;
    min-width: 420px;
    background: #18191D;
    border-radius: 100%;
    margin-top: -60px;
    padding: 60px;
    @media (max-width: 1600px) {
      padding: 30px;
      width: 350px;
      min-width: 350px;
      height: 350px;
      margin-top: -20px;
    }
    @media (max-width: 1400px) {
      width: 300px;
      min-width: 300px;
      height: 300px;
      margin-top: -15px;
    }
    @media (max-width: 992px) {
      width: 270px;
      min-width: 270px;
      padding: 15px;
      margin-top: -10px;
      height: 270px;
    }
    @media (max-width: 768px) {
      margin: 20px auto;
    }
    .upgrade__game-border-1 {
      border-radius: 100%;
      background: #131419;
      padding: 25px;
      height: 100%;
      @media (max-width: 992px) {
        padding: 16px;
      }
      .upgrade__game-border-2 {
        position: relative;
        height: 100%;
        width: 100%;
        transition: 0.7s;
        padding: 20px;
        border-radius: 100%;
        border: 1px solid #21222B;
        @media (max-width: 992px) {
          padding: 14px;
        }
        .upgrade__cursor {
          position: absolute;
          top: 0;
          z-index: 20;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          transition: 5s;
          display: flex;
          justify-content: center;
          border-radius: 100%;
          .top-arrow {
            position: absolute;
            top: -10px;
          }
        }
        .upgrade__game-percentage {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 15;
          strong, span {
            display: block;
            width: 100%;
            text-align: center;
          }
          strong {
            font-weight: 700;
            font-size: 30px;
            line-height: 36px;
            text-align: center;
            text-transform: uppercase;
            margin-bottom: 5px;
            @media (max-width: 1400px) {
              font-size: 22px;
            }
          }
          span {
            font-weight: 700;
            font-size: 12px;
            line-height: 15px;
            text-align: center;
            text-transform: uppercase;
            color: #676974;
            @media (max-width: 1400px) {
              font-size: 10px;
            }
          }
        }
        &.active {
          border-color: #FE5E3B
        }
      }
    }
  }
  .upgrade__game-item {
    flex-grow: 1;
    overflow: hidden;
    padding-top: 30px;
    .upgrade__game-item_title {
      position: relative;
      font-weight: 700;
      width: 100%;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      line-height: 16px;
      color: #676974;

      &::after, &::before {
        content: "";
        display: block;
        width: 26px;
        height: 5.56px;
        background: url(/img/title-mask.png);
      }
      &::before {
        margin-right: 19px;
      }
      &::after {
        transform: scale(1, -1) rotate(180deg);
        margin-left: 19px;
      }
      @media (max-width: 1750px) {
        font-size: 13px;
        &::before {
          margin-right: 4px;
        }
        &::after {
          margin-left: 4px;
        }
      }
      @media (max-width: 1350px) {
        &::before, &::after {
          display: none;
        }
      }
    }
    .upgrade__game-item_instance-money {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: 992px) {
        img {
          max-width: 160px;
        }
      }
    }
    .upgrade__game-item_instance {
      margin-top: 44px;
      @media (max-width: 1400px) {
        margin-top: 20px;
      }
      .upgrade__game-item_instance-image {
        display: flex;
        justify-content: center;
        margin-bottom: 21px;
        height: 120px;
        width: 100%;
        &:not(.non-bg) {
          background: url(/img/instance-bg.png);
          background-repeat: no-repeat;
          background-position: center center;
          @media (max-width: 992px) {
            background-size: auto 65%;
          }
        }
        img {
          max-width: 90%;
          max-height: 100%;
        }
        @media (max-width: 992px) {
          height: 95px;
        }
      }
      strong {
        width: 100%;
        display: block;
        text-align: center;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 5px;
      }
      span {
        display: block;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        color: #676974;
        width: 100%;
        text-align: center;

      }
    }

    @media (max-width: 768px) {
      width: 100%;
      min-width: 100%;

    }
  }
}
</style>
