<template>
  <div class="event-cases" :style="{
    background: 'url(https://rustfast.org/cdn-cgi/imagedelivery/IWwC2L3JFPDEcVB1-rHJPQ/' + eventBackground + '/banner) center 25% / cover no-repeat'
  }">
    <div class="event-cases__wrapper">
      <h1 class="event-cases__title">{{ $t('event.title') }}</h1>

      <div class="event-cases__description">{{ $t('event.description') }}</div>

      <div class="event-cases__items">
        <router-link v-for="(caseItem, i) in cases.slice(0, 5)" :key="i" :to="`/case/${caseItem.slug}`"
             class="event-cases__item" :class="{ 'event-cases__item--blocked': isBlocked(caseItem) }"
        >
          <div class="event-cases__item-image">
            <img :src="caseItem.getImage + '/500fx500f'" alt="Case image">

            <div class="event-cases__item-blocked-image">
              <svg width="106" height="105" viewBox="0 0 106 105" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.7" d="M69.7113 46.9437V43.75C69.7113 39.6812 69.7113 27.3438 53.3051 27.3438C36.8988 27.3438 36.8988 39.6812 36.8988 43.75V46.9437C31.5613 48.125 29.7676 51.5812 29.7676 59.0625V63.4375C29.7676 73.0625 32.7426 76.0375 42.3676 76.0375H64.2426C73.8676 76.0375 76.8426 73.0625 76.8426 63.4375V59.0625C76.8426 51.5812 75.0488 48.125 69.7113 46.9437ZM53.3051 66.0625C50.6363 66.0625 48.4926 63.9188 48.4926 61.25C48.4926 58.5812 50.6363 56.4375 53.3051 56.4375C55.9738 56.4375 58.1176 58.5812 58.1176 61.25C58.1176 63.9188 55.9738 66.0625 53.3051 66.0625ZM63.1488 46.4625H43.4613V43.75C43.4613 37.3625 45.0363 33.9062 53.3051 33.9062C61.5738 33.9062 63.1488 37.3625 63.1488 43.75V46.4625Z"/>
              </svg>
            </div>
          </div>

          <div class="event-cases__item-actions">
            <div class="event-cases__item-text">{{ caseItem.name[$root.currentLanguage] }}</div>

            <OpenEventButton :case-item="caseItem"></OpenEventButton>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import OpenEventButton from "@/components/OpenCase/OpenEventButton.vue";
import { GET_EVENT_BG } from "@/queries";

export default {
  components: { OpenEventButton },
  props: {
    cases: {
      type: Array,
      required: true,
    }
  },
  computed: {
    isBlocked() {
      return (caseItem) =>
          (caseItem.needDeposited && caseItem.needDeposited > 0) ||
          caseItem.needPromocode ||
          this.isBlockedByTime(caseItem.nextOpen)
    },
    isBlockedByTime: () => (nextOpen) => nextOpen ? Date.parse(nextOpen) > Date.now() : false,
  },
  apollo: {
    eventBackground: {
      query: GET_EVENT_BG,
      update: data => data.eventBackground
    },
  }
}
</script>

<style lang="scss">
  .event-cases {
    margin-bottom: 25px;
    border: 1px dashed #31333c;
    border-radius: 12px;
    @media (max-width: 992px) {
      background: transparent;
    }
    .event-cases__wrapper {
      padding: 35px 15px 20px;
      min-height: 450px;
      .event-cases__title {
        font-weight: 800;
        font-size: 64px;
        line-height: 70px;
        text-align: center;
        margin-bottom: 5px;
        @media (max-width: 768px) {
          font-size: 44px;
          line-height: 45px;
        }

      }
      .event-cases__description {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        margin-bottom: 60px;
        @media (max-width: 768px) {
          margin-bottom: 40px;
        }
      }
      .event-cases__items {
        display: flex;
        justify-content: space-between;
        margin: 0 auto;

        @media (max-width: 1600px) {
          flex-wrap: wrap;
          justify-content: center;
          grid-template-columns: repeat(4, 1fr);
        }
        @media (max-width: 992px) {
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 20px;
        }
        @media (max-width: 575px) {
          grid-template-columns: repeat(1, 1fr);
        }

        .event-cases__item {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          width: calc(20% - 15px);
          background: url('/img/divider.svg') center right / contain no-repeat;

          @media (max-width: 1600px) {
            width: calc(33.333% - 15px);
          }
          @media (max-width: 992px) {
            width: calc(50% - 15px);
            background: none;
          }
          @media (max-width: 575px) {
            width: 100%;
          }
          .event-cases__item-blocked-image {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
            opacity: 0;
            transition: opacity .35s;

            & > svg {
              fill: #FFFFFF;
            }
          }
          .event-cases__item-image {
            position: relative;
            transition: opacity .35s;
            img {
              transform: scale(1.2);
              transition: .35s;
            }
            .event-cases__item-blocked-image {
              svg {
                transition: transform 0.35s;
                path {
                }
              }
            }

          }
          .event-cases__item-actions {
            z-index: 2;
          }
          &:hover {
            .event-cases__item-image {
              img {
                transform: translateY(-15px) scale(1.23);
              }
            }
            .event-cases__item-blocked-image {
              svg {
                transform: scale(1.1);
                path {
                  opacity: 1;
                }
              }
            }
          }
          .event-cases__item-image > img {
            max-width: 215px;
          }
          @media (max-width: 992px) {
            background: rgba(255,255,255,0.05) !important;
            border-radius: 15px;
          }
          .event-cases__item-actions {
            display: flex;
            justify-content: space-between;
            align-items: center;
            align-self: start;
            width: 100%;
            padding: 0 25px 15px;

            @media (max-width: 992px) {
              margin-top: 15px;
            }
            .event-cases__item-text {
              font-weight: 700;
              font-size: 14px;
              line-height: 17px;
              text-transform: uppercase;
            }
            .event-cases__item-button {}
          }
        }
        .event-cases__item:last-child {
          background: none;
        }
        .event-cases__item--blocked {
          &:hover {
            img {
              filter: blur(3px);
            }

            .event-cases__item-blocked-image {
              opacity: 1;
            }
          }
        }
      }
    }
  }
</style>
