<template>
  <div class="inventory__select-item" @click="setUpgradeMethod">
    <div class="inventory__select-item_select">
      <input :ref="`product_${product.id}`" type="checkbox" :id="`product_${product.id}`">
      <label :for="`product_${product.id}`">
        <span class="square" :class="{'active': selectUpgrade && selectUpgrade.id === product.id}">
          <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.61902 0.176186C6.79787 -0.0342174 7.11341 -0.059803 7.32381 0.11904C7.53422 0.297883 7.5598 0.613429 7.38096 0.823832L3.13097 5.82382C2.94932 6.03752 2.62745 6.06004 2.41782 5.8737L0.16783 3.8737C-0.0385613 3.69024 -0.0571518 3.37421 0.126307 3.16782C0.309765 2.96143 0.625801 2.94284 0.832191 3.1263L2.70011 4.78667L6.61902 0.176186Z" fill="white"/>
          </svg>
        </span>
      </label>
    </div>
    <div class="inventory__select-item_removed" v-if="selectUpgrade && selectUpgrade.id === product.id">
      <button @click.prevent.stop="deleteUpgradeMethod">
        <img src="/img/remove-item.svg" alt="">
      </button>
    </div>
    <ProductItem :product="product" />
  </div>
</template>
<script>
import ProductItem from '../ProductItem'
import { mapActions, mapGetters } from 'vuex'
export default {
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      selectUpgrade: 'upgrade/selectUpgrade',
    })
  },
  methods: {
    deleteUpgradeMethod () {
      this.$refs[`product_${this.product.id}`].checked = false
      this.deleteUpgrade()
    },
    setUpgradeMethod () {
      if (this.selectUpgrade) {
        return;
      }

      this.setUpgrade(this.product)
    },
    ...mapActions({
      setUpgrade: 'upgrade/setUpgrade',
      deleteUpgrade: 'upgrade/deleteUpgrade'
    })
  },
  components: {
    ProductItem
  }
}
</script>
<style scoped lang="scss">
.inventory__select-item {
  position: relative;
  cursor: pointer;
  &.disabled {
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 9;
      background: rgba(0,0,0, 0.5);
      border-radius: 6px;
      overflow: hidden;
    }
  }
  .inventory__select-item_removed {
    position: absolute;
    z-index: 9;
    button {
      background: #F74646;
      border-radius: 4px;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        min-width: 20px;
      }
    }
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.01) 0%, rgba(255, 255, 255, 0) 251.54%);
    backdrop-filter: blur(2px);
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 60px;
  }
  .inventory__select-item_select {
    position: absolute;
    top: 8px;
    left: 8px;
    z-index: 9;
    input {
      display: none;
    }
    label {
      display: block;
      cursor: pointer;
      .square {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 14px;
        height: 14px;
        border-radius: 100%;
        border: 1px solid rgba(255,255,255, 0.15);
        svg {
          transition: 0.2s;
          opacity: 0;
        }
        &.active {
          background: #66BF57;
          svg {
            opacity: 1;
          }
        }
      }
    }

  }
}
.product__item {
  width: auto;
  height: 110px;

  ::v-deep {
    .product__item-img img {
      max-height: 45px;
    }
    .product__item-desc {
      width: 100%;
      left: 0;
      bottom: 10px;
      strong {
        width: 100%;
        display: block;
        font-size: 10px;
        margin-bottom: 0;
        text-align: center;
      }
      span {
        width: 100%;
        display: block;
        text-align: center;
        font-size: 12px;
      }
    }
  }
}
</style>
