<template>
  <div class="bar__component">
    <div ref="rating" class="rating"></div>
  </div>
</template>
<script>
export default {
  props: {
    ratingColor: {
      type: String,
      default: '#FE5E3B'
    },
    percent: {
      type: Number,
      required: true
    }
  },
  watch: {
    percent () {
      this.init()
    },
    ratingColor () {
      this.init()
    }
  },
  methods: {
    init () {
      const rating = this.$refs.rating
      // Get content and get score as an int
      const ratingContent = rating.innerHTML;
      const ratingScore = this.percent;

      // Define if the score is good, meh or bad according to its value


      // Add score class to the rating

      // After adding the class, get its color
      const ratingColor = this.ratingColor;

      // Define the background gradient according to the score and color
      const gradient = `background: conic-gradient(${ratingColor} ${ratingScore}%, transparent 0 100%)`;

      // Set the gradient as the rating background
      rating.setAttribute("style", gradient);

      // Wrap the content in a tag to show it above the pseudo element that masks the bar
      rating.innerHTML = `<span>₽{ratingScore} ${
          ratingContent.indexOf("%") >= 0 ? "<small>%</small>" : ""
      }</span>`;
    }
  },
  mounted () {
    this.init()
  }
}
</script>
<style scoped lang="scss">
.bar__component {
  height: 100%;
  width: 100%;
}
/* Rating item */
.rating {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  overflow: hidden;
  box-sizing: content-box;
  transition: 0.2s;

  color: #2980b9;
  width: 100%;
  height: 100%;

  /* Basic style for the text */
  font-size: calc(2rem / 3);
  line-height: 1;
}

/* Rating circle content */
.rating span {
  position: relative;
  display: flex;
  font-weight: bold;
  z-index: 2;
}

.rating span small {
  font-size: 0.5em;
  transition: 0.2s;
  font-weight: 900;
  align-self: center;
}

/* Bar mask, creates an inner circle with the same color as thee background */
.rating::after {
  content: "";
  position: absolute;
  height: calc(100% - 6px);
  width: calc(100% - 6px);
  top: 0.2rem;
  right: 0.2rem;
  bottom: 0.2rem;
  transition: 0.2s;
  left: 0.2rem;
  background: #131419;
  border-radius: inherit;
  z-index: 1;
  border: 5px solid rgba(33, 34, 43, 0.45);
}

/* Bar background */
.rating::before {
  content: "";
  position: absolute;
  top: 0.2rem;
  right: 0.2rem;
  transition: 0.2s;
  bottom: 0.2rem;
  left: 0.2rem;
  border-radius: inherit;
  box-shadow: 0 0 0 1rem rgba(33, 34, 43, 1);
  z-index: -1;
}

/* Classes to give different colors to ratings, based on their score */
.rating.good {
  background: #27ae60;
  color: #27ae60;
}

.rating.meh {
  background: #f1c40f;
  color: #f1c40f;
}

.rating.bad {
  background: #e74c3c;
  color: #e74c3c;
}

</style>
