<template>
  <button @click="login" class="authorization__button">
    {{ $t('header.login') }}
  </button>
</template>
<script>
import { LOGIN_URI } from '../const/ENDPOINTS'
export default {
  methods: {
    login () {
      window.location.href = LOGIN_URI
    }
  }
}
</script>
<style scoped lang="scss">
.authorization__button {
  border-radius: 8px;
  padding: 15px 25px;
  background: url(/img/button-bg.png);
  background-size: 100% 100%;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
}
</style>
