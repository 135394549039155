import gql from 'graphql-tag'

export const SEND_CHAT_MESSAGE = gql`mutation ($text: String!) {
    sendChatMessage (text: $text) {
        id
        chatLang
        text
        createdAt
        user {
            steamId
            name
            avatar
            chatMuted
            isBanned
        }
    }
}`

export const OPEN_CASE = gql`mutation ($caseId: Int!, $count: Int = 1) {
    caseOpen (caseId: $caseId, count: $count) {
        id
        price
        createdAt
        item {
            name
            getImage
            color
        }
    }
}`


export const SELL_ITEMS = gql`mutation ($openIds: [String!]) {
    inventoryItemSell (openIds: $openIds) {
        id
        price
    }
}`


export const WITHDRAW_ITEM = gql`mutation ($openId: String!) {
    withdrawCreate (openId: $openId) {
        id
        buyId
        tradeofferId
        status
        createdAt
    }
}`


export const SAVE_TRADE_LINK = gql`mutation ($tradeLink: String!) {
    updateTradeLink (tradeLink: $tradeLink) {
        result
    }
}`

// export const SELL_ALL = gql`mutation {
//     sellAllItems {
//         id
//         price
//     }
// }`


export const CREATE_ITEM_UPGRADE = gql`mutation ($openId: String!, $itemId: Int!) {
    upgradeItem (openId: $openId, itemId: $itemId) {
        isWin
        open {
            id
            price
            createdAt
            item {
                name
                image
                color
            }
        }
    }
}`


export const CREATE_BALANCE_UPGRADE = gql`mutation ($sum: Float!, $itemId: Int!) {
    upgradeBalance (sum: $sum, itemId: $itemId) {
        isWin
        open {
            id
            price
            createdAt
            item {
                name
                image
                color
            }
        }
    }
}`

export const BUY_ITEM = gql`mutation ($itemId: Int!) {
    marketBuy (itemId: $itemId) {
        id
        buyId
        tradeofferId
        status
        createdAt
    }
}`


export const MUTE_USER = gql`mutation ($steamId: String!, $muted: Boolean!) {
    chatMutedUser (steamId: $steamId, muted: $muted) {
        result
    }
}`

export const BANNED_USER = gql`mutation ($steamId: String!, $banned: Boolean!) {
    bannedUser (steamId: $steamId, banned: $banned) {
        result
    }
}`


export const DELETE_MESSAGE = gql`mutation ($id: String!) {
    chatDeleteMessage (id: $id) {
        result
    }
}`


export const CREATE_FREEKASSA = gql`mutation ($sum: Float!, $email: String!) {
    freekassaPayCreate (sum: $sum, email: $email)
}`


export const CREATE_ENOT = gql`mutation ($sum: Float!) {
    enotPayCreate (sum: $sum)
}`

export const CREATE_SKINBACK = gql`mutation {
    skinsbackPayCreate
}`

export const USE_PROMOCODE = gql`mutation ($code: String!) {
    usePromocode (code: $code) {
        prize
        type,
        code
    }
}`

