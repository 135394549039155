<template>
  <div class="modal">
    <div class="modal__body">
      <div class="close" @click="$emit('close')">
        <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19.4458 7.07109L7.07138 19.4455C6.58323 19.9336 6.58323 20.7251 7.07138 21.2132C7.55954 21.7014 8.351 21.7014 8.83915 21.2132L21.2135 8.83886C21.7017 8.35071 21.7017 7.55925 21.2135 7.07109C20.7254 6.58294 19.9339 6.58294 19.4458 7.07109Z" fill="white"/>
          <path opacity="0.3" d="M21.2133 19.4455L8.83896 7.07114C8.35081 6.58298 7.55935 6.58298 7.07119 7.07114C6.58304 7.55929 6.58304 8.35075 7.07119 8.83891L19.4456 21.2133C19.9337 21.7014 20.7252 21.7014 21.2133 21.2133C21.7015 20.7251 21.7015 19.9337 21.2133 19.4455Z" fill="white"/>
        </svg>
      </div>
      <div class="title">
        {{ $t('open.congratulate') }}
      </div>
      <div class="sub-title">
        {{ $t('open.win_all_count', {count: items.length}) }} <em>₽{{ parseFloat(totalPrice).toFixed(2) }}</em>
      </div>

      <div class="prize__item" v-for="(item, i) in items" :key="item.id">
        <div class="prize__item-img">
          <img :src="`${item.item.getImage}/45fx45f`" alt="">
        </div>
        <div class="prize__item-title">
          {{ item.item.name }}
        </div>
        <div class="prize__item-button" :disabled="loading === item.id" @click="sell(item.id, i)">
          <BlockLoader v-if="loading === item.id"></BlockLoader>
          <span>{{ $t('open.sell_by') }}</span>
          <div class="prize__item-button-cost">
            {{ parseFloat(item.price).toFixed(2) }}₽
          </div>
        </div>
      </div>

      <div class="label">
        {{ $t('open.take') }}
      </div>

      <div class="buttons">
        <button :disabled="allLoading" class="accent" @click="sellAll">
          <BlockLoader v-if="allLoading"></BlockLoader>
          <span>{{ $t('open.sell_all') }}</span>
          <span class="cost">
            {{ parseFloat(totalPrice).toFixed(2) }}₽
          </span>
        </button>
        <button @click="$emit('close')" class="repeat">
          <span>{{ $t('open.again') }}</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { SELL_ITEMS } from '../../queries/mutations'
import BlockLoader from '../BlockLoader'
export default {
  data () {
    return {
      allLoading: false,
      totalPrice: 0,
      loading: null
    }
  },
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  methods: {
    async sellAll () {
      this.allLoading = true
      const ids = this.items.map(item => item.id)
      await this.$apollo.mutate({
        mutation: SELL_ITEMS,
        variables: {
          openIds: ids,
        },
      }).then((data) => {
        let sum = 0
        data.data.inventoryItemSell.forEach(item => {
          sum += parseFloat(item.price)
        })
        this.$toast.success(this.$t('open.sell_many', {count: data.data.inventoryItemSell.length}) + ` ${parseFloat(sum).toFixed(2)} ₽`);
        this.$emit('close')
      }).catch((error) => {
        error.graphQLErrors.map(item => {
          this.$toast.error(item.message);
        })
      }).finally(() => {
        this.allLoading = false
      })
    },
    async sell (openId, key) {
      this.loading = openId

      await this.$apollo.mutate({
        mutation: SELL_ITEMS,
        variables: {
          openIds: [openId],
        },
      }).then((data) => {
        this.$toast.success(this.$t('open.success_sell', {amount: parseFloat(data.data.inventoryItemSell[0].price).toFixed(2)}));
        this.items.splice(key, 1)
        this.setTotal()

        if (!this.items.length) {
          this.$emit('close')
        }
      }).catch((error) => {
        error.graphQLErrors.map(item => {
          this.$toast.error(item.message);
        })
      }).finally(() => {
        this.loading = null
      })

    },
    setTotal () {
      let sum = 0
      this.items.forEach(item => {
        sum += parseFloat(item.price)
      })

      this.totalPrice = sum
    }
  },
  components: {
    BlockLoader
  },
  watch: {
    'items.length': function () {
      this.setTotal ()
    }
  },
  mounted () {
    this.setTotal ()
  },
}
</script>
<style scoped lang="scss">
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    width: 100%;
    background: #1D1E23;
    background: rgba(36, 37, 44, 0.65);
    z-index: 999;
    backdrop-filter: blur(22.5px);
    display: flex;
    align-items: center;
    justify-content: center;
    .modal__body {
      width: 540px;
      background: #18191D;
      max-width: 95%;
      border-radius: 12px;
      padding: 30px;
      @media (max-width: 575px) {
        padding: 10px;
      }
      .title {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        display: block;
        margin-bottom: 6px;
        text-align: center;
      }
      .sub-title {
        margin-bottom: 30px;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        width: 100%;
        color: #7B7C83;
        em {
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #66BF57;
          font-style: normal;
        }
      }
      .prize__item {
        width: 100%;
        background: #0E0E11;
        border-radius: 8px;
        padding: 9px;
        display: flex;
        .prize__item-img {
          min-width: 45px;
          width: 45px;
          margin-right: 15px;
          @media (max-width: 575px) {
            margin-right: 5px;
            min-width: 35px;
            width: 35px;
          }
          img {
            max-height: 45px;
            max-width: 100%;
            @media (max-width: 575px) {
              max-height: 35px;
            }
          }
        }
        .prize__item-title {
          padding-right: 5px;
          display: flex;
          align-items: center;
          font-weight: 700;
          flex-grow: 1;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 14px;
          line-height: 17px;
          @media (max-width: 575px) {
            font-size: 12px;
          }
        }
        .prize__item-button {
          background: #FE5E3B;
          border-radius: 8px;
          position: relative;
          padding: 6px;
          display: flex;
          margin-left: auto;
          cursor: pointer;
          transition: 0.2s;
          align-items: center;
          justify-content: center;
          padding-left: 14px;
          &[disabled] {
            cursor: not-allowed;
            background: #7B7C83;
          }
          &:hover {
            opacity: 0.8;
          }
          span {
            font-weight: 700;
            font-size: 12px;
            line-height: 15px;
            text-align: center;
            white-space: nowrap;
            text-transform: uppercase;
            margin-right: 10px;
            @media (max-width: 575px) {
              font-size: 10px;
            }
          }
          .prize__item-button-cost {
            width: 80px;
            padding: 7.5px 0;
            text-align: center;
            background: #18191D;
            border-radius: 6px;
            font-weight: 700;
            font-size: 12px;
            line-height: 15px;
            text-transform: uppercase;
            @media (max-width: 575px) {
              font-size: 10px;
            }
          }
        }
      }
      .prize__item + .prize__item {
        margin-top: 10px;
      }

      .label {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: #7B7C83;
        margin-top: 20px;
        width: 100%;
        margin-bottom: 15px;
      }
      .buttons {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 15px;
        @media (max-width: 575px) {
          grid-template-columns: repeat(1, 1fr);
        }
        button {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          @media (max-width: 575px) {
            height: 50px;
          }
          &[disabled] {
            cursor: not-allowed;
            background: #7B7C83;
          }
          &.accent {
            background: #FE5E3B;
            border-radius: 8px;
            padding: 8px 10px;
            padding-left: 21px;

          }
          &.repeat {
            background: #66BF57;
            border-radius: 8px;
          }
          .cost {
            margin-left: 10px;
            padding: 10px 0;
            background: #18191D;
            border-radius: 6px;
            width: 86px;
            min-width: 86px;
            text-align: center;
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            text-transform: uppercase;
          }
          span {
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            white-space: nowrap;
            text-align: center;
            text-transform: uppercase;
          }
        }
      }

      position: relative;
      .close {
        right: 25px;
        position: absolute;
        top: 30px;
        transition: 0.2s;
        cursor: pointer;
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
</style>
