import { INVENTORY_TAB } from '../../../const/PROFILE_TABS'
const state = () => ({
    activeTab: INVENTORY_TAB,
});

const getters = {
    activeTab: state => state.activeTab,
    inventory: state => state.inventory
};

const actions = {
    setTab ({ commit }, tab) {
        commit('SET_TAB', tab)
    }
};

const mutations = {
    SET_TAB (state, tab) {
        state.activeTab = tab
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
