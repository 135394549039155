<template>
  <div class="profile__withdraw">
    <div class="profile__withdraw-head" v-if="withdrawHistory && withdrawHistory.items.length > 0">
      <div class="id">{{ $t('profile.withdraw_id') }}</div>
      <div class="product">{{ $t('profile.withdraw_product') }}</div>
      <div class="cost">{{ $t('profile.withdraw_cost') }}</div>
      <div class="status">{{ $t('profile.withdraw_status') }}</div>
      <div class="date">{{ $t('profile.withdraw_date') }}</div>
      <div class="actions"></div>
    </div>
    <div class="profile__withdraw-items" v-if="withdrawHistory">
      <WithdrawItem :withdraw="withdraw" v-for="(withdraw, i) in withdrawHistory.items" :key="i" />
    </div>

    <EmptyInventory :text="$t('profile.empty_withdraws')" v-if="withdrawHistory && !withdrawHistory.items.length"></EmptyInventory>

    <button class="load-more" v-if="showMoreEnabled" @click="showMore">
      {{ $t('profile.load_more') }}
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 14C6.10457 14 7 13.1046 7 12C7 10.8954 6.10457 10 5 10C3.89543 10 3 10.8954 3 12C3 13.1046 3.89543 14 5 14Z" fill="#83848E"/>
        <path d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z" fill="#83848E"/>
        <path d="M19 14C20.1046 14 21 13.1046 21 12C21 10.8954 20.1046 10 19 10C17.8954 10 17 10.8954 17 12C17 13.1046 17.8954 14 19 14Z" fill="#83848E"/>
      </svg>
    </button>
  </div>
</template>
<script>
import { GET_WITHDRAWS } from '../../queries/index'
import WithdrawItem from './WithdrawItem'
import { WITHDRAW_UPDATES } from '../../queries/subscriptions'
import EmptyInventory from '../EmptyInventory'
const TAKE = 10
export default {
  data () {
    return {
      page: 0,
    }
  },
  components: {
    EmptyInventory,
    WithdrawItem
  },
  computed: {
    showMoreEnabled () {
      if (!this.withdrawHistory) {
        return false;
      }
      return this.withdrawHistory.total > this.withdrawHistory.items.length
    }
  },
  methods: {
    async updateStatusWithdraw (withdrawUpdate) {
      await this.$nextTick()
      if (!withdrawUpdate || !withdrawUpdate.id) return;

      const findProduct = this.withdrawHistory.items.findIndex(item => item && item.id === withdrawUpdate.id)
      if (findProduct > -1) {
        this.withdrawHistory.items[findProduct].tradeofferId = withdrawUpdate.tradeofferId
        this.withdrawHistory.items[findProduct].status = withdrawUpdate.status
      }
    },
    async showMore () {
      this.page++
      // Fetch more data and transform the original result
      await this.$apollo.queries.withdrawHistory.fetchMore({
        // New variables
        variables: {
          take: TAKE,
          skip: TAKE * this.page
        },
        // Transform the previous result with new data
        updateQuery: (previousResult, { fetchMoreResult }) => {
          return {
            withdrawHistory: {
              __typename: previousResult.withdrawHistory.__typename,
              items: [...previousResult.withdrawHistory.items, ...fetchMoreResult.withdrawHistory.items],
              total: fetchMoreResult.withdrawHistory.total,
            },
          }
        },
      })
    },
  },
  apollo: {
    withdrawHistory: {
      fetchPolicy: 'network-only',
      query: GET_WITHDRAWS,
      variables () {
        return {
          take: TAKE,
        }
      },
      update: data => data.withdrawHistory
    },
    $subscribe: {
      WithdrawUpdatesStream: {
        query: WITHDRAW_UPDATES,
        result ({ data }) {
          this.updateStatusWithdraw(data.WithdrawUpdatesStream)
        },
      }
    }
  },
}
</script>
<style scoped lang="scss">
.profile__inventory-empty {
  margin-top: 25px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #676974;
  margin-bottom: 11px;
}

.profile__withdraw {
  padding-bottom: 20px;
  .id {
    width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 120px;
    @media (max-width: 992px) {
      min-width: 140px;
    }
  }
  @media (max-width: 600px) {
    min-width: 600px;
  }
  .product {
    width: 120px;
    min-width: 120px;
    @media (max-width: 992px) {
      min-width: 90px;
    }
  }
  .cost {
    width: 130px;
    min-width: 130px;
  }
  .status {
    width: 130px;
    min-width: 130px;
  }
  .date {
    width: 120px;
    min-width: 120px;

  }
  .actions {
    display: flex;
    justify-content: flex-end;
    min-width: 200px;
    width: 200px;
  }
  @media (max-width: 1400px) {
    & > div {
      margin-right: 15px !important;
    }
  }
}
.profile__withdraw-head {
  display: flex;
  padding: 0 30px;
  justify-content: space-between;
  @media (max-width: 1450px) {
    padding: 0 10px;
    .actions, .date {
      display: none;
    }
    .status {
      min-width: 170px;
    }
  }
  @media (max-width: 600px) {
    .status {
      padding-left: 15px;
    }
  }
  & > div {
    padding: 15px 0;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #676974;
    @media (max-width: 992px) {
      font-size: 12px;
    }

  }

}
.profile__withdraw-items {
  width: 100%;
}
.load-more {
  display: flex;
  background: #27282F;
  border-radius: 6px;
  margin: 0 auto;
  margin-top: 20px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 15px 36px;
  svg {
    margin-left: 8px;
  }
  color: #83858E;
}
</style>
