<template>
  <div class="upgrade__panel" :class="{'disabled': disabled, 'balance': betType === BET_BALANCE_TYPE}">
    <div class="upgrade__panel-item" v-if="!disabled">
      <div class="use-balance" @click="setBetType(BET_BALANCE_TYPE)" v-if="betType === BET_PRODUCT_TYPE">
        {{ $t('upgrade.use_balance') }}
      </div>

      <SelectBalanceRanger v-if="betType === BET_BALANCE_TYPE"></SelectBalanceRanger>
    </div>
    <div class="upgrade__panel-item justify-center">
      <div class="upgrade-block">
        <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M12.6728 0.480713C12.938 0.480713 13.1923 0.58607 13.3799 0.773606C13.5674 0.961143 13.6728 1.2155 13.6728 1.48071V6.48071C13.6728 6.74593 13.5674 7.00028 13.3799 7.18782C13.1923 7.37536 12.938 7.48071 12.6728 7.48071H7.67276C7.40754 7.48071 7.15319 7.37536 6.96565 7.18782C6.77812 7.00028 6.67276 6.74593 6.67276 6.48071C6.67276 6.2155 6.77812 5.96114 6.96565 5.77361C7.15319 5.58607 7.40754 5.48071 7.67276 5.48071H10.6747C10.1318 4.75625 9.40254 4.19269 8.56455 3.85014C7.72657 3.50758 6.81133 3.39887 5.91642 3.53559C5.0215 3.67232 4.18047 4.04935 3.483 4.62649C2.78552 5.20362 2.25775 5.95923 1.95596 6.81274C1.91641 6.94157 1.85123 7.06108 1.76433 7.16408C1.67744 7.26708 1.57062 7.35146 1.45029 7.41214C1.32996 7.47283 1.19861 7.50856 1.06412 7.5172C0.929639 7.52584 0.794795 7.50721 0.667691 7.46243C0.540587 7.41765 0.423846 7.34764 0.32448 7.2566C0.225114 7.16557 0.145173 7.05539 0.0894595 6.93268C0.033746 6.80997 0.00340919 6.67727 0.000271166 6.54255C-0.00286686 6.40782 0.0212587 6.27385 0.0711988 6.14868C0.46759 5.02808 1.14273 4.02683 2.03302 3.23927C2.9233 2.4517 3.99943 1.90374 5.16001 1.647C6.3206 1.39027 7.52744 1.43321 8.66684 1.77178C9.80625 2.11036 10.8407 2.73342 11.6728 3.58228V1.48071C11.6728 1.2155 11.7781 0.961143 11.9657 0.773606C12.1532 0.58607 12.4075 0.480713 12.6728 0.480713Z" fill="white"/>
        </svg>
      </div>
    </div>
    <div class="upgrade__panel-item justify-right">
      <button class="upgrade-btn" @click="clearUpgradeState" v-if="status === 'finish'">
        {{ $t('upgrade.try_again') }}
      </button>
      <button v-else class="upgrade-btn" @click="start" :disabled="!availableUpgrade || disabled || loading">
        <template v-if="status === 'process'">
          {{ $t('upgrade.upgrading') }}
        </template>
        <template v-else>{{ $t('upgrade.call_upgrade') }}</template>
        <BlockLoader v-if="loading"></BlockLoader>
      </button>
    </div>
  </div>
</template>
<script>
import { BET_PRODUCT_TYPE, BET_BALANCE_TYPE } from '../../const/UPGRADE_VARIABLES'
import { mapGetters, mapActions } from 'vuex'
import SelectBalanceRanger from './SelectBalanceRanger'
import BlockLoader from '../BlockLoader'
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      BET_PRODUCT_TYPE,
      BET_BALANCE_TYPE
    }
  },
  watch: {
    status () {
      this.loading = false
    },
    betType () {
      this.loading = false
    },
    selectInventory () {
      this.loading = false
    }
  },
  methods: {
    start () {
      this.loading = true
      this.$root.$emit('startUpgrade')
    },
    ...mapActions({
      clearUpgradeState: 'upgrade/clearUpgradeState',
      setBetType: 'upgrade/setBetType'
    })
  },
  computed: {
    ...mapGetters({
      status: 'upgrade/status',
      selectInventory: 'upgrade/selectInventory',
      betType: 'upgrade/betType',
      availableUpgrade: 'upgrade/availableUpgrade',
      selectUpgrade: 'upgrade/selectUpgrade'
    }),
  },
  components: {
    BlockLoader,
    SelectBalanceRanger
  }
}
</script>
<style scoped lang="scss">
.upgrade__panel {
  background: #1D1E23;
  padding: 9px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 9;
  padding-left: 18px;
  @media (max-width: 1600px) {
    &.balance {
      align-items: unset;
    }
  }
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
  .upgrade__panel-item {
    .upgrade-block {
      width: 42px;
      height: 42px;
      background: #131419;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: calc(50% - 21px);
      left: calc(50% - 21px);
      &::after {
        content: "";
        display: block;
        width: 20px;
        position: absolute;
        left: calc(50% - 10px);
        bottom: 0;
        height: 2px;
        background: #FE5E3B;
        border-radius: 12px;
      }

      @media (max-width: 768px) {
        position: relative;
        left: unset;
        top: unset;
        margin: 0 auto;
      }
    }
    .upgrade-btn {
      padding: 12.5px 70px;
      background: #66BF57;
      border-radius: 4px;
      position: relative;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      @media (max-width: 768px) {
        width: 100%;
        margin-bottom: 10px;
        margin-top: 20px;
      }
    }
    .use-balance {
      font-weight: 700;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.05em;
      width: fit-content;
      text-transform: uppercase;
      color: #898EAF;
      padding-bottom: 3px;
      transition: 0.1s;
      border-bottom: 2px solid #424556;
      cursor: pointer;
      &:hover {
        border-color: #FE5E3B;
        color: #FE5E3B
      }
      @media (max-width: 768px) {
        justify-content: center;
        text-align: center;
        margin: 0 auto;
        margin-top: 10px;
        margin-bottom: 20px;
      }
    }
    &.justify-right {
      display: flex;
      justify-content: flex-end;
    }
    &.justify-center {
      display: flex;
      justify-content: center;
    }

    @media (max-width: 768px) {
      text-align: center;
      justify-content: center;
      width: 100%;
    }
  }
}
</style>
