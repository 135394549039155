<template>
  <div class="profile__withdraw-item">
    <div class="id" v-tooltip="withdraw.id">
      <input type="text" @click="copyId(withdraw.id)" readonly :value="getId">
    </div>
    <div class="product" v-tooltip="withdraw.item.name">
      <img :src="`https://community.akamai.steamstatic.com/economy/image/${withdraw.item.image}/360fx360f`" alt="">
    </div>
    <div class="cost">
      ₽ {{ parseFloat(withdraw.price).toFixed(2) }}
    </div>
    <div class="status">
      <template v-if="getStatus === 'cancel'">
        <svg v-tooltip="$t('statuses.' + withdraw.status)" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.3" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#F74747"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0355 10.6213L14.864 7.79289C15.2545 7.40237 15.8877 7.40237 16.2782 7.79289C16.6687 8.18342 16.6687 8.81658 16.2782 9.20711L13.4497 12.0355L16.2782 14.864C16.6687 15.2545 16.6687 15.8877 16.2782 16.2782C15.8877 16.6687 15.2545 16.6687 14.864 16.2782L12.0355 13.4497L9.20711 16.2782C8.81658 16.6687 8.18342 16.6687 7.79289 16.2782C7.40237 15.8877 7.40237 15.2545 7.79289 14.864L10.6213 12.0355L7.79289 9.20711C7.40237 8.81658 7.40237 8.18342 7.79289 7.79289C8.18342 7.40237 8.81658 7.40237 9.20711 7.79289L12.0355 10.6213Z" fill="#F74747"/>
        </svg>
        <span v-tooltip="$t('statuses.' + withdraw.status)">{{ $t('profile.error') }}</span>
      </template>
      <template v-else-if="getStatus === 'success'">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.3" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#599B4E"/>
          <path d="M16.769 7.81769C17.1458 7.41393 17.7786 7.39211 18.1823 7.76895C18.5861 8.14578 18.6079 8.77857 18.2311 9.18232L11.2311 16.6823C10.8655 17.074 10.2561 17.108 9.84923 16.7593L6.34923 13.7593C5.9299 13.3998 5.88134 12.7685 6.24076 12.3492C6.60018 11.9299 7.23148 11.8813 7.65081 12.2407L10.423 14.6169L16.769 7.81769Z" fill="#599B4E"/>
        </svg>
        <span :class="[getStatus]">{{ $t('profile.success') }}</span>
      </template>
      <template v-else-if="withdraw.status === 'waitingAccept'">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.3" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#599B4E"/>
          <path d="M16.769 7.81769C17.1458 7.41393 17.7786 7.39211 18.1823 7.76895C18.5861 8.14578 18.6079 8.77857 18.2311 9.18232L11.2311 16.6823C10.8655 17.074 10.2561 17.108 9.84923 16.7593L6.34923 13.7593C5.9299 13.3998 5.88134 12.7685 6.24076 12.3492C6.60018 11.9299 7.23148 11.8813 7.65081 12.2407L10.423 14.6169L16.769 7.81769Z" fill="#599B4E"/>
        </svg>
        <span class="sent">{{ $t('profile.trade_sent') }}</span>
      </template>
      <template v-else>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M12 21C7.58172 21 4 17.4183 4 13C4 8.58172 7.58172 5 12 5C16.4183 5 20 8.58172 20 13C20 17.4183 16.4183 21 12 21Z" fill="#CDA12F"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M13 5.06189C12.6724 5.02104 12.3387 5 12 5C11.6613 5 11.3276 5.02104 11 5.06189V4H10C9.44772 4 9 3.55228 9 3C9 2.44772 9.44772 2 10 2H14C14.5523 2 15 2.44772 15 3C15 3.55228 14.5523 4 14 4H13V5.06189Z" fill="#CDA12F"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M16.71 6.5327L17.5356 5.70708C17.9261 5.31655 18.5593 5.31655 18.9498 5.70708C19.3403 6.0976 19.3403 6.73077 18.9498 7.12129L18.1672 7.9039C17.7408 7.38852 17.2511 6.92747 16.71 6.5327Z" fill="#CDA12F"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9631 7.5H12.0371C12.2984 7.5 12.5156 7.70115 12.5356 7.96165L12.9587 13.4617C12.9799 13.737 12.7738 13.9773 12.4985 13.9985C12.4858 13.9995 12.473 14 12.4602 14H11.54C11.2639 14 11.04 13.7761 11.04 13.5C11.04 13.4872 11.0405 13.4744 11.0415 13.4617L11.4646 7.96165C11.4846 7.70115 11.7018 7.5 11.9631 7.5Z" fill="#CDA12F"/>
        </svg>
        <span class="process">{{ $t('profile.in_process') }}</span>
      </template>

    </div>
    <div class="date">
      {{ getDate }}
    </div>
    <div class="actions">
      <button @click="goToTrade" class="accept" v-if="withdraw.status === 'waitingAccept' && withdraw.tradeofferId">
        {{ $t('profile.accept_trade') }}
        <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.32539 7.23081L4.43456 7.32438L4.53267 7.21927L10.8786 0.420033C11.1989 0.0768451 11.7368 0.0582979 12.08 0.378607C12.4232 0.698916 12.4417 1.23679 12.1214 1.57997L5.12142 9.07997C4.81065 9.41294 4.29266 9.44178 3.94685 9.14537L0.446845 6.14537C0.0904185 5.83986 0.0491412 5.30326 0.35465 4.94683C0.660159 4.5904 1.19676 4.54913 1.55319 4.85464L4.32539 7.23081Z" fill="white" stroke="#FE5E3B" stroke-width="0.3"/>
        </svg>
      </button>
    </div>
  </div>
</template>
<script>
import { getStatusByStatus } from '../../helpers/getStatusByStatus'
import moment from 'moment'
export default {
  props: {
    withdraw: {
      type: Object,
      required: true
    }
  },
  computed: {
    getDate () {
      return moment(this.withdraw.createdAt).format('MM.DD.YYYY HH:mm')
    },
    getId () {
      const id = this.withdraw.id
      return id[0] + id[1] + id[2] + id[3] + id[4] + id[5] + '...' + id[id.length - 4] + id[id.length - 3] + id[id.length - 2] + id[id.length - 1]
    },
    getStatus () {
      return getStatusByStatus(this.withdraw.status)
    }
  },
  methods: {
    copyId (text) {
      navigator.clipboard.writeText(text).then(() => {
        this.$toast.success(this.$t('profile.copied'))
      }, function() {});
    },
    goToTrade () {
      window.open(`https://steamcommunity.com/tradeoffer/${this.withdraw.tradeofferId}/`, '_blank')
    }
  }
}
</script>
<style scoped lang="scss">
.profile__withdraw-item {
  padding: 11px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #1D1E23;
  @media (max-width: 1400px) {
    padding: 11px 10px;
  }
  &:nth-child(even) {
    background: #23242A;
  }
  .product {
    display: flex;
    align-items: center;
    img {
      height: 30px;
    }
    .product-view {
      width: 38px;
      height: 38px;
      margin-left: 12px;
      background: #2D2E36;
      border-radius: 6px;
      display: flex;
      transition: 0.2s;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .cost {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
  }
  .actions {
    display: flex;
    justify-content: flex-end;
    .accept {
      background: #FE5E3B;
      border-radius: 4px;
      padding: 9px 17px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        margin-left: 5px;
      }
    }
  }
  .date {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
  }
  .status {
    display: flex;
    align-items: center;
    svg {
      min-width: 24px;
    }
    span {
      white-space: nowrap;
      margin-left: 5px;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #F64747;

      &.success, &.sent {
        color: #599B4E;
      }
      &.process {
        color: #CDA12F;
      }
    }
  }
  .id {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
  }
  @media (max-width: 1450px) {
    flex-wrap: wrap;
    .date {
      width: 50%;
    }
    .actions {
      width: 50%;
    }
    .date, .actions {
      margin-top: 10px;
    }
    .status {
      min-width: 170px;
    }
  }
  .actions {
    @media (max-width: 1400px) {

    }
  }
}
.id {
  cursor: pointer;
  input {
    outline: none;
    cursor: pointer;
    background: transparent;
    border: none;
    max-width: 100%;
  }
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 120px;
  transition: 0.2s;
  &:hover {
    opacity: 0.7;
  }
  @media (max-width: 992px) and (min-width: 600px) {
    min-width: 140px;
  }
}
.product {
  width: 120px;
  min-width: 120px;
  @media (max-width: 992px) {
    min-width: 90px;
  }
}
.cost {
  width: 130px;
  min-width: 130px;
}
.status {
  width: 130px;
  min-width: 130px;
}
.date {
  width: 120px;
  min-width: 120px;
}
.actions {
  display: flex;
  justify-content: flex-end;
  min-width: 200px;
  width: 200px;
}
</style>
