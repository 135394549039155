<template>
  <div class="main">
    <Loader v-if="$apollo.loading"></Loader>
    <EventCases v-if="!$apollo.loading && eventCases && (eventCases.length || eventCases.length === 5)" :cases="eventCases"></EventCases>
    <CasesFilters @filter="filterPrice = $event" @search="search = $event"></CasesFilters>
    <Cases v-for="(caseItem, i) in getCases" :cases="caseItem" :key="i" />
    <div class="empty" v-if="!getCases || !getCases.length">
      {{ $t('cases_not_found') }}
    </div>
  </div>
</template>
<script>
import { GET_CATEGORIES, GET_EVENT_CASES } from '../queries/index'
import Loader from '../components/Loader'
import CasesFilters from '../components/CasesFilters'
import Cases from '../components/Cases'
import EventCases from "@/components/EventCases.vue";
export default {
  data () {
    return {
      filterPrice: '0-9999999',
      search: ''
    }
  },
  components: {
    Cases,
    CasesFilters,
    Loader,
    EventCases
  },
  computed: {
    getCases () {
      const categories = this.categories
      if (!categories) {
        return []
      }

      return categories.reduce((categoriesAcc, category) => {
        const filteredCases = category.cases.reduce((casesAcc, box) => {
          const boxPrice = parseFloat(box.price)
          const searchString = this.search.toLowerCase()
          const filterPrice = this.filterPrice;

          const [filterPriceFrom, filterPriceTo] = filterPrice ? filterPrice.split('-') : ['', '']
          const [ruName, enName] = [box.name.ru.toLowerCase(), box.name.en.toLowerCase()]

          const isSearchMatched = ruName.includes(searchString) || enName.includes(searchString)
          const isPriceFilterMatched = boxPrice >= filterPriceFrom && boxPrice <= filterPriceTo

          return isPriceFilterMatched && isSearchMatched ? [ ...casesAcc, box ] : casesAcc
        }, [])

        return filteredCases.length ? [ ...categoriesAcc, { ...category, cases: [ ...filteredCases ] }] : categoriesAcc
      }, [])

      // if (this.filterPrice) {
      //   const prices = this.filterPrice.split('-')
      //   const from = parseFloat(prices[0])
      //   const to = parseFloat(prices[1])
      //   return categories.filter(category => {
      //     let filteredCases = category.cases.filter(item => {
      //       return parseFloat(item.price) >= from && parseFloat(item.price) <= to
      //     })
      //
      //     return filteredCases.length
      //   })
      // }
      //
      // return categories
    }
  },
  apollo: {
    categories: {
      query: GET_CATEGORIES,
      update: data => data.categories,
    },
    eventCases: {
      query: GET_EVENT_CASES,
      update: data => data.eventCases,
    }
  }
}
</script>
<style scoped lang="scss">
.main {
  position: relative;
  .empty {
    background: #131419;
    border: 1px solid #2B2D34;
    border-radius: 8px;
    padding: 27px 56px;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    line-height: 17px;
  }
}
</style>
