<template>
  <div class="show__prize">
    <OpenCaseFinish @close="$emit('restart')" :item="winners[0]" :case-item="caseItem" v-if="winners.length === 1"></OpenCaseFinish>
    <transition name="fade">
      <PrizesModal @close="$emit('restart')" :items="getWinners" v-if="winners.length > 1"></PrizesModal>
    </transition>
  </div>
</template>
<script>
import OpenCaseFinish from './OpenCaseFinish'
import PrizesModal from '../Modals/PrizesModal'
export default {
  props: {
    winners: {
      type: Array,
      required: true
    },
    caseItem: {
      type: Object,
      required: true
    }
  },
  computed: {
    getWinners () {
      return [...this.winners]
    }
  },
  components: {
    PrizesModal,
    OpenCaseFinish
  }
}
</script>
<style scoped lang="scss">
.show__prize {

}
</style>
