import { BET_PRODUCT_TYPE, BET_BALANCE_TYPE } from '../../../const/UPGRADE_VARIABLES'
const state = () => ({
    selectInventory: null,
    betType: BET_PRODUCT_TYPE,
    selectUpgrade: null,
    useSelectBalance: 5,
    filterConfig: {
        name: null,
        priceSort: 'desc',
        priceFrom: 0,
        priceTo: 99999
    },
    inventoryFilters: {
        priceSort: 'desc',
        priceFrom: 0,
        priceTo: 99999
    },
    status: 'start'
});

const getters = {
    status: state => state.status,
    inventoryFilters: state => state.inventoryFilters,
    filterConfig: state => state.filterConfig,
    selectInventory: state => state.selectInventory,
    betType: state => state.betType,
    selectUpgrade: state => state.selectUpgrade,
    useSelectBalance: state => state.useSelectBalance,
    upgradePercent: state => {
        if (!state.selectUpgrade) return 0;
        let bet = 0;
        if (state.betType === BET_BALANCE_TYPE) {
            bet = parseFloat(state.useSelectBalance)
        } else {
            if (!state.selectInventory) return 0;
            bet = parseFloat(state.selectInventory.price)
        }

        const itemPrice = state.selectUpgrade.price

        let percent = +Number((bet / itemPrice) * 100).toFixed(2);


        return percent
    },
    availableUpgrade: (state, getters) => {
        return parseFloat(getters.upgradePercent) > 0
    },
    betweenBalanceBet: (state, getters, rootGetters) => {
        if (getters.selectUpgrade) {
            return {
                priceFrom: parseFloat(getters.selectUpgrade.price * 0.05).toFixed(2),
                priceTo: parseFloat(getters.selectUpgrade.price * 0.80).toFixed(2)
            }
        }
        return {
            priceFrom: 0,
            priceTo: parseFloat(rootGetters.auth.user.balance).toFixed(2)
        }
    }
};

const actions = {
    async clearUpgradeState ({ commit, rootGetters }) {
        await commit('CLEAR_DATA', rootGetters['main/config'].getUpgradeConfig.upgradeMinSum)
    },
    setStatus ({ commit }, status) {
      commit('SET_STATUS', status)
    },
    async setSelectBalance ({ commit, dispatch }, amount) {
        await commit('SET_SELECT_BALANCE', amount)
        dispatch('calculateShopPriceFrom', amount)
    },
    async setInventory ({ commit, dispatch, getters }, inventory) {
        await commit('SET_INVENTORY', inventory)
        if (!getters.selectUpgrade) {
            await dispatch('calculateShopPriceFrom', inventory.price)
        }
    },
    deleteInventory ({ commit }) {
        commit('DELETE_INVENTORY')
    },
    async setUpgrade ({ commit, dispatch, getters }, upgrade) {
        await commit('SET_UPGRADE', upgrade)
        if (!getters.selectInventory && getters.betType !== BET_BALANCE_TYPE) {
            await dispatch('calculateInventoryPriceFrom', upgrade.price)
        }
    },
    deleteUpgrade ({ commit }) {
        commit('DELETE_UPGRADE')
    },
    async setBetType ({ commit, dispatch, rootGetters }, type) {
        await commit('SET_BET_TYPE', type)
        await commit('DELETE_INVENTORY', type)
        await commit('DELETE_UPGRADE', type)
        if (type === BET_PRODUCT_TYPE) {
            await commit('CLEAR_DATA')
        } else {
            await dispatch('calculateShopPriceFrom', rootGetters.main.config.getUpgradeConfig.upgradeMinSum ?? 5)
        }
    },
    calculateInventoryPriceFrom ({ commit }, sum) {
        commit('setInventoryPriceFrom', {
            priceFrom: parseFloat(sum * 0.05).toFixed(2),
            priceTo: parseFloat(sum * 0.80).toFixed(2)
        })
    },
    calculateShopPriceFrom ({ commit }, sum) {
        commit('setUpgradePriceFrom', {
            priceFrom: parseFloat(sum * 1.25).toFixed(2),
            priceTo: sum > 0 ? parseFloat(sum * 20).toFixed(2) : 99999
        })
    }
};

const mutations = {
    CLEAR_DATA (state, upgradeMinSum) {
        state.selectInventory = null
        state.betType = BET_PRODUCT_TYPE
        state.selectUpgrade = null
        state.useSelectBalance = upgradeMinSum
        state.filterConfig = {
            name: null,
            priceSort: 'desc',
            priceFrom: 0,
            priceTo: 99999
        }
        state.inventoryFilters = {
            priceSort: 'desc',
            priceFrom: 0,
            priceTo: 99999
        }
        state.status = 'start'
    },
    SET_STATUS (state, status) {
        state.status = status
    },
    SET_SELECT_BALANCE (state, amount) {
        state.useSelectBalance = amount
    },
    SET_INVENTORY (state, inventory) {
        state.selectInventory = inventory
    },
    DELETE_INVENTORY (state) {
        state.selectInventory = null
        state.filterConfig.priceFrom = 0
        state.filterConfig.priceTo = 99999
    },
    SET_UPGRADE (state, upgrade) {
        state.selectUpgrade = upgrade
    },
    DELETE_UPGRADE (state) {
        state.selectUpgrade = null
        state.inventoryFilters.priceFrom = 0
        state.inventoryFilters.priceTo = 99999
    },
    SET_BET_TYPE (state, type) {
        state.betType = type
    },
    setUpgradePriceFrom (state, payload) {
        state.filterConfig.priceFrom = payload.priceFrom
        state.filterConfig.priceTo = payload.priceTo
    },
    setInventoryPriceFrom (state, payload) {
        state.inventoryFilters.priceFrom = payload.priceFrom
        state.inventoryFilters.priceTo = payload.priceTo
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
