<template>
  <div class="upgrade wrapper-bg" v-if="initialized">
    <div class="title-wrapper">
      <div class="title">
        upgrade
      </div>
    </div>

    <UpgradeGame></UpgradeGame>
    <UpgradeGamePanel v-if="$root.isAuth" :disabled="isProcessGame"></UpgradeGamePanel>
    <UpgradeGamePanelUnauthorized v-else></UpgradeGamePanelUnauthorized>
    <div class="separator-title">
      <span>{{ $t('upgrade.select') }}</span>
      <div class="line"></div>
    </div>

    <div class="upgrade__inventories">
      <div class="upgrade__inventory">
        <div class="upgrade__inventory-head">
          <InventoryFilters :disabled="betType !== BET_PRODUCT_TYPE || isProcessGame || !$root.isAuth" :filterConfig="inventoryFilters" :title="$t('inventory')"></InventoryFilters>
        </div>
        <UpgradeInventoryWrapper v-if="$root.isAuth" :disabled="betType !== BET_PRODUCT_TYPE || isProcessGame"></UpgradeInventoryWrapper>
        <InventoryUnauthorized v-else></InventoryUnauthorized>
      </div>
      <div class="upgrade__inventory">
        <div class="upgrade__inventory-head">
          <InventoryFilters :disabled="isProcessGame || !$root.isAuth" :title="$t('upgrade.upgrade_title')" :searchable="true" :filterConfig="filterConfig"></InventoryFilters>
        </div>
        <UpgradeShopWrapper v-if="$root.isAuth" :disabled="isProcessGame"></UpgradeShopWrapper>
        <InventoryUnauthorized v-else></InventoryUnauthorized>
      </div>
    </div>
  </div>
</template>
<script>
import { BET_PRODUCT_TYPE } from '../const/UPGRADE_VARIABLES'
import UpgradeGame from '../components/Upgrade/UpgradeGame'
import UpgradeGamePanelUnauthorized from '../components/Upgrade/UpgradeGamePanelUnauthorized'
import UpgradeGamePanel from '../components/Upgrade/UpgradeGamePanel'
import InventoryFilters from '../components/Upgrade/InventoryFilters'
import UpgradeInventoryWrapper from '../components/Upgrade/UpgradeInventoryWrapper'
import UpgradeShopWrapper from '../components/Upgrade/UpgradeShopWrapper'
import InventoryUnauthorized from '../components/Upgrade/InventoryUnauthorized'
import { mapGetters, mapActions } from 'vuex'
export default {
  data () {
    return {
      initialized: false,
      BET_PRODUCT_TYPE
    }
  },
  methods: {
    ...mapActions({
      clearUpgradeState: 'upgrade/clearUpgradeState',
    })
  },
  computed: {
    isProcessGame () {
      return ['process', 'finish'].includes(this.status)
    },
    ...mapGetters({
      status: 'upgrade/status',
      inventoryFilters: 'upgrade/inventoryFilters',
      filterConfig: 'upgrade/filterConfig',
      betType: 'upgrade/betType'
    })
  },
  async mounted () {
    await this.clearUpgradeState()
    this.initialized = true
  },
  components: {
    InventoryFilters,
    UpgradeGamePanelUnauthorized,
    InventoryUnauthorized,
    UpgradeShopWrapper,
    UpgradeInventoryWrapper,
    UpgradeGame,
    UpgradeGamePanel
  }
}
</script>
<style scoped lang="scss">
  .upgrade {
    overflow: hidden;
    padding: 0 80px;
    @media (max-width: 1700px) {
      padding: 0 50px;
    }
    @media (max-width: 1600px) {
      padding: 0 20px;
    }
    @media (max-width: 768px) {
      padding: 0 10px;
    }
   .upgrade__inventories {
     display: flex;
     .upgrade__inventory {
       width: 50%;
       margin-bottom: 20px;
       .upgrade__inventory-head {
         padding: 14px 13px;
         background: #1D1E23;
       }
       @media (max-width: 768px) {
         margin-right: 0;
         width: 100%;
       }
     }
     @media (max-width: 768px) {
       flex-wrap: wrap;
     }
     .upgrade__inventory__wrapper {
       margin-right: 10px;
       @media (max-width: 768px) {
         margin-right: 0;
         width: 100%;
       }
     }

     .upgrade__inventory:first-child {
       ::v-deep {
         .upgrade__inventory__wrapper {
           margin-right: 10px;
           @media (max-width: 768px) {
             margin-left: 0;
           }
         }
       }
       .upgrade__inventory-head {
         border-top-left-radius: 12px;
         border-bottom-left-radius: 12px;
       }
       .inventory__unauthorized {
         margin-right: 10px;
         @media (max-width: 768px) {
           margin-left: 0;
         }
       }
     }
     .upgrade__inventory:last-child {
       .upgrade__inventory-head {
         border-top-right-radius: 12px;
         border-bottom-right-radius: 12px;
       }
       .inventory__unauthorized {
         margin-left: 10px;
         @media (max-width: 768px) {
           margin-left: 0;
         }
       }
       .upgrade__shop__wrapper {
         margin-left: 10px;
         @media (max-width: 768px) {
           margin-left: 0;
         }
       }

     }
   }
  }
</style>
