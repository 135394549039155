<template>
  <div class="inventory__filters" :class="{'disabled': disabled}">
    <div class="inventory__filters-title">
      {{ title }}
    </div>
    <div class="inventory__filters-controls" v-if="filterConfig">
      <div class="inventory__filters-controls_search" v-if="searchable">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.3" d="M11.9111 13.9226C11.5856 13.5972 11.5856 13.0695 11.9111 12.7441C12.2365 12.4186 12.7641 12.4186 13.0896 12.7441L16.4229 16.0774C16.7484 16.4028 16.7484 16.9305 16.4229 17.2559C16.0975 17.5814 15.5698 17.5814 15.2444 17.2559L11.9111 13.9226Z" fill="#5D606C"/>
          <path d="M9.16634 13.3333C11.4675 13.3333 13.333 11.4678 13.333 9.16665C13.333 6.86546 11.4675 4.99998 9.16634 4.99998C6.86515 4.99998 4.99967 6.86546 4.99967 9.16665C4.99967 11.4678 6.86515 13.3333 9.16634 13.3333ZM9.16634 15C5.94468 15 3.33301 12.3883 3.33301 9.16665C3.33301 5.94499 5.94468 3.33331 9.16634 3.33331C12.388 3.33331 14.9997 5.94499 14.9997 9.16665C14.9997 12.3883 12.388 15 9.16634 15Z" fill="#5D606C"/>
        </svg>
        <input type="text" v-model="filterConfig.name" :placeholder="$t('upgrade.search')">
      </div>
      <div class="inventory__filters-controls_price">
        <span>{{ $t('upgrade.price') }}</span>
        <em>₽</em>
        <div class="inventory__filters-controls_sort" @click="toggleSort" :class="{'asc': filterConfig.priceSort === 'asc'}">
          <img src="/img/sort.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    filterConfig: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true
    },
    searchable: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggleSort () {
      if (this.filterConfig.priceSort === 'desc') {
        this.filterConfig.priceSort = 'asc'
      } else {
        this.filterConfig.priceSort = 'desc'
      }
    }
  },
}
</script>
<style scoped lang="scss">
.inventory__filters {
  width: 100%;
  padding: 15px;
  background: #131419;
  border-radius: 10px;
  position: relative;
  .inventory__filters-title {
    font-weight: 800;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
  }
  .inventory__filters-controls {
    margin-left: auto;
    display: flex;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 10px;
    .inventory__filters-controls_search {
      display: flex;
      padding-right: 5px;
      align-items: center;
      input {
        margin-left: 5px;
        outline: none;
        border: none;
        width: 40px;
        background: transparent;
        font-weight: 700;
        font-size: 10px;
        line-height: 12px;
        color: #5D606C;
      }
    }
    .inventory__filters-controls_sort {
      padding: 0 5px;
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: 0.2s;
      img {
        width: 20px;
        filter: invert(1);
      }
      &.asc {
        img {
          transform: rotate(180deg);
        }
      }

    }
    .inventory__filters-controls_price {
      background: #0D0E12;
      border-radius: 10px;
      display: flex;
      align-items: center;
      padding-left: 25px;
      padding-right: 5px;
      span {
        margin-right: 15px;
        font-weight: 700;
        font-size: 10px;
        line-height: 12px;
      }
      em {
        font-style: normal;
        font-weight: 700;
        font-size: 10px;
        margin-right: 15px;
        line-height: 12px;
      }
      input {
        width: 50px;
        outline: none;
        background: transparent;
        border: none;
        font-weight: 700;
        font-size: 10px;
        line-height: 12px;
        &::placeholder {
          color: rgba(255,255,255, 0.25)
        }
      }
    }
  }
  &.disabled {
    filter: blur(1px);
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      content: "";
      display: block;
      opacity: 0.6;
      background: rgba(19, 20, 25, 1);

      z-index: 999;
    }
  }
}
</style>
