<template>
  <div class="sounds"></div>
</template>
<script>
export default {
  data () {
    return {
      cashout: new Audio('/sounds/cashout.wav'),
      notify: new Audio('/sounds/notification.wav'),
    }
  },
  mounted () {
    this.$root.$on('SoundPlay', (sound) => {
      const isDisabled = localStorage.getItem('soundDisabled') ? parseInt(localStorage.getItem('soundDisabled')) : 0
      if (!isDisabled) {
        this[sound].volume = 0.2
        this[sound].play()
      }
    })
  }
}
</script>

