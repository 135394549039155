<template>
  <div class="open-case wrapper-bg" v-if="caseModel">
    <div class="title-wrapper">
      <div class="title">
        {{ caseModel.name[$root.currentLanguage] }}
      </div>
    </div>

    <transition name="fade">
      <Loader v-if="$apollo.loading"></Loader>
    </transition>

    <div class="case-block" v-if="step === 'start'">
      <BigCaseItem
          :color="caseModel.color"
          :image="`https://rustfast.org/cdn-cgi/imagedelivery/IWwC2L3JFPDEcVB1-rHJPQ/${caseModel.image}/500fx500f`"
      />

      <OpenCaseAuth v-if="!authStatus"></OpenCaseAuth>
      <div v-else-if="caseModel.isEvent"
           style="
             margin-top: 30px;
             display: flex;
             align-items: center;
             justify-content: center;
           "
      >
        <OpenEventButton :case-item="caseModel" @start="startOpen"></OpenEventButton>
      </div>
      <OpenNotBalance v-else-if="!availableBalance && !loading"></OpenNotBalance>
      <OpenCaseTrade v-else-if="!hasTradeLink"></OpenCaseTrade>
      <OpenCaseBlock
          v-else
          @fastStart="startFastOpen"
          :loading="loading"
          @updateCount="count = $event"
          :price="caseModel.price"
          @start="startOpen"
      ></OpenCaseBlock>
    </div>

    <template v-if="step === 'process'">
      <div class="process__open">
        <div class="process__open-slides">
            <OpenCaseSlider v-for="(winner) in winnerCases"
                            :winnerItem="winner"
                            :key="winner.id"
                            :products="generateForSlider(caseModel.items)"
                            @close="closeSlider()"
            />
          <div class="open__case-cursor"></div>
        </div>
        <div class="open__case-process">
          {{ $t('open.opening') }}
        </div>
      </div>
    </template>

    <ShowPrize @restart="reset" :winners="winnerCases" v-show="step === 'finish'" :caseItem="caseModel"></ShowPrize>

    <div class="separator-title">
      <span>{{ $t('open.products') }}</span>
      <div class="line"></div>
    </div>

    <CaseProducts :cases="caseModel.items" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { GET_CASE } from '../queries/index'
import { OPEN_CASE } from '../queries/mutations'
import { generateForSlider } from '../helpers/generateForSlider'
import CaseProducts from '../components/CaseProducts'
import Loader from '../components/Loader'
import BigCaseItem from '../components/BigCaseItem'
import ShowPrize from '../components/OpenCase/ShowPrize'
import OpenCaseBlock from '../components/OpenCase/OpenCaseBlock'
import OpenNotBalance from '../components/OpenCase/OpenNotBalance'
import OpenCaseTrade from '../components/OpenCase/OpenCaseTrade'
import OpenCaseAuth from '../components/OpenCase/OpenCaseAuth'
import OpenCaseSlider from '../components/OpenCase/OpenCaseSlider'
import OpenEventButton from "@/components/OpenCase/OpenEventButton.vue";
export default {
  props: {
    slug: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      count: 1,
      openSound: new Audio('/sounds/open.mp3'),
      openFinishSound: new Audio('/sounds/open-finish.mp3'),
      generateForSlider,
      step: 'start', // start
      routeParam: this.$route.params.slug,
      winnerCases: [],
      finishTimeoutId: null
    }
  },
  metaInfo() {
    const lang = this.$root.currentLanguage
    const caseModelSeo = this.caseModel

    return {
      title: caseModelSeo ? lang === 'ru' ? caseModelSeo.seo.title.ru : caseModelSeo.seo.title.en : '',
      meta: [
        { name: 'keywords', content: caseModelSeo && caseModelSeo.seo.keywords },
        { name: 'description', content: caseModelSeo ? lang === 'ru' ? caseModelSeo.seo.description.ru : caseModelSeo.seo.description.en : '' }
      ]
    }
  },
  beforeDestroy () {
    this.openSound.pause()
  },
  computed: {
    isBlocked() {
      return (caseItem) => {
        return (caseItem.needDeposited && caseItem.needDeposited > 0) ||
        caseItem.needPromocode ||
        this.checkTimeBlock(caseItem.nextOpen)
      }
    },
    checkTimeBlock() {
      return (nextOpen) => nextOpen ? Date.parse(nextOpen) > Date.now() : false
    },
    hasTradeLink () {
      if (!this.authStatus || !this.user.tradeLink) {
        return false
      }

      return true
    },
    availableBalance () {
      if (!this.authStatus || !this.user.balance) {
        return false;
      }

      return this.user.balance >= parseFloat(this.caseModel.price)
    },
    ...mapGetters({
      authStatus: 'auth/authStatus',
      user: 'auth/user'
    })
  },
  methods: {
    reset () {
      this.count = 1
      this.winnerCases = []
      this.step = 'start'
    },
    async startFastOpen () {
      this.loading = true
      await this.$apollo.mutate({
        mutation: OPEN_CASE,
        variables: {
          caseId: this.caseModel.id,
          count: this.count
        }
      }).then(async (response) => {
        this.$set(this, 'winnerCases', response.data.caseOpen)
        this.loading = false
        this.showFinish(0)
      }).catch(error => {
        error.graphQLErrors.map(item => {
          this.$toast.error(item.message);
        })
        this.loading = false
      })
    },
    async startOpen () {
      this.loading = true
      await this.$apollo.mutate({
          mutation: OPEN_CASE,
          variables: {
            caseId: this.caseModel.id,
            count: this.count
          }
        }).then(async (response) => {
          this.$set(this, 'winnerCases', response.data.caseOpen)
          this.loading = false
          this.step = 'process'
          await this.$nextTick()

          setTimeout(async () => {
            const isDisabled = localStorage.getItem('soundDisabled') ? parseInt(localStorage.getItem('soundDisabled')) : 0

            if (!isDisabled) {
              this.openSound.volume = 0.1
              await this.openSound.play()
            }
          }, 300)

          this.finishTimeoutId = setTimeout(() => {
            this.showFinish()
          }, 8600)
        }).catch(error => {
          error.graphQLErrors.map(item => {
            this.$toast.error(item.message);
          })
          this.loading = false
        })
    },

    async showFinish (delay = 700) {
      const isDisabled = localStorage.getItem('soundDisabled') ? parseInt(localStorage.getItem('soundDisabled')) : 0
      if (!isDisabled) {
        this.openFinishSound.volume = 0.1
        await this.openFinishSound.play()
      }
      setTimeout(() => {
        this.step = 'finish'
      }, delay)
    },

    closeSlider() {
      if (this.step !== 'finish') {
        if (this.finishTimeoutId) {
          clearTimeout(this.finishTimeoutId)
        }
        this.finishTimeoutId = null
        this.step = 'finish'
      }
    }
  },
  apollo: {
    caseModel: {
      fetchPolicy: 'network-only',
      query: GET_CASE,
      variables() {
        return {
          slug: this.routeParam
        }
      },
      update: function(data) {
        if (!data.case) {
          this.$toast.error(this.$t('public.no_items_in_case'))
          this.$router.back()
          return null
        }
        return data.case
      },
    }
  },
  components: {
    OpenEventButton,
    Loader,
    CaseProducts,
    ShowPrize,
    BigCaseItem,
    OpenCaseAuth,
    OpenCaseSlider,
    OpenCaseBlock,
    OpenNotBalance,
    OpenCaseTrade
  }
}
</script>
<style scoped lang="scss">
.open-case {
  position: relative;
  padding-bottom: 30px;
  .case-block {
    width: 302px;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .open__case-process {
    width: 222px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(254, 94, 59, 0.15);
    border-radius: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    margin: 0 auto;
    text-transform: uppercase;
    margin-top: 26px;
    color: #FE5E3B;
  }

}
.process__open {
  margin: 30px 0;

  .process__open-slides {
    position: relative;
    background: #131419;
    padding: 7.5px 0;
    .open__case-cursor {
      position: absolute;
      left: 50%;
      height: calc(100% - 20px);
      width: 2px;
      background: #FE5E3B;
      top: 10px;
      display: flex;
      justify-content: center;
      &::after {
        content: "";
        display: block;
        width: 16px;
        height: 18px;
        background: url(/img/arrow-down.svg);
        position: absolute;
        top: -20px;
      }
      &::before {
        content: "";
        display: block;
        width: 16px;
        background-color: #18191D;
        height: 18px;
        background-image: url(/img/arrow-down.svg);
        transform: rotate(180deg);
        position: absolute;
        bottom: -20px;
      }
    }
  }
}
</style>
