export default function setCookie(name, value, expires_second, path, domain, secure){
    var s = name+'='+encodeURIComponent(value)+';';
    if( expires_second ){
        var d=new Date();
        d.setTime( d.getTime()+ expires_second*1000  );
        s+=' expires='+d.toUTCString()+';';
    }
    if(path) s+=' path='+path+';';
    if(domain) s+=' domain='+domain+';';
    if(secure)s+=' secure;';
    document.cookie=s;
}
