import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/Main.vue'
import OpenCase from '../views/OpenCase.vue'
import Profile from '../views/Profile.vue'
import Store from '../views/Store.vue'
import PublicProfile from '../views/PublicProfile.vue'
import Upgrade from '../views/Upgrade.vue'
import Informations from '../views/Informations.vue'
import InformationsRules from '../views/InformationsRules'
import InformationsOffert from '../views/InformationsOffert'
import InformationsTerms from '../views/InformationsTerms'
import InformationsPricing from '../views/InformationsPricing'
import InformationsDescriptions from '../views/InformationsDescriptions'
import InformationsPayments from '../views/InformationsPayments'
import InformationsSecurity from '../views/InformationsSecurity'
import InformationsRegisters from '../views/InformationsRegisters'
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Main
    },
    {
        path: '/case/:slug',
        name: "OpenCase",
        props: true,
        component: OpenCase
    },
    {
        path: '/store',
        name: "Store",
        component: Store
    },
    {
        path: '/profile',
        name: "Profile",
        component: Profile
    },
    {
        path: '/upgrade',
        name: "Upgrade",
        component: Upgrade
    },
    {
        path: '/user/:id',
        name: "PublicProfile",
        component: PublicProfile
    },
    {
        path: '/r/:ref',
        name: "Referral",
        component: Main
    },
    {
        path: '/informations',
        name: 'informations',
        component: Informations
    },
    {
        path: '/informations/rules',
        name: 'informations',
        component: InformationsRules
    },
    {
        path: '/informations/offert',
        name: 'informations',
        component: InformationsOffert
    },

    {
        path: '/informations/terms-and-conditions',
        name: 'informations',
        component: InformationsTerms
    },

    {
        path: '/informations/pricing',
        name: 'informations',
        component: InformationsPricing
    },

    {
        path: '/informations/descriptions',
        name: 'informations',
        component: InformationsDescriptions
    },

    {
        path: '/informations/payments',
        name: 'informations',
        component: InformationsPayments
    },
    {
        path: '/informations/privacy',
        name: 'informations',
        component: InformationsSecurity
    },
    {
        path: '/informations/registers',
        name: 'informations',
        component: InformationsRegisters
    },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    window.scrollTo(0,0 )
    return next()
})
export default router
