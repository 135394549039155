<template>
  <div class="unavailable-block">
    <p v-html="$t('open.enought_money')"></p>
    <button @click="toggleDeposit(true)">{{ $t('open.deposit') }}</button>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  methods: {
    ...mapActions({
      toggleDeposit: 'main/toggleDeposit'
    })
  }
}
</script>
<style scoped lang="scss">
</style>
