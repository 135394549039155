import Vue from 'vue'
import Vuex from 'vuex'
import main from './modules/main'
import profile from './modules/profile'
import upgrade from './modules/upgrade'
import shop from './modules/shop'
import auth from './modules/auth'
Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        main,
        profile,
        shop,
        upgrade,
        auth
    },
})
