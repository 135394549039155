<template>
  <div class="product__item" :style="{
    background: `linear-gradient(180deg, ${hexToRgbA('#' + product.color, 0.05)} 0%, ${hexToRgbA('#' + product.color, 0.25)} 100%), url('/img/bg_gradient.png') no-repeat center / 100% 100%`,
    '--after': `${hexToRgbA('#' + product.color, 1)}`
  }">
    <div
        class="product__item-img"
        :class="{ 'product__item-img--small': isUpgrade }"
        :style="{ background: `url('/img/item-logo.svg') no-repeat bottom center / 85% 85%` }"
    >
      <img :src="`${product.getImage}/200fx200f`" alt="">
    </div>
    <div class="product__item-desc">
      <strong>{{ product.name }}</strong>
      <span>₽ {{ parseFloat(product.price).toFixed(2) }}</span>
    </div>
  </div>
</template>
<script>
import hexToRgbA from "@/helpers/hexToRgba";

export default {
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  methods: {hexToRgbA},
  computed: {
    isUpgrade() {
      return this.$route.name === 'Upgrade'
    }
  }
}
</script>
<style scoped lang="scss">
.product__item {
  width: 195px;
  height: 160px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;

  .product__item-img {
    display: flex;
    justify-content: center;
    padding-top: 15px;
    img {
      min-height: 100px;
      max-height: 100px;
      max-width: 100%;
      margin: 0 auto;
      display: block;
    }
    &--small {
      img {
        max-height: 70px;
        min-height: auto;
      }
    }
  }
  .product__item-desc {
    position: absolute;
    bottom: 10px;
    left: 15px;
    strong {
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      display: block;
      margin-bottom: 5px;
      width: 100%;
    }
    span {
      display: block;
      width: 100%;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      white-space: nowrap;
    }

  }
  .product__item-percent {
    right: 10px;
    bottom: 10px;
    background: rgba(255,255,255, 0.05);
    width: 38px;
    position: absolute;
    border-radius: 4px;
    text-align: center;
    font-weight: 700;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    line-height: 16px;
  }
  &::after {
    content: "";
    display: block;
    width: 65px;
    position: absolute;
    left: calc(50% - 32.5px);
    height: 2px;
    bottom: 0;
    background: var(--after);
  }
}
</style>
