const state = () => ({
});

const getters = {
    items: state => state.items
};

const actions = {

};

const mutations = {

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
