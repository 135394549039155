export function generateForSlider (items) {
    items = JSON.parse(JSON.stringify(items))
    // const minLength = 10
    // const count = (items.length / 2 > minLength ? items.length / 2 : minLength)
    // const count = (items.length < minLength ? minLength : items.length / 2)
    const count = 40
    let result = []
    let i = 0
    while (i <= count) {
        result = [...result, ...items]
        result = result.slice(0, count)
        if (result.length === count) break
        i++
    }

    return result.sort(() => 0.5 - Math.random())
}
