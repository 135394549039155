<template>
  <div class="profile wrapper-bg">
    <div class="title-wrapper">
      <div class="title">
        {{ $t('user_profile') }}
      </div>
    </div>

    <Loader v-if="$apollo.loading"></Loader>
    <template v-else>
      <ProfileInformation :pub="true" v-if="profile" :profile="profile"></ProfileInformation>
      <div class="separator-title">
        <span>{{$t('inventory')}}</span>
        <div class="line"></div>
      </div>
      <PublicProfileInventory></PublicProfileInventory>
    </template>
  </div>
</template>
<script>
import { GET_PROFILE } from '../queries/index'
import ProfileInformation from '../components/Profile/ProfileInformation'
import PublicProfileInventory from '../components/Profile/PublicProfileInventory'
import Loader from '../components/Loader'

export default {
  apollo: {
    profile: {
      fetchPolicy: 'network-only',
      query: GET_PROFILE,
      variables () {
        return {
          steamId: this.$route.params.id
        }
      },
      update: data => {
        return Object.assign({}, data.profileStatistics, data.profile)
      }
    }
  },
  components: {
    Loader,
    ProfileInformation,
    PublicProfileInventory
  }
}
</script>
<style scoped lang="scss">
.profile {
  position: relative;
  min-height: 50vh;
  padding: 0 80px;
  overflow: hidden;
  margin-bottom: 20px;
  padding-bottom: 50px;
  @media (max-width: 1600px) {
    padding: 0 20px;
  }
  @media (max-width: 768px) {
    padding: 0 10px;
  }
}
</style>
