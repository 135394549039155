import { render, staticRenderFns } from "./InformationsRegisters.vue?vue&type=template&id=a684e49e&scoped=true&"
var script = {}


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a684e49e",
  null
  
)

export default component.exports