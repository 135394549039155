<template>
  <div id="app" :class="{ 'chat-hide': !forceOpenChat }">
    <left-col></left-col>
    <div class="app-content">
      <header-top></header-top>
      <live-drop></live-drop>
      <router-view v-if="!isLoading" />
      <PageFooter />
    </div>
    <Chat :key="keyChat"></Chat>
    <SubscribeChecker v-if="authStatus" />
    <Sounds />

    <transition name="fade">
      <DepositModal v-if="isShowDeposit"></DepositModal>
    </transition>
  </div>
</template>
<script>
import LeftCol from './components/LeftCol'
import setCookie from './helpers/setCookie'
import SubscribeChecker from './components/SubscribeChecker'
import DepositModal from './components/Modals/DepositModal'
import Sounds from './components/Sounds'
import HeaderTop from './components/HeaderTop'
import LiveDrop from './components/LiveDrop'
import Chat from './components/Chat'
import { mapGetters, mapActions } from 'vuex'
import chatMediaVisibility from "@/helpers/chatMedia";
import PageFooter from "@/components/PageFooter.vue";

export default {
  data () {
    return {
      keyChat: 0,
      isLoading: true,
      forceOpenChat: JSON.parse(localStorage.getItem('chat') || true)
    }
  },
  metaInfo() {
    const lang = this.$root.currentLanguage

    return {
      title: lang === 'ru' ? this.config.title.ru : this.config.title.en,

      meta: [
        { name: 'keywords', content: this.config.keywords },
        { name: 'description', content: lang === 'ru' ? this.config.description.ru : this.config.description.en }
      ]
    }
  },
  watch: {
    '$root.currentLanguage'() {
      this.keyChat++
    },
  },
  computed: {
    ...mapGetters({
      isShowDeposit: 'main/isShowDeposit',
      authStatus: 'auth/authStatus',
      user: 'auth/user',
      isAuthenticated: 'auth/isAuthenticated',
      config: 'main/config'
    })
  },
  methods: {
    ...mapActions ({
      getConfig: 'main/getConfig',
      getUser: 'auth/getUser'
    }),
    setReferral () {
      if (this.$route.params.ref) {
        setCookie('ref', this.$route.params.ref, 3600000)
      }
    }
  },
  components: {
    Sounds,
    SubscribeChecker,
    'left-col': LeftCol,
    'header-top': HeaderTop,
    DepositModal,
    'live-drop': LiveDrop,
    'Chat': Chat,
    PageFooter
  },
  created() {
    chatMediaVisibility(this)
    this.$root.$on('openChat', (close = false) => {
      this.forceOpenChat = !close;
    })
  },
  async mounted () {
    await this.getConfig()

    if (this.isAuthenticated) {
      await this.getUser()
    }

    this.isLoading = false
    this.setReferral()
  },

}
</script>
<style lang="scss">
@import './styles/main.scss';
#app {
  display: flex;
  padding-left: 100px;
  padding-right: 400px;
  min-height: 100vh;
  @media (max-width: 1600px) {
    padding-right: 350px;
  }
  @media (max-width: 1200px) {
    padding-right: 0;
    padding-left: 0;
  }
  .app-content {
    flex-grow: 1;
    overflow: hidden;
    padding-right: 20px;
    display: flex;
    flex-flow: column;
    @media (max-width: 1200px) {
      padding-left: 20px;
    }
    @media (max-width: 768px) {
      padding: 0 10px;
    }
  }
}
#app.chat-hide {
  @media (min-width: 1200px) {
    padding-right: 85px;
  }
}
</style>
