<template>
  <div class="store wrapper-bg">
    <div class="title-wrapper">
      <div class="title">
        {{ $t('store.head') }}
      </div>
    </div>
    <div class="store__filters">
      <div class="price-search-input">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.3" d="M11.9101 13.9226C11.5847 13.5972 11.5847 13.0695 11.9101 12.7441C12.2355 12.4186 12.7632 12.4186 13.0886 12.7441L16.4219 16.0774C16.7474 16.4028 16.7474 16.9305 16.4219 17.2559C16.0965 17.5814 15.5689 17.5814 15.2434 17.2559L11.9101 13.9226Z" fill="#5D606C"/>
          <path d="M9.16732 13.3334C11.4685 13.3334 13.334 11.4679 13.334 9.16671C13.334 6.86552 11.4685 5.00004 9.16732 5.00004C6.86613 5.00004 5.00065 6.86552 5.00065 9.16671C5.00065 11.4679 6.86613 13.3334 9.16732 13.3334ZM9.16732 15C5.94566 15 3.33398 12.3884 3.33398 9.16671C3.33398 5.94505 5.94566 3.33337 9.16732 3.33337C12.389 3.33337 15.0007 5.94505 15.0007 9.16671C15.0007 12.3884 12.389 15 9.16732 15Z" fill="#5D606C"/>
        </svg>
        <input v-model="search" :placeholder="$t('upgrade.search')" type="text">
      </div>
      <div class="store__filters-price" :class="{'active': order === 'desc'}" @click="changeOrder">
        <img src="/img/sort.png" alt="">
      </div>
    </div>

    <div v-if="$apollo.loading || loading" class="store__loader">
      <Loader></Loader>
    </div>
    <template v-if="!$apollo.loading && !loading">
      <div class="store__items">
        <StoreItem :item="item" v-for="(item, i) in items" :key="i" />
      </div>
      <Pagination v-if="sortItems && sortItems.length > 42" @changePage="page = $event" :activePage="page" :total="sortItems.length" />
    </template>

  </div>
</template>
<script>
import StoreItem from '../components/Store/StoreItem'
import Pagination from '../components/Pagination'
import Loader from '../components/Loader'
import { MARKET_PRICE_LIST } from '../queries/index'
const TAKE = 42;
export default {
  data () {
    return {
      search: '',
      order: 'desc',
      loading: false,
      page: 0,
    }
  },
  methods: {
    changeOrder () {
      this.page = 0
      this.loading = true
      if (this.order === 'asc') {
        this.order = 'desc'
      } else {
        this.order = 'asc'
      }

      setTimeout(() => {
        this.loading = false
      }, 400)
    }
  },
  computed: {
    items () {
      if (!this.sortItems) return []

      return this.sortItems.slice(TAKE * this.page, (TAKE * this.page) + TAKE)
    },
    sortItems () {
      let items = this.searchItems
      if (this.order === 'asc') {
        items = items.sort((a,b) => {
          return a.price - b.price;
        })
      } else {
        items = items.sort((a,b) => {
          return b.price - a.price;
        })
      }

      return items
    },

    searchItems () {
      if (!this.shopItems) return [];

      return this.shopItems.filter(obj => String(obj.name).toLocaleLowerCase().includes(String(this.search).toLocaleLowerCase()));
    }
  },
  watch: {
    search () {
      this.page = 0
    }
  },
  apollo: {
    shopItems: {
      fetchPolicy: 'network-only',
      query: MARKET_PRICE_LIST,
      update: data => data.marketPrices
    }
  },
  components: {
    StoreItem,
    Loader,
    Pagination
  }
}
</script>
<style scoped lang="scss">
.store__loader {
  position: relative;
  min-height: 200px;
}
  .store {
    position: relative;
    margin-bottom: 20px;
    padding: 0 20px;
    padding-bottom: 30px;
    @media (max-width: 768px) {
      padding: 0 10px;
    }
    .store__filters {
      margin-top: -30px;
      display: flex;
      @media (max-width: 992px) {
        margin-top: 10px;
      }
      .price-search-input {
        margin-right: 10px;
        width: 250px;
        background: #131419;
        border-radius: 6px;
        display: flex;
        padding-left: 18px;
        align-items: center;
        @media (max-width: 992px) {
          width: 100%;
        }
        input {
          margin-left: 10px;
          flex-grow: 1;
          overflow: hidden;
          outline: none;
          border: none;
          padding: 16px 0;
          background: transparent;
          font-weight: 700;
          font-size: 10px;
          line-height: 12px;
          color: #5D606C;
        }
      }
      .store__filters-price {
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background: #131419;
        border-radius: 6px;
        img {
          filter: invert(1);
          width: 20px;
          transform: rotate(180deg);
        }
        &.active {
          img {
            transform: none;
          }
        }
        svg {
          path {
            transition: 0.2s;
          }
        }
        &:hover {
          svg {
            path {
              fill: #fff;
            }
          }
        }
      }
    }
    .store__items {
      margin-top: 15px;
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      grid-gap: 20px;
      @media (max-width: 1750px) {
        grid-template-columns: repeat(6, 1fr);
      }
      @media (max-width: 1600px) {
        grid-template-columns: repeat(5, 1fr);
      }
      @media (max-width: 1400px) {
        grid-template-columns: repeat(4, 1fr);
      }
      @media (max-width: 1200px) {
        grid-template-columns: repeat(5, 1fr);
      }
      @media (max-width: 992px) {
        grid-template-columns: repeat(4, 1fr);
      }
      @media (max-width: 800px) {
        grid-template-columns: repeat(3, 1fr);
      }
      @media (max-width: 600px) {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
</style>
