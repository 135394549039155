<template>
  <div class="upgrade__shop__wrapper" :class="{'disabled': disabled}">
    <InventoryWrapper
        :page="page"
        :total="itemsList.total"
        v-if="itemsList"
        :items="itemsList.items"
        @next="showMore"
        :loading="$apollo.loading"
    >
      <ShopSelectItem v-slot v-for="(product, i) in itemsList.items" :key="i" :product="product"></ShopSelectItem>
    </InventoryWrapper>
  </div>
</template>
<script>
import InventoryWrapper from './InventoryWrapper'
import { mapGetters, mapActions } from 'vuex'
import ShopSelectItem from './ShopSelectItem'
import { GET_SITE_ITEMS } from '../../queries/index'
export default {
  data () {
    return {
      page: 0
    }
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      betType: 'upgrade/betType',
      useSelectBalance: 'upgrade/useSelectBalance',
      filterConfig: 'upgrade/filterConfig',
      shop: 'shop/items'
    })
  },
  watch: {
    'filterConfig.name': function () {
      this.deleteUpgrade()
      this.page = 0
    },
    betType () {
      this.deleteUpgrade()
    },
    'filterConfig.priceFrom': function () {
      this.page = 0
    },
    'filterConfig.priceTo': function () {
      this.page = 0
    },
    'filterConfig.priceSort': function () {
      this.deleteUpgrade()
      this.page = 0
    },
    page () {
      this.deleteUpgrade()
    },
  },
  methods: {
    ...mapActions({
      deleteUpgrade: 'upgrade/deleteUpgrade'
    }),
    async showMore (page) {
      this.page = page
      // Fetch more data and transform the original result
      await this.$apollo.queries.itemsList.fetchMore({
        // New variables
        variables: {
            take: 15,
            skip: 15 * this.page,
            name: this.filterConfig.name,
            priceSort: this.filterConfig.priceSort,
            priceFrom: parseFloat(this.filterConfig.priceFrom),
            priceTo: parseFloat(this.filterConfig.priceTo),
        },
        // Transform the previous result with new data
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const itemsList = fetchMoreResult.itemsList.items
          return {
            itemsList: {
              __typename: previousResult.itemsList.__typename,
              // Merging the tag list
              items: itemsList,
              total: fetchMoreResult.total,
            },
          }
        },
      })
    },
  },
  apollo: {
    itemsList: {
      fetchPolicy: 'network-only',
      query: GET_SITE_ITEMS,
      variables () {
        return {
          take: 15,
          name: this.filterConfig.name,
          priceSort: this.filterConfig.priceSort,
          priceFrom: parseFloat(this.filterConfig.priceFrom),
          priceTo: parseFloat(this.filterConfig.priceTo),
        }
      },
      result(data) {
        this.deleteUpgrade()
        return data.itemsList
      }
    }
  },
  components: {
    ShopSelectItem,
    InventoryWrapper
  }
}
</script>
<style scoped lang="scss">
.upgrade__shop__wrapper {
  position: relative;
  &.disabled {
    filter: blur(1px);
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      content: "";
      display: block;
      opacity: 0.6;
      background: rgba(19, 20, 25, 1);

      z-index: 999;
    }
  }
}
</style>
