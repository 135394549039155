<template>
  <div class="app-left">
    <router-link to="/profile" v-if="$root.isAuth" class="profile-circle">
      <img :src="user.avatar" alt="">
      <div class="profile-circle_arrow">
        <svg width="8" height="4" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.3841 1.14007C5.17197 0.953309 4.82803 0.953309 4.61589 1.14007L1.1591 4.18359C0.946968 4.37036 0.946968 4.67315 1.1591 4.85992C1.37122 5.04669 1.71515 5.04669 1.92728 4.85992L5 2.15458L8.07273 4.85992C8.28486 5.04669 8.62877 5.04669 8.8409 4.85992C9.05303 4.67315 9.05303 4.37036 8.8409 4.18359L5.3841 1.14007ZM5.54318 1.79707V1.47824H4.45681V1.79707H5.54318Z" fill="black" stroke="black" stroke-width="0.4"/>
        </svg>
      </div>
    </router-link>

    <div @click="logout" class="logout" v-if="$root.isAuth">
      <img src="/img/logout.svg" alt="">
    </div>

    <nav>
      <div class="nav__body">
        <router-link to="/" :class="{'active': $route.name === 'Home'}">
          <span class="icon">
            <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 0H20V4H0V0ZM17 5H1V16C1 17.1 1.9 18 3 18H17C17.5304 18 18.0391 17.7893 18.4142 17.4142C18.7893 17.0391 19 16.5304 19 16V5H17ZM14 11H6V9H14V11Z" fill="#595A5F"/>
            </svg>
          </span>
          <em>{{ $t('caseTitle') }}</em>
        </router-link>
        <div class="separator"></div>
        <router-link to="/upgrade" :class="{'active': $route.name === 'Upgrade'}">
          <span class="icon">
            <svg width="15" height="20" viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.1707 6.00001H9.00065L9.80065 1.17001C9.82522 1.02769 9.81867 0.881746 9.78146 0.742202C9.74424 0.602658 9.67724 0.472831 9.58506 0.361651C9.49289 0.25047 9.37773 0.160574 9.24749 0.0981456C9.11726 0.0357172 8.97506 0.00223786 8.83065 1.03795e-05H3.67065C3.43413 -0.00106678 3.20488 0.0817273 3.02363 0.233686C2.84238 0.385644 2.72086 0.596929 2.68065 0.83001L1.01065 10.83C0.985814 10.974 0.992823 11.1216 1.03119 11.2626C1.06955 11.4036 1.13834 11.5344 1.23271 11.646C1.32708 11.7575 1.44474 11.847 1.57741 11.9082C1.71009 11.9693 1.85456 12.0007 2.00065 12H6.00065V20L14.0107 7.54001C14.1075 7.38904 14.162 7.2148 14.1684 7.03555C14.1747 6.85629 14.1328 6.67862 14.0469 6.52116C13.961 6.36369 13.8343 6.23222 13.6802 6.14054C13.526 6.04885 13.35 6.00031 13.1707 6.00001Z" fill="#595A5F"/>
            </svg>
          </span>
          <em>{{ $t('upgradeTitle') }}</em>
        </router-link>
      </div>
    </nav>
    <div class="socials" v-if="config">
      <a :href="config.vkLink" target="_blank">
        <svg width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M22.8269 0.941C22.9874 0.3989 22.8269 0 22.0551 0H19.5009C18.8505 0 18.5512 0.342599 18.3908 0.724199C18.3908 0.724199 17.0899 3.8941 15.2512 5.9495C14.6572 6.5436 14.384 6.7345 14.0588 6.7345C13.8984 6.7345 13.6512 6.5436 13.6512 6.0016V0.941C13.6512 0.2905 13.469 0 12.9313 0H8.9158C8.5082 0 8.2653 0.3036 8.2653 0.5854C8.2653 1.2011 9.1847 1.3442 9.2801 3.0788V6.8429C9.2801 7.6668 9.1326 7.8185 8.8074 7.8185C7.9401 7.8185 5.8326 4.6356 4.5837 0.993C4.3323 0.2862 4.085 0 3.4302 0H0.875999C0.147499 0 0 0.342599 0 0.724199C0 1.4006 0.8673 4.7614 4.0372 9.2019C6.1491 12.233 9.1238 13.8766 11.8298 13.8766C13.4559 13.8766 13.6554 13.5123 13.6554 12.8836C13.6554 9.9867 13.508 9.7135 14.3233 9.7135C14.7005 9.7135 15.3509 9.9043 16.8688 11.3657C18.6032 13.1002 18.8895 13.8765 19.8608 13.8765H22.415C23.1435 13.8765 23.5121 13.5123 23.2996 12.7923C22.8139 11.279 19.5312 8.1654 19.3838 7.9573C19.0066 7.4716 19.1149 7.2547 19.3838 6.821C19.3882 6.8168 22.506 2.4284 22.8269 0.941Z" fill="#2D303C"/>
        </svg>
      </a>
      <a :href="config.tgLink" target="_blank">
        <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M23.2928 2.66004L19.7677 19.2843C19.5018 20.4576 18.8082 20.7495 17.8226 20.1969L12.4515 16.2389L9.85997 18.7316C9.57327 19.0184 9.33327 19.2583 8.78057 19.2583L9.16637 13.788L19.1211 4.79284C19.5539 4.40704 19.0273 4.19314 18.4484 4.57904L6.14197 12.3279L0.843869 10.6697C-0.308531 10.3099 -0.32933 9.51724 1.08377 8.96454L21.8066 0.981039C22.766 0.621239 23.6056 1.19474 23.2928 2.66004Z" fill="#2D303B"/>
        </svg>
      </a>
      <router-link to="/informations" v-tooltip.right="$t('info_for_users')">

        <svg width="24" height="21" viewBox="0 0 20 20" version="1.1">
          <g id="Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Outlined" transform="translate(-442.000000, -288.000000)">
              <g id="Action" transform="translate(100.000000, 100.000000)">
                <g id="Outlined-/-Action-/-info" transform="translate(340.000000, 186.000000)">
                  <g>
                    <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                    <path d="M11,7 L13,7 L13,9 L11,9 L11,7 Z M11,11 L13,11 L13,17 L11,17 L11,11 Z M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M12,20 C7.59,20 4,16.41 4,12 C4,7.59 7.59,4 12,4 C16.41,4 20,7.59 20,12 C20,16.41 16.41,20 12,20 Z" id="🔹-Icon-Color" fill="#2D303B"></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </router-link>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  methods: {
    ...mapActions({
      logout: 'auth/logOut'
    })
  },
  computed: {
    ...mapGetters({
      config: 'main/config',
      user: 'auth/user'
    })
  }
}
</script>
<style scoped lang="scss">
.app-left {
  height: 100vh;
  position: fixed;
  min-width: 100px;
  width: 100px;
  left: 0;
  top: 0;
  @media (max-width: 1200px) {
    display: none;
  }
  .logout {
    position: absolute;
    background: #FE5E3B;
    left: calc(50% - 20px);
    width: 40px;
    padding: 7px 0;
    border-radius: 5px;
    top: 100px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.2s;
    justify-content: center;
    z-index: 99;
    &:hover {
      opacity: 0.8;
    }
  }
  nav {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    height: 100vh;
    .nav__body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 84px;
      background: url(/img/nav-bg.svg);
      background-size: 101% 100%;
      padding-right: 5px;
      height: 356px;
      a {
        display: block;
        width: 100%;
        text-decoration: none;
        padding: 30px 0;
        position: relative;
        .icon {
          width: 48px;
          height: 48px;
          background: #18191D;
          border-radius: 12px;
          display: flex;
          align-items: center;
          transition: 0.2s;

          svg {
            path {
              transition: 0.2s;
            }
          }
          justify-content: center;
          margin: 0 auto;
          margin-bottom: 12px;
        }
        em {
          font-weight: 700;
          font-size: 14px;
          width: 100%;
          display: block;
          line-height: 17px;
          font-style: normal;
          text-align: center;
          color: #FFFFFF;
        }
        &::after {
          content: "";
          display: block;
          left: 0;
          position: absolute;
          height: 100%;
          transition: 0.2s;
          opacity: 0;
          width: 2px;
          background: #FE5E3B;
          border-radius: 0 12px 12px 0;
          top: 0;
        }
        &.active, &:hover {
          .icon {
            background: #FE5E3B;
            svg {
              path {
                fill: #fff;
              }
            }
          }
          &::after {
            opacity: 1;
          }
        }
      }
      .separator {
        width: 48px;
        margin: 0 auto;
        height: 1px;
        background: #27282F;
        border-radius: 12px;
      }
    }
  }
  .socials {
    z-index: 2;
    position: absolute;
    bottom: 30px;
    width: 100%;
    a {
      display: block;
      width: 100%;
      text-align: center;
      svg {
        path {
          transition: 0.2s;
        }
      }
      &:hover {
        svg {
          path {
            fill: #fff;
          }
        }
      }
    }
    a + a {
      margin-top: 25px;
    }
  }
}
</style>
