<template>
  <div class="profile wrapper-bg">
    <div class="title-wrapper">
      <div class="title">
        {{ $t('profile.title') }}
      </div>
    </div>
    <Loader v-if="$apollo.loading"></Loader>
    <ProfileInformation
        v-if="profile"
        :profile="profile"
    ></ProfileInformation>
    <ProfileTradeLink></ProfileTradeLink>
    <ProfileTabs></ProfileTabs>
    <TabsContent></TabsContent>
  </div>
</template>
<script>
import ProfileInformation from '../components/Profile/ProfileInformation'
import ProfileTradeLink from '../components/Profile/ProfileTradeLink'
import ProfileTabs from '../components/Profile/ProfileTabs'
import TabsContent from '../components/Profile/TabsContent'
import Loader from '../components/Loader'
import { GET_PROFILE } from '../queries/index'
import { mapGetters } from 'vuex'
export default {
  components: {
    Loader,
    ProfileInformation,
    ProfileTradeLink,
    ProfileTabs,
    TabsContent
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    })
  },
  created () {
    if (!this.$root.isAuth) {
      this.$router.push('/')
    }
  },
  apollo: {
    profile: {
      fetchPolicy: 'network-only',
      query: GET_PROFILE,
      variables () {
        return {
          steamId: this.user.steamId
        }
      },
      update: data => {
        return Object.assign({}, data.profileStatistics, data.profile)
      }
    }
  }
}
</script>
<style scoped lang="scss">
.profile {
  position: relative;
  min-height: 100vh;
  padding: 0 80px;
  padding-bottom: 20px;
  overflow: hidden;
  @media (max-width: 1600px) {
    padding: 0 20px;
  }
  @media (max-width: 768px) {
    padding: 0 10px;
  }
}
</style>
