<template>
  <div class="subscribe-checker"></div>
</template>
<script>
import { USER_CHECKER } from '../queries/subscriptions'
export default {
  apollo: {
    $subscribe: {
      UserStatusCheckerStream: {
        query: USER_CHECKER
      }
    }
  }
}
</script>

