<template>
  <div class="live-drop">
    <div class="live-drop__select" v-if="getOpens && getOpens.length">
      <div v-tooltip="$t('live.top')" class="live-drop__select-item revert" @click="setSelectTab('top')" :class="{'active': selectTab === 'top'}">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M11.2601 5.81415L2 16.0002H22L12.7399 5.81415C12.3684 5.40549 11.736 5.37538 11.3273 5.74688C11.3039 5.76823 11.2814 5.79067 11.2601 5.81415Z" fill="#444650"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0057 15.7118L20.2806 6.85303C20.6576 6.44944 21.2904 6.42787 21.694 6.80487C21.8964 6.99398 22.0114 7.25861 22.0114 7.53565V20.0001H16.0114H2V7.53565C2 7.25861 2.11493 6.99398 2.31739 6.80487C2.72098 6.42787 3.35378 6.44944 3.73078 6.85303L12.0057 15.7118Z" fill="#444650"/>
        </svg>
      </div>
      <div v-tooltip="$t('live.default')" class="live-drop__select-item"  @click="setSelectTab('default')" :class="{'active': selectTab === 'default'}">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18.5 5H5.5C4.67157 5 4 5.67157 4 6.5C4 7.32843 4.67157 8 5.5 8H18.5C19.3284 8 20 7.32843 20 6.5C20 5.67157 19.3284 5 18.5 5Z" fill="white"/>
          <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M5.5 15H18.5C19.3284 15 20 15.6716 20 16.5C20 17.3284 19.3284 18 18.5 18H5.5C4.67157 18 4 17.3284 4 16.5C4 15.6716 4.67157 15 5.5 15ZM5.5 10H18.5C19.3284 10 20 10.6716 20 11.5C20 12.3284 19.3284 13 18.5 13H5.5C4.67157 13 4 12.3284 4 11.5C4 10.6716 4.67157 10 5.5 10Z" fill="white"/>
        </svg>
      </div>
    </div>
    <div class="items">
      <div class="item" v-for="(drop, i) in getOpens" :key="i" :style="{
        backgroundImage: `linear-gradient(180deg, ${hexToRgbA('#' + drop.item.color, 0.05)} 0%, ${hexToRgbA('#' + drop.item.color, 0.25)} 100%), url('/img/item-logo.png'), url('/img/bg_gradient.png')`,
        backgroundPosition: `center, center 8px`,
        backgroundRepeat: `no-repeat`,
        backgroundSize: `100% 100%, 90px 55px, 100% 100%`,
        '--after': hexToRgbA('#' + drop.item.color, 1)
      }">
        <router-link :to="(user && user.steamId === drop.user.steamId) ? '/profile/' : `/user/${drop.user.steamId}`" class="item__user">
          <div class="item__user-avatar">
            <img :src="drop.user.avatar" alt="">
          </div>
          <span>
            {{ drop.user.name }}
          </span>
        </router-link>
        <img :src="`${drop.item.getImage}/360fx360f`" alt="">
        <strong>{{ drop.item.name }}</strong>
      </div>
    </div>
  </div>
</template>
<script>
import { GET_LIVE_DROP, GET_HIGHT_LIVE_DROP } from '../queries/index'
import { LIVE_DROP_STREAM } from '@/queries/subscriptions'
import { mapGetters } from 'vuex'
import hexToRgbA from "@/helpers/hexToRgba";
export default {
  data () {
    return {
      selectTab: localStorage.getItem('live_drop') ? localStorage.getItem('live_drop') : 'default'
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    }),
    getOpens () {
      if (this.selectTab === 'top') {
        return this.lastHightOpens
      }

      return this.lastOpens
    }
  },
  methods: {
    hexToRgbA,
    setSelectTab (tab) {
      localStorage.setItem('live_drop', tab)
      this.selectTab = tab
    }
  },
  apollo: {
    lastOpens: {
      query: GET_LIVE_DROP,
      update: data => data.lastOpens
    },
    lastHightOpens: {
      query: GET_HIGHT_LIVE_DROP,
      update: data => data.lastHighPriceOpens
    },
    $subscribe: {
      OpenStream: {
        query: LIVE_DROP_STREAM,
        result ({ data }) {
          setTimeout(() => {
            if (parseFloat(data.OpenStream.price) >= 500) {
              this.lastHightOpens.unshift(data.OpenStream)
            }

            this.lastOpens.unshift(data.OpenStream)
          }, 1000 * 10)
        },
      },
    }
  }
}
</script>
<style scoped lang="scss">
.live-drop {
  display: flex;
  .live-drop__select {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10px;
    .live-drop__select-item {
      background: #252529;
      border-radius: 12px;
      width: 45px;
      height: 45px;
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: 0.2s;
      justify-content: center;
      &:hover, &.active {
        background: #FE5E3B;
      }
      &.active.revert {
        svg {
          path {
            fill: #fff;
          }
        }
      }
    }
    .live-drop__select-item + .live-drop__select-item {
      margin-top: 10px;
    }
  }
  .items {
    display: flex;
    padding: 20px;
    max-width: 100%;
    overflow: hidden;
    position: relative;
    .item {
      min-width: 170px;
      width: 170px;
      height: 100px;
      display: flex;
      align-items: center;
      //background: red;
      justify-content: center;
      border-radius: 10px;
      position: relative;
      overflow: hidden;
      //background-image: url('/img/item-logo.png'), url('/img/bg_gradient.png')/* no-repeat center / 60% 60%*//*, url('/img/bg_gradient.png') no-repeat center / 100% 100%*/;
      //background-position: center, center;
      //background-repeat: no-repeat, no-repeat;
      //background-size: 60% 60%, 100% 100%;
      .item__user {
        transition: 0.2s;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(19, 20, 25, 0.3);
        backdrop-filter: blur(7.5px);
        flex-direction: column;
        justify-content: center;
        display: flex;
        align-items: center;
        z-index: 2;
        .item__user-avatar {
          width: 100%;
          display: flex;
          justify-content: center;
          img {
            width: 40px;
            height: 40px;
            margin: 0;
            border-radius: 100%;
          }
          margin-bottom: 8px;
        }
        span {
          font-weight: 700;
          font-size: 14px;
          line-height: 16px;
          width: 100%;
          text-align: center;
        }
      }
      &:hover {
        .item__user {
          opacity: 1;
        }
      }
      img {
        max-height: 65px;
        margin-bottom: 25px;
      }
      strong {
        position: absolute;
        bottom: 10px;
        left: 0;
        width: 100%;
        display: block;
        text-align: center;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 6px;
        white-space: nowrap;
      }
      &::after {
        position: absolute;
        bottom: 0;
        z-index: 3;
        width: 65px;
        left: calc(50% - 32.5px);
        height: 2px;
        content: "";
        display: block;
        background: var(--after);
      }
    }
    .item + .item {
      margin-left: 15px;
    }
    &::after {
      content: "";
      display: block;
      right: 0;
      position: absolute;
      top: 0;
      height: 100%;
      width: 120px;
      background: linear-gradient(90deg, rgba(29, 30, 35, 0) 0%, #1D1E23 100%);
    }
    &::before {
      content: "";
      display: block;
      left: 0;
      position: absolute;
      top: 0;
      height: 100%;
      width: 120px;
      background: linear-gradient(90deg, #1D1E23 0%, rgba(29, 30, 35, 0) 100%);
    }
  }
}
</style>
