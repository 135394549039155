<template>
  <div class="slide__instance-wrap">
    <div class="slide__instance" :style="{
      background: `linear-gradient(180deg, ${hexToRgbA('#' + product.color, 0.05)} 0%, ${hexToRgbA('#' + product.color, 0.25)} 100%), url('/img/item-logo.svg') no-repeat center / 60% 60%, url('/img/bg_gradient.png') no-repeat center / 100% 100%`,
      '--after': `${hexToRgbA('#' + product.color, 1)}`
    }">
      <div class="slide__instance-img">
        <img :src="`${product.getImage}/360fx360f`" alt="">
      </div>
      <div class="slide__instance-title">
        {{ product.name }}
      </div>
    </div>
  </div>
</template>
<script>
import hexToRgbA from "@/helpers/hexToRgba";

export default {
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  methods:{hexToRgbA}
}
</script>
<style scoped lang="scss">
.slide__instance-wrap {
  padding: 0 7.5px;
  .slide__instance {
    height: 170px;
    border-radius: 10px;
    position: relative;
    .slide__instance-img {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      img {
        max-width: 50%;
        margin-bottom: 30px;
      }
    }
    .slide__instance-title {
      position: absolute;
      bottom: 25px;
      width: 100%;
      text-align: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
    }
    &::after {
      position: absolute;
      width: 65px;
      left: calc(50% - 32.5px);
      content: "";
      bottom: 0;
      height: 2px;
      background: var(--after);
    }
  }
}
</style>
