<template>
  <div class="select__balance-ranger">
    <div class="select__balance-ranger_title">
      {{ $t('upgrade.use_balance') }}:
    </div>
    <div class="select__balance-ranger-body">
      <div class="select__balance-ranger-body_indicators">
        <span class="active">{{ parseFloat(useBalanceAmount).toFixed(2) }}₽</span>
        <span>{{ parseFloat(betweenBalanceBet.priceTo).toFixed() }}₽</span>
      </div>
      <range-slider
          class="slider"
          :min="minSum"
          :max="parseFloat(user.balance).toFixed(2)"
          :step="1"
          v-model="useBalanceAmount">
      </range-slider>
      <div class="cancel" @click="setBetType(BET_PRODUCT_TYPE)">{{ $t('upgrade.user_skins') }}</div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { BET_PRODUCT_TYPE } from '../../const/UPGRADE_VARIABLES'
import RangeSlider from 'vue-range-slider'
import { debounce } from 'vue-debounce'
import 'vue-range-slider/dist/vue-range-slider.css'
export default {
  data () {
    return {
      BET_PRODUCT_TYPE,
      useBalanceAmount: 5
    }
  },
  computed: {
    minSum () {
      if (!this.config.getUpgradeConfig.upgradeMinSum) {
        return 5
      }
      return this.config.getUpgradeConfig.upgradeMinSum;
    },
    ...mapGetters({
      config: 'main/config',
      betweenBalanceBet: 'upgrade/betweenBalanceBet',
      user: 'auth/user',
      useSelectBalance: 'upgrade/useSelectBalance'
    })
  },
  methods: {
    ...mapActions({
      setSelectBalance: 'upgrade/setSelectBalance',
      setBetType: 'upgrade/setBetType'
    }),
  },
  watch: {
    useBalanceAmount: debounce(function () {
      this.setSelectBalance(this.useBalanceAmount)
    }, '600ms')
  },
  mounted () {
    this.useBalanceAmount = this.minSum
  },
  components: {
    RangeSlider
  }
}
</script>
<style scoped lang="scss">
.select__balance-ranger {
  display: flex;
  align-items: center;
  position: relative;
  @media (max-width: 1600px) {
    flex-wrap: wrap;
    padding-top: 15px;
  }
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
  .select__balance-ranger_title {
    margin-right: 20px;
    white-space: nowrap;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    padding-bottom: 15px;
    @media (max-width: 1600px) {
      width: 100%;
    }
  }
  .select__balance-ranger-body {
    overflow: hidden;
    padding-left: 10px;
    padding-bottom: 5px;
    padding-right: 10px;
    @media (max-width: 1600px) {
      padding-bottom: 30px;
    }
    .select__balance-ranger-body_indicators {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        &.active {
          color: #FE5E3B;
        }
      }
    }
    .cancel {
      font-size: 12px;
      text-decoration: underline;
      color: #898EAF;
      cursor: pointer;
      position: absolute;
      left: 0;
      transition: 0.1s;
      bottom: 0;
      &:hover {
        color: #F74646;
      }
    }
    ::v-deep {
      .range-slider {
        padding: 0;
        height: auto;
        position: relative;
        z-index: 15;
        width: 265px;
      }
      .range-slider-rail {
        background-color: #27282F;
      }
      .range-slider-fill {
        background-color: #FE5E3B;
      }
      .range-slider-knob {
        width: 15px;
        height: 15px;
        border: 3.5px solid #FE5E3B
      }
    }
  }
}
</style>
