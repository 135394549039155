import { apolloClient } from '@/vue-apollo'
import { GET_CONFIG } from '../../../queries/index'
const state = () => ({
    config: {
        upgradeMinSum: 5,
        title: {
            ru: '',
            en: ''
        },
        keywords: {
            ru: '',
            en: ''
        },
        description: {
            ru: '',
            en: ''
        }
    },
    isShowDeposit: false
});

const getters = {
    config: state => state.config,
    isShowDeposit: state => state.isShowDeposit
};

const actions = {
    async toggleDeposit ({ commit }, value) {
      commit('TOGGLE_DEPOSIT_MODAL', value)
    },
    async getConfig ({ commit }) {
        const { data } = await apolloClient.query({ query: GET_CONFIG })
        commit('SET_CONFIG', data.config)
    }
};

const mutations = {
    TOGGLE_DEPOSIT_MODAL (state, value) {
        state.isShowDeposit = value
    },
    SET_CONFIG (state, config) {
        state.config = config
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
