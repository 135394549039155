<template>
  <div class="case-products">
    <ProductItem :product="product" v-for="product in cases" :key="product.id"></ProductItem>
  </div>
</template>
<script>
import ProductItem from './ProductItem'
export default {
  props: {
    cases: {
      type: Array,
      default: () => []
    }
  },
  methods: {

  },
  components: {
    ProductItem
  }
}
</script>
<style scoped lang="scss">
.case-products {
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  grid-gap: 15px;
  margin: 0 auto;
}
</style>
