<template>
  <div class="chat" :class="{'open': forceOpenChat}">
    <div class="chat__head">
      <div class="chat__head-icon">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M14.4862 18L12.7975 21.0566C12.5304 21.54 11.922 21.7153 11.4386 21.4483C11.2977 21.3704 11.1777 21.2597 11.0887 21.1255L9.01653 18H5C3.34315 18 2 16.6569 2 15V6C2 4.34315 3.34315 3 5 3H19C20.6569 3 22 4.34315 22 6V15C22 16.6569 20.6569 18 19 18H14.4862Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M6 7H15C15.5523 7 16 7.44772 16 8C16 8.55228 15.5523 9 15 9H6C5.44772 9 5 8.55228 5 8C5 7.44772 5.44772 7 6 7ZM6 11H11C11.5523 11 12 11.4477 12 12C12 12.5523 11.5523 13 11 13H6C5.44772 13 5 12.5523 5 12C5 11.4477 5.44772 11 6 11Z" fill="white"/>
        </svg>
      </div>
      <div class="chat__head-titles">
        <strong>{{ $t('chat.title') }}</strong>
        <span>
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.3" d="M9 8.25C7.34315 8.25 6 6.90685 6 5.25C6 3.59315 7.34315 2.25 9 2.25C10.6569 2.25 12 3.59315 12 5.25C12 6.90685 10.6569 8.25 9 8.25Z" fill="#66BF57"/>
            <path d="M2.25049 15.1494C2.54119 11.5699 5.44643 9.75 8.98751 9.75C12.5784 9.75 15.5287 11.4699 15.7484 15.15C15.7572 15.2966 15.7484 15.75 15.185 15.75C12.4058 15.75 8.27604 15.75 2.79563 15.75C2.60753 15.75 2.23465 15.3444 2.25049 15.1494Z" fill="#66BF57"/>
          </svg>
          {{ usersOnline }}
        </span>
      </div>
      <div class="chat__head-close" @click="closeChat">
        <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19.4458 7.07109L7.07138 19.4455C6.58323 19.9336 6.58323 20.7251 7.07138 21.2132C7.55954 21.7014 8.351 21.7014 8.83915 21.2132L21.2135 8.83886C21.7017 8.35071 21.7017 7.55925 21.2135 7.07109C20.7254 6.58294 19.9339 6.58294 19.4458 7.07109Z" fill="white"/>
          <path opacity="0.3" d="M21.2133 19.4455L8.83896 7.07114C8.35081 6.58298 7.55935 6.58298 7.07119 7.07114C6.58304 7.55929 6.58304 8.35075 7.07119 8.83891L19.4456 21.2133C19.9337 21.7014 20.7252 21.7014 21.2133 21.2133C21.7015 20.7251 21.7015 19.9337 21.2133 19.4455Z" fill="white"/>
        </svg>
      </div>
      <button class="chat__head-hide" @click="closeChat">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5 14.1667L9.16667 10L5 5.83337M10.8333 14.1667L15 10L10.8333 5.83337" stroke="#595A5F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

        <div>
          Свернуть
        </div>
      </button>
    </div>
    <div class="chat__body">
      <div class="chat__messages" ref="chat__messages">
        <div class="chat__message" v-for="(message, i) in messages" :key="i">
          <router-link :to="(user && user.steamId === message.user.steamId) ? '/profile/' : `/user/${message.user.steamId}`" class="chat__messages-user" >
            <img :src="message.user.avatar" alt="" width="46" height="46">
          </router-link>
          <div class="chat__messages-message">
            <div class="chat__messages-message_top">
              <router-link :to="(user && user.steamId === message.user.steamId) ? '/profile/' : `/user/${message.user.steamId}`" class="nickname">{{ message.user.name }}</router-link>
              <div class="date">{{ getTime(message.createdAt) }}</div>
              <div class="chat__messages-message-settings" v-if="user.isAdmin || user.isModerator" @click.prevent.stop="openSettingsMessage = openSettingsMessage === message.id ? null : message.id">
                <div class="settings__body" :class="{'bottom': [0,1].includes(i)}" v-if="openSettingsMessage === message.id">
                  <div class="settings__body-item" @click="muteUser(message.user)">
                    <div class="icon">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M17.5854 12L16.1712 10.5858C15.7807 10.1953 15.7807 9.5621 16.1712 9.17157C16.5617 8.78105 17.1949 8.78105 17.5854 9.17157L18.9996 10.5858L20.4138 9.17157C20.8044 8.78105 21.4375 8.78105 21.8281 9.17157C22.2186 9.5621 22.2186 10.1953 21.8281 10.5858L20.4138 12L21.8281 13.4142C22.2186 13.8047 22.2186 14.4379 21.8281 14.8284C21.4375 15.219 20.8044 15.219 20.4138 14.8284L18.9996 13.4142L17.5854 14.8284C17.1949 15.219 16.5617 15.219 16.1712 14.8284C15.7807 14.4379 15.7807 13.8047 16.1712 13.4142L17.5854 12Z" fill="#84858E"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M7 16L3.60777 15.3215C2.67292 15.1346 2 14.3137 2 13.3604V10.6396C2 9.68622 2.67292 8.86539 3.60777 8.67842L7 7.99997L10.2929 4.70708C10.6834 4.31655 11.3166 4.31655 11.7071 4.70708C11.8946 4.89461 12 5.14897 12 5.41418V18.5858C12 19.138 11.5523 19.5858 11 19.5858C10.7348 19.5858 10.4804 19.4804 10.2929 19.2929L7 16Z" fill="#84858E"/>
                      </svg>
                    </div>
                    <span>
                      <BlockLoader v-if="actionLoading === message.user.steamId"></BlockLoader>
                      <template v-else>
                        <template v-if="message.user.chatMuted">{{ $t('chat.unmuted') }}</template>
                        <template v-else>
                          {{ $t('chat.muted') }}
                        </template>
                      </template>
                      </span>
                  </div>
                  <div class="settings__body-item" @click="banUser(message.user)">
                      <div class="icon" v-if="message.user.isBanned">
                        <svg width="19" height="19" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M13.5 16.5854C13.5 17.4138 12.8284 18.0854 12 18.0854C11.1716 18.0854 10.5 17.4138 10.5 16.5854C10.5 15.7569 11.1716 15.0854 12 15.0854C12.8284 15.0854 13.5 15.7569 13.5 16.5854Z" fill="#84858E"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M6.33367 10C6.20971 9.64407 6.09518 9.27081 5.99836 8.88671C5.69532 7.68444 5.54485 6.29432 5.89748 4.97439C6.26228 3.60888 7.14664 2.39739 8.74323 1.59523C10.3398 0.793061 11.8397 0.806642 13.153 1.32902C14.4225 1.83396 15.448 2.78443 16.2317 3.7452C16.4302 3.98851 16.6166 4.23669 16.7907 4.48449C17.0806 4.89706 16.9784 5.45918 16.5823 5.7713C16.112 6.14195 15.4266 6.01135 15.0768 5.52533C14.9514 5.35112 14.8197 5.17831 14.6819 5.0094C14.0088 4.18414 13.2423 3.51693 12.4138 3.18741C11.6292 2.87533 10.7252 2.83767 9.64112 3.38234C8.55703 3.92702 8.04765 4.6748 7.82971 5.49059C7.5996 6.35195 7.6774 7.36518 7.93771 8.39788C8.07953 8.96054 8.26936 9.50489 8.47135 10H18C19.6569 10 21 11.3431 21 13V20C21 21.6569 19.6569 23 18 23H6C4.34315 23 3 21.6569 3 20V13C3 11.3431 4.34315 10 6 10H6.33367ZM19 13C19 12.4477 18.5523 12 18 12H6C5.44772 12 5 12.4477 5 13V20C5 20.5523 5.44772 21 6 21H18C18.5523 21 19 20.5523 19 20V13Z" fill="#84858E"/>
                        </svg>
                      </div>
                      <div class="icon" v-else>
                        <svg width="19" height="19" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M3 7V5C3 2.23858 5.23858 0 8 0C10.7614 0 13 2.23858 13 5V7H14C15.1046 7 16 7.89543 16 9V15C16 16.1046 15.1046 17 14 17H2C0.89543 17 0 16.1046 0 15V9C0 7.89543 0.89543 7 2 7H3ZM8 2C6.34315 2 5 3.34315 5 5V7H11V5C11 3.34315 9.65685 2 8 2Z" fill="#84858E"/>
                        </svg>
                      </div>
                    <span>
                      <BlockLoader v-if="actionLoading === `${message.user.steamId}_banned`"></BlockLoader>
                      <template v-else>
                        <template v-if="message.user.isBanned">{{ $t('chat.unban') }}</template>
                        <template v-else>
                          {{ $t('chat.ban') }}
                        </template>
                      </template>
                    </span>
                  </div>
                  <div class="settings__body-item" @click="deleteMessage(message)">
                    <div class="icon">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 8H18L17.1065 19.615C17.0464 20.3965 16.3948 21 15.611 21H8.38905C7.60524 21 6.95358 20.3965 6.89347 19.615L6 8ZM8 10L8.45438 14.0894L15.5518 14.0339L16 10H8Z" fill="#84858E"/>
                        <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M14 4.5V3.5C14 3.22386 13.7761 3 13.5 3H10.5C10.2239 3 10 3.22386 10 3.5V4.5H5.5C5.22386 4.5 5 4.72386 5 5V5.5C5 5.77614 5.22386 6 5.5 6H18.5C18.7761 6 19 5.77614 19 5.5V5C19 4.72386 18.7761 4.5 18.5 4.5H14Z" fill="#84858E"/>
                      </svg>
                    </div>
                    <span>
                      <BlockLoader v-if="actionLoading === `${message.id}_delete`"></BlockLoader>
                      <template v-else>
                        {{ $t('chat.delete_message') }}
                      </template>
                    </span>
                  </div>

                </div>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7 12C7 10.8954 6.10457 10 5 10C3.89543 10 3 10.8954 3 12C3 13.1046 3.89543 14 5 14C6.10457 14 7 13.1046 7 12Z" fill="#31333B"/>
                  <path d="M14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12Z" fill="#31333B"/>
                  <path d="M21 12C21 10.8954 20.1046 10 19 10C17.8954 10 17 10.8954 17 12C17 13.1046 17.8954 14 19 14C20.1046 14 21 13.1046 21 12Z" fill="#31333B"/>
                </svg>
              </div>
            </div>
            <div class="chat__messages-message_text">
              {{ message.text }}
            </div>

          </div>
        </div>

      </div>

      <template v-if="!$apollo.loading">
        <ChatUnauthorized v-if="!$root.isAuth" />
        <template v-else-if="authStatus">
          <ChatMuted v-if="user.isBanned || user.chatMuted"></ChatMuted>
          <div class="chat__input" v-else>
            <input @keydown.enter="sendMessage" type="text" :placeholder="$t('chat.your_message')" v-model="message">
            <button @click="sendMessage" :disabled="!message">
              <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 7.072L-6.05683e-07 14.0002L4 7.072L0 0.143798L12 7.072Z" fill="white"/>
              </svg>
            </button>
          </div>
        </template>
      </template>

    </div>
    <div class="chat__hide">
      <button class="chat__hide-button" @click="$root.$emit('openChat')">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5 14.1667L9.16667 10L5 5.83337M10.8333 14.1667L15 10L10.8333 5.83337" stroke="#595A5F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

        <div>
          Развернуть
        </div>
      </button>
    </div>
  </div>
</template>
<script>
import { GET_CHAT_MESSAGES, GET_USER_ONLINE } from '../queries'
import { NEW_CHAT_MESSAGE, USERS_ONLINE } from '../queries/subscriptions'
import { SEND_CHAT_MESSAGE, MUTE_USER, BANNED_USER, DELETE_MESSAGE } from '../queries/mutations'
import { mapGetters } from 'vuex'
import ChatUnauthorized from './ChatUnauthorized'
import ChatMuted from './ChatMuted'
import BlockLoader from './BlockLoader'
export default {
  data () {
    return {
      forceOpenChat: JSON.parse(localStorage.getItem('chat') || false),
      actionLoading: false,
      openSettingsMessage: null,
      chat_messages: [],
      message: '',
      maxMessageLength: 128
    }
  },
  watch: {
    'chat_messages.length' () {
      setTimeout(() => {
        this.toBottom()
      }, 0)
    },
    message(/*val*/) {
      // if (val.length >= this.maxMessageLength) {
        this.message = this.message.slice(0, this.maxMessageLength)
      // }
    }
  },
  computed: {
    ...mapGetters({
      authStatus: 'auth/authStatus',
      user: 'auth/user'
    }),
    messages () {
      return [...this.chat_messages].reverse()
    }
  },
  methods: {
    closeChat() {
      this.$root.$emit('openChat', true)
    },
    async deleteMessage (message) {
      this.actionLoading = `${message.id}_delete`

      await this.$apollo.mutate({
        mutation: DELETE_MESSAGE,
        variables: {
          id: message.id
        }
      }).then(() => {

        if(message.user.steamId === this.user.steamId) {
          const index = this.chat_messages.findIndex(item => item.id === message.id)
          if (index > -1) {
            this.chat_messages.splice(index, 1)
          }
        }

        this.$toast.success(this.$t('chat.success_delete'));
      }).catch((error) => {
        error.graphQLErrors.map(item => {
          this.$toast.error(item.message);
        })
      }).finally(() => {
        setTimeout(() => {
          this.actionLoading = null
        }, 500)
      })
    },
    async banUser (user) {
      this.actionLoading = `${user.steamId}_banned`
      const isBanned = !user.isBanned
      await this.$apollo.mutate({
        mutation: BANNED_USER,
        variables: {
          steamId: user.steamId,
          banned: isBanned
        }
      }).then(() => {
        if (isBanned) {
          this.$toast.success(this.$t('chat.success_banned'));
        } else {
          this.$toast.success(this.$t('chat.success_unbanned'));
        }
        this.messages.forEach((item) => {
          if (item.user.steamId === user.steamId) {
            item.user.isBanned = isBanned
          }
        })
        user.isBanned = isBanned
      }).catch((error) => {
        error.graphQLErrors.map(item => {
          this.$toast.error(item.message);
        })
      }).finally(() => {
        setTimeout(() => {
          this.actionLoading = null
        }, 500)
      })
    },
    async muteUser (user) {
      this.actionLoading = user.steamId
      const isMuted = !user.chatMuted
      await this.$apollo.mutate({
        mutation: MUTE_USER,
        variables: {
          steamId: user.steamId,
          muted: isMuted
        }
      }).then(() => {
        if (isMuted) {
          this.$toast.success(this.$t('chat.success_muted'));
        } else {
          this.$toast.success(this.$t('chat.success_unmuted'));
        }
        this.messages.forEach((item, key) => {
          if (item.user.steamId === user.steamId) {
            this.messages[key].user.chatMuted = isMuted
          }
        })
        user.chatMuted = isMuted
      }).catch((error) => {
        error.graphQLErrors.map(item => {
          this.$toast.error(item.message);
        })
      }).finally(() => {
        setTimeout(() => {
          this.actionLoading = null
        }, 500)
      })
    },
    toBottom () {
      const body = this.$refs.chat__messages
      body.scrollTop = body.scrollHeight
    },
    getTime (date) {
      date = new Date(date)

      let hours = date.getHours()
      let minutes = date.getMinutes()
      return `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}`;
    },
    async sendMessage () {
      if (!this.message) return;
      let response;

      try {
        response = await this.$apollo.mutate({
          mutation: SEND_CHAT_MESSAGE,
          variables: {
            text: this.message
          }
        })

        this.chat_messages.unshift(response.data.sendChatMessage)
        this.toBottom()
        this.message = ''
      } catch (e) {
        e.graphQLErrors.forEach(error => {
          this.$toast.error(this.$t(error.message));
        })
      }
    },
    hideAdminMenu () {
      this.openSettingsMessage = null
    }
  },
  components: {
    ChatUnauthorized,
    BlockLoader,
    ChatMuted
  },
  apollo: {
    chat_messages: {
      fetchPolicy: 'network-only',
      query: GET_CHAT_MESSAGES,
      update: data => data.chatMessages
    },
    usersOnline: {
      query: GET_USER_ONLINE,
      update: data => data.usersOnline
    },
    $subscribe: {
      ChatMessageStream: {
        query: NEW_CHAT_MESSAGE,
        result ({ data }) {
          if (data.ChatMessageStream.type === 'del') {
            const index = this.chat_messages.findIndex(item => item.id === data.ChatMessageStream.message.id)
            if (index > -1) {
              this.chat_messages.splice(index, 1)
            }
          } else {
            this.chat_messages.unshift(data.ChatMessageStream.message)
          }
        },
      },
      UsersOnlineStream: {
        query: USERS_ONLINE,
        result ({ data }) {
          this.usersOnline = data.UsersOnlineStream
        }
      }
    }
  },
  beforeDestroy() {
    document.removeEventListener('click', this.hideAdminMenu)
  },
  mounted () {
    document.addEventListener('click', this.hideAdminMenu)
    this.$root.$on('openChat', (close = false) => {
      this.forceOpenChat = !close;
      setTimeout(() => {
        this.toBottom()
      }, 0)
    })
  }
}
</script>
<style scoped lang="scss">
.chat {
  min-width: 85px;
  width: 85px;
  border-left: 1px dashed #31333C;
  position: fixed;
  height: 100vh;
  top: 0;
  background: #1A1B20;
  flex-direction: column;
  right: 0;
  //z-index: 99999;
  display: flex;

  @media(max-width: 1200px) {
    display: none;
    z-index: 999;
  }

  .chat__head {
    padding: 27px 27px;
    display: flex;
    align-items: center;
    border-bottom: 1px dashed #31333C;

    @media(min-width: 1200px) {
      padding-inline: 18px;
    }
    .chat__head-icon {
      width: 48px;
      height: 48px;
      background: linear-gradient(180deg, #272830 0%, #141518 100%);
      border: 1.5px dashed #31333B;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .chat__head-titles {
      flex-grow: 1;
      overflow: hidden;
      @media(min-width: 1200px) {
        display: none;
      }
      strong {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 5px;
        width: 100%;
        display: block;
      }
      span {
        display: flex;
        align-items: center;
        svg {
          margin-right: 4px;
        }
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        text-transform: uppercase;
        color: #66BF57;
      }
    }
    .chat__head-close {
      display: none;
      align-items: center;
      @media (max-width: 1200px) {
        display: flex;
      }
    }
    .chat__head-hide {
      display: flex;
      align-items: center;
      background: none;
      @media(min-width: 1200px) {
        display: none;
      }
      & > svg {
        margin-right: 5px;
      }
      & > div {
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        color: #595A5F;
        text-transform: uppercase;
      }
      @media (max-width: 1200px) {
        display: none;
      }
    }
  }
  .chat__body {
    flex-grow: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    padding-bottom: 20px;

    @media(min-width: 1200px) {
      display: none;
    }
    .chat__messages {
      flex-grow: 1;
      overflow: hidden;
      overflow-y: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      .chat__message {
        align-items: flex-start;
        display: flex;
        padding: 12px 0;
        .chat__messages-user {
          min-width: 46px;
          min-height: 46px;
          border-radius: 100%;
          overflow: hidden;
          margin-right: 10px;
        }
        .chat__messages-message {
          flex-grow: 1;
          text-overflow: ellipsis;
          word-break: break-word;
          .chat__messages-message_top {
            display: flex;
            align-items: center;
            position: relative;
            .nickname {
              font-weight: 600;
              font-size: 14px;
              line-height: 16px;
              white-space: nowrap;
              margin-right: 5px;
            }
            .date {
              font-weight: 500;
              font-size: 12px;
              line-height: 16px;
              color: #3B3E4A;
            }
            .chat__messages-message-settings {
              position: absolute;
              right: 0;
              cursor: pointer;
              .settings__body {
                position: absolute;
                background: #18191D;
                z-index: 99;
                border-radius: 8px;
                right: 0;
                bottom: 100%;
                border: 1px solid #31333B;
                width: 214px;
                padding: 0 15px;
                &.bottom {
                  bottom: unset;
                  top: 100%;
                }
                .settings__body-item {
                  display: flex;
                  align-items: center;
                  height: 45px;
                  .icon {
                    width: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  }
                  border-top: 1px solid #26272A;
                  span {
                    margin-left: 10px;
                    position: relative;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    transition: 0.2s;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 16px;
                    color: #84858E;
                    .block__loader {
                      position: static;
                      width: 23px;
                      height: 23px;
                    }
                  }
                  svg {
                    path {
                      transition: 0.2s;
                    }
                  }
                  &:hover {
                    svg {
                      path {
                        fill: #fff;
                      }
                    }
                    span {
                      color: #fff
                    }
                  }
                }
              }
              & > svg {
                path {
                  transition: 0.2s;
                }
              }
              &:hover {
                & > svg {
                  path {
                    fill: #fff;
                  }
                }
              }
            }
          }
          .chat__messages-message_text {
            max-width: 80%;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            padding: 10px;
            width: fit-content;
            background: #1F2025;
            margin-top: 5px;
            border-radius: 4px;
            color: #7A7B7E;
          }
        }
      }
    }
    .chat__input {
      margin-top: 10px;
      background: #1E1F25;
      border-radius: 12px;
      position: relative;
      input {
        width: 100%;
        background: transparent;
        outline: none;
        border: none;
        padding: 0 48px 0 16px;
        height: 55px;
        font-weight: 600;
        font-size: 14px;
        color: #fff;
        line-height: 17px;
        &::placeholder {
          color: #636469;
        }
      }
      button {
        position: absolute;
        right: 8px;
        height: 37px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #D9463C;
        top: calc(50% - 18.5px);
        width: 37px;
        border-radius: 12px;
      }
    }
  }
  .chat__hide {
    display: none;
  }


  &.open {
    min-width: 400px;
    width: 400px;
    display: flex;

    .chat__head {
      .chat__head-icon {
        margin-right: 10px;
      }
    }
    @media (max-width: 1600px) {
      min-width: 350px;
      width: 350px;
    }
    @media (max-width: 992px) {
      transform: translateY(70px);
      top: -70px;
    }
    @media(max-width: 1200px) {
      .chat__head {
        padding-block: 20px;
      }
    }
    @media(min-width: 1200px) {
      .chat__body {
        display: flex;
      }
      .chat__head {
        padding-inline: 27px;
        .chat__head-titles {
          display: block;
        }
        .chat__head-hide {
          display: flex;
        }
      }
      .chat__hide {
        display: none;
      }
    }
  }

  @media(min-width: 1200px) {
    .chat__hide {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      .chat__hide-button {
        background: none;
        transform: rotate(90deg);
        display: flex;
        align-items: center;
        justify-content: center;

        & > svg {
          transform: rotate(180deg);
          margin-right: 5px;
        }
      }
    }
  }
}
</style>
