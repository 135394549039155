export default function hexToRgbA(hex, alpha){
    var c;
    // TODO: colors и проверку удалить после изменения цветов на беке
    const colors = {
        '#gray': '#5D687C',
        '#blue': '#4176FF',
        '#purple': '#B92DC2',
        '#red': '#FF3D3D',
        '#yellow': '#FDB022',
    }
    hex = colors[hex] ? colors[hex] : hex

    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        c= hex.substring(1).split('');
        if(c.length== 3){
            c= [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c= '0x'+c.join('');
        // console.log('rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',' + alpha + ')')
        return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',' + alpha + ')';
    }

    return hex;
}
