<template>
  <footer class="footer">
    <div class="footer__wrapper">
      <div class="footer__item">
        <img class="footer__image" src="/img/flash.png" alt="upgrade">
        <div class="footer__count">{{ upgrade }}</div>
        <div class="footer__text">{{ $t('footer.upgrade') }}</div>
      </div>
      <div class="footer__item">
        <img class="footer__image" src="/img/dropbox.png" alt="cases">
        <div class="footer__count">{{ open }}</div>
        <div class="footer__text">{{ $t('footer.open') }}</div>
      </div>
      <div class="footer__item">
        <img class="footer__image" src="/img/bill.png" alt="withdraws">
        <div class="footer__count">{{ withdraw }}</div>
        <div class="footer__text">{{ $t('footer.withdraw') }}</div>
      </div>
      <div class="footer__item">
        <img class="footer__image" src="/img/users.png" alt="users">
        <div class="footer__count">{{ users }}</div>
        <div class="footer__text">{{ $t('footer.users') }}</div>
      </div>
    </div>
  </footer>
</template>

<script>
import {
  GET_OPEN_STATISTICS,
  GET_UPGRADE_STATISTICS,
  GET_USERS_STATISTICS,
  GET_WITHDRAW_STATISTICS
} from "@/queries";

export default {
  name: "PageFooter",
  apollo: {
    withdraw: {
      query: GET_WITHDRAW_STATISTICS,
      update: data => data.withdrawCount
    },
    open: {
      query: GET_OPEN_STATISTICS,
      update: data => data.openCount
    },
    users: {
      query: GET_USERS_STATISTICS,
      update: data => data.userCount
    },
    upgrade: {
      query: GET_UPGRADE_STATISTICS,
      update: data => data.upgradeCount
    },
  },
}
</script>

<style scoped lang="scss">
.footer {
  min-height: 100px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-top: auto;

  .footer__wrapper {
    display: flex;
    flex: 1;
    margin: 0 auto;
    max-width: 1600px;

    .footer__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-right: 1px dashed #31333C;
      flex: 1;
      row-gap: 7px;

      .footer__image{
        width: 28px;
        height: 28px;
      }
      .footer__count{
        font-size: 16px;
        line-height: 20px;
        font-weight: 700;
      }
      .footer__text{
        line-height: 17px;
        font-size: 14px;
        font-weight: 500;
        color: #7A7B7E;
        text-transform: uppercase;
      }
    }

    .footer__item:last-child {
      border-right: none;
      border-right-color: transparent;
    }
  }
}
</style>
