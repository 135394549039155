<template>
  <div class="case__filters">
    <div class="case__filters-wrapper">
      <div class="price-search-input">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.3" d="M11.9101 13.9226C11.5847 13.5972 11.5847 13.0695 11.9101 12.7441C12.2355 12.4186 12.7632 12.4186 13.0886 12.7441L16.4219 16.0774C16.7474 16.4028 16.7474 16.9305 16.4219 17.2559C16.0965 17.5814 15.5689 17.5814 15.2434 17.2559L11.9101 13.9226Z" fill="#5D606C"/>
          <path d="M9.16732 13.3334C11.4685 13.3334 13.334 11.4679 13.334 9.16671C13.334 6.86552 11.4685 5.00004 9.16732 5.00004C6.86613 5.00004 5.00065 6.86552 5.00065 9.16671C5.00065 11.4679 6.86613 13.3334 9.16732 13.3334ZM9.16732 15C5.94566 15 3.33398 12.3884 3.33398 9.16671C3.33398 5.94505 5.94566 3.33337 9.16732 3.33337C12.389 3.33337 15.0007 5.94505 15.0007 9.16671C15.0007 12.3884 12.389 15 9.16732 15Z" fill="#5D606C"/>
        </svg>
        <input v-model="search" :placeholder="$t('upgrade.search')" type="text">
      </div>

      <div class="price-filters">
        <div class="price-filter">
          <input type="radio" v-model="priceFilter" name="price" id="all" value="0-9999999">
          <label for="all">
          <span class="square">
            <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.2771 1.01768C10.5293 0.720962 10.9743 0.68488 11.2711 0.937095C11.5678 1.18931 11.6039 1.63431 11.3517 1.93103L5.35806 8.98232C5.10189 9.2837 4.64797 9.31545 4.35234 9.05266L1.17926 6.23215C0.888196 5.97342 0.861979 5.52773 1.1207 5.23667C1.37943 4.9456 1.82512 4.91939 2.11618 5.17811L4.75044 7.51967L10.2771 1.01768Z" fill="white"/>
            </svg>
          </span>
            <span class="label">Все</span>
          </label>
        </div>
        <div class="price-filter">
          <input type="radio" v-model="priceFilter" name="price" id="p01" value="0-100">
          <label for="p01">
          <span class="square">
            <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.2771 1.01768C10.5293 0.720962 10.9743 0.68488 11.2711 0.937095C11.5678 1.18931 11.6039 1.63431 11.3517 1.93103L5.35806 8.98232C5.10189 9.2837 4.64797 9.31545 4.35234 9.05266L1.17926 6.23215C0.888196 5.97342 0.861979 5.52773 1.1207 5.23667C1.37943 4.9456 1.82512 4.91939 2.11618 5.17811L4.75044 7.51967L10.2771 1.01768Z" fill="white"/>
            </svg>
          </span>
            <span class="label">₽0-100</span>
          </label>
        </div>
        <div class="price-filter">
          <input type="radio" v-model="priceFilter" name="price" id="p515" value="100-300">
          <label for="p515">
          <span class="square">
            <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.2771 1.01768C10.5293 0.720962 10.9743 0.68488 11.2711 0.937095C11.5678 1.18931 11.6039 1.63431 11.3517 1.93103L5.35806 8.98232C5.10189 9.2837 4.64797 9.31545 4.35234 9.05266L1.17926 6.23215C0.888196 5.97342 0.861979 5.52773 1.1207 5.23667C1.37943 4.9456 1.82512 4.91939 2.11618 5.17811L4.75044 7.51967L10.2771 1.01768Z" fill="white"/>
            </svg>
          </span>
            <span class="label">₽100-300</span>
          </label>
        </div>
        <div class="price-filter">
          <input type="radio" v-model="priceFilter" name="price" id="p15" value="500-99999999">
          <label for="p15">
          <span class="square">
            <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.2771 1.01768C10.5293 0.720962 10.9743 0.68488 11.2711 0.937095C11.5678 1.18931 11.6039 1.63431 11.3517 1.93103L5.35806 8.98232C5.10189 9.2837 4.64797 9.31545 4.35234 9.05266L1.17926 6.23215C0.888196 5.97342 0.861979 5.52773 1.1207 5.23667C1.37943 4.9456 1.82512 4.91939 2.11618 5.17811L4.75044 7.51967L10.2771 1.01768Z" fill="white"/>
            </svg>
          </span>
            <span class="label">₽500+</span>
          </label>
        </div>
      </div>

      <div class="spacer">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      priceFilter: '0-9999999',
      search: '',
    }
  },
  watch: {
    priceFilter () {
      this.$emit('filter', this.priceFilter)
    },
    search () {
      this.$emit('search', this.search)
    }
  }
}
</script>
<style scoped lang="scss">
.case__filters {
  background: #18191D;
  border-radius: 12px;
  margin-bottom: 22px;

  &-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 14px 14px;
    margin: 0 auto;

    @media (max-width: 640px) {
      flex-wrap: wrap;
    }
  }
  .price-search-input {

    margin-right: 10px;
    width: 250px;
    background: #131419;
    border-radius: 6px;
    display: flex;
    padding-left: 18px;
    align-items: center;
    @media (max-width: 992px) {
      width: 100%;
    }
    @media (max-width: 640px) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 10px;
    }
    input {
      margin-left: 10px;
      flex-grow: 1;
      overflow: hidden;
      outline: none;
      border: none;
      padding: 16px 0;
      background: transparent;
      font-weight: 700;
      font-size: 10px;
      line-height: 12px;
      color: #5D606C;
    }
  }
  .spacer {
    width: 250px;
  }

  .price-filters {
    @media (max-width: 640px) {
      width: 100%;
      justify-content: center;
    }
    background: #1C1D22;
    border: 1px solid #23242B;
    border-radius: 8px;
    padding: 10px;
    display: flex;
    @media (max-width: 450px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 10px;
    }
    .price-filter {
      cursor: pointer;
      display: flex;
      padding-left: 11px;
      @media (max-width: 450px) {
        padding-left: 0;

      }
      input {
        display: none;
      }
      label {
        display: flex;
        cursor: pointer;
        align-items: center;

        .square {
          background: rgba(48, 49, 55, 0.45);
          border-radius: 6px;
          width: 22px;
          transition: 0.2s;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 22px;
          margin-right: 6.5px;
          @media (max-width: 450px) {
            height: 17px;
            width: 17px;
          }
          svg {
            opacity: 0;
            transition: 0.2s;
          }
        }
        .label {
          font-weight: 600;
          font-size: 10px;
          white-space: nowrap;
          transition: 0.2s;
          line-height: 12px;
          color: #65666C;
          @media (max-width: 450px) {
            font-size: 9px;
          }
        }
      }
      input:checked + label {
        .square {
          background: #FE5E3B;
          svg {
            opacity: 1;
          }
        }
        .label {
          color: #fff;
        }
      }
    }
    .price-filter + .price-filter {
      margin-left: 14px;
      border-left: 1px solid #25262B;
      @media (max-width: 450px) {
        margin-left: 0;
        border-left: none;
      }
    }
  }
}
</style>
