<template>
  <div class="open__case">
    <div class="open__case-area" :ref="`container_${randContainer}`">
      <agile :ref="`slider_${randContainer}`" :speed="7350" :options="settings" class="open__case-list">
        <OpenCaseSlideItem v-for="(product, i) in getProducts" :key="i" :product="product"></OpenCaseSlideItem>
      </agile>
    </div>

  </div>
</template>
<script>
import { VueAgile } from 'vue-agile'
import OpenCaseSlideItem from './OpenCaseSlideItem';

export default {
  props: {
    products: {
      type: Array,
      default: () => []
    },
    winnerItem: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      randContainer: (Math.random() + 1).toString(36).substring(7),
      settings: {
        responsive: [
          {
            breakpoint: 1700,
            settings: {
              slidesToShow: 7
            }
          },
          {
            breakpoint: 1201,
            settings: {
              slidesToShow: 5
            }
          },
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 3
            }
          },
          {
            breakpoint: 575,
            settings: {
              slidesToShow: 3
            }
          },
          {
            breakpoint: 320,
            settings: {
              slidesToShow: 2
            }
          },
        ],
        slidesToShow: 7,
        centerMode: true,
        rtl: false,
        pauseOnHover: false,
        pauseOnDotsHover: false,

        infinite: true,
        navButtons: false,
        timing: 'ease-out',
        dots: false
      },
      translateTimeoutId: null
    }
  },
  computed: {
    getProducts() {
      const winnerKey = 35
      // this.products[winnerKey] = this.winnerItem.item

      return this.products.map((product, idx) => {
        if (idx === winnerKey) {
          return this.winnerItem.item
        }

        return product
      })
    }
  },
  methods: {
    getKey () {
      // let key = 0
      const winnerKey = 35

      // this.products[winnerKey] = this.winnerItem.item
      // for (const item in this.products) {
      //   if (this.products[item].name === this.winnerItem.item.name) {
      //     console.log("this.products[item]", this.products[item]);
      //     key = item
      //   }
      // }
      return Number(winnerKey)
    },
    randomInteger(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    start () {
      const key = this.getKey()
      this.$nextTick(() => {
        this.$refs[`slider_${this.randContainer}`].goTo(key)
        this.translateTimeoutId = setTimeout(() => {
          this.startSmallTranslate()
        }, 2000)
      })
    },
    startSmallTranslate () {
      if (window.innerWidth < 768) {
        this.$refs[`slider_${this.randContainer}`].$el.children[0].style['margin-left'] = `${this.randomInteger(20, 50)}px`;
      } else {
        let width = this.$refs[`slider_${this.randContainer}`].slides[0].clientWidth / 2
        this.$refs[`slider_${this.randContainer}`].$el.children[0].style['margin-left'] = `${Math.random() < 0.5 ? '-' : ''}${this.randomInteger(width / 5, width / 1.5)}px`
      }
    }
  },
  mounted () {
      this.start()
  },
  beforeDestroy() {
    if (this.translateTimeoutId) {
      clearTimeout(this.translateTimeoutId)
    }
    this.translateTimeoutId = null
    this.$emit('close')
  },
  components: {
    agile: VueAgile,
    OpenCaseSlideItem
  }
}
</script>
<style scoped lang="scss">
::v-deep {
  .ag-sl {
    padding: 0 7.5px;
  }
}
.open__case {
  position: relative;
  .open__case-area {
    .open__case-list {
      overflow: hidden;
      padding: 7.5px 0;
      background: #131419;
      display: flex;
      width: 100%;
    }

    position: relative;
    &::after {
      content: "";
      display: block;
      right: 0;
      position: absolute;
      top: 0;
      height: 100%;
      width: 250px;
      background: linear-gradient(90deg, #131419 0%, rgba(19, 20, 25, 0) 95.59%), linear-gradient(90deg, #131419 0%, rgba(19, 20, 25, 0) 95.59%);
      transform: rotate(180deg);
    }
    &::before {
      content: "";
      display: block;
      left: 0;
      z-index: 9;
      position: absolute;
      top: 0;
      height: 100%;
      width: 250px;
      background: linear-gradient(90deg, #131419 0%, rgba(19, 20, 25, 0) 95.59%), linear-gradient(90deg, #131419 0%, rgba(19, 20, 25, 0) 95.59%);
    }
  }
  .open__case-process {
    width: 222px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(254, 94, 59, 0.15);
    border-radius: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    margin: 0 auto;
    text-transform: uppercase;
    margin-top: 26px;
    color: #FE5E3B;
  }
  .open__case-cursor {
    position: absolute;
    left: 50%;
    height: calc(100% - 20px);
    width: 2px;
    background: #FE5E3B;
    top: 10px;
    display: flex;
    justify-content: center;
    &::after {
      content: "";
      display: block;
      width: 16px;
      height: 18px;
      background: url(/img/arrow-down.svg);
      position: absolute;
      top: -20px;
    }
    &::before {
      content: "";
      display: block;
      width: 16px;
      background-color: #18191D;
      height: 18px;
      background-image: url(/img/arrow-down.svg);
      transform: rotate(180deg);
      position: absolute;
      bottom: -20px;
    }
  }
  &::after {
    position: absolute;
    content: "";
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
  }
}
</style>
