<template>
  <header class="header">
    <router-link to="/" class="header-logo">
      <img src="/img/logo.svg" class="desktop" alt="">
      <img src="/img/short-logo-main.svg" class="mobile" alt="">
    </router-link>
    <div class="header-content">
      <div class="header-content__nav">
        <router-link to="/store" :class="{'active': $route.name === 'Store'}">
          <img src="/img/top-bg.png" alt="" class="top">
          <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M2.42959 0H17.5723C18.2342 0 18.7813 0.515894 18.8202 1.1766L19.8453 18.6032C19.9263 19.9815 18.8747 21.1646 17.4964 21.2457C17.4475 21.2486 17.3985 21.25 17.3496 21.25H2.65234C1.27163 21.25 0.152344 20.1307 0.152344 18.75C0.152344 18.701 0.153783 18.6521 0.156658 18.6032L1.18175 1.1766C1.22062 0.515894 1.76775 0 2.42959 0Z" fill="#595A5F"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.75 3.75H6.25C6.25 5.82107 7.92893 7.5 10 7.5C12.0711 7.5 13.75 5.82107 13.75 3.75H16.25C16.25 7.20178 13.4518 10 10 10C6.54822 10 3.75 7.20178 3.75 3.75Z" fill="#595A5F"/>
          </svg>
          <span>{{ $t('header.store') }}</span>
        </router-link>
      </div>
      <div class="header-content__indicators">
        <HeaderNotificationWrapper v-if="$root.isAuth"></HeaderNotificationWrapper>
        <div class="header-content__indicator">
          <div class="indicator__main" @click="changeSoundDisabled">
            <svg v-if="!soundDisabled" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path opacity="0.3" d="M19.0745 18.7407C18.6613 18.9718 18.1391 18.8242 17.908 18.411C17.677 17.9978 17.8246 17.4756 18.2378 17.2445C19.4047 16.5919 20.1424 15.361 20.1424 13.9999C20.1424 12.6489 19.4157 11.4257 18.2623 10.7692C17.8509 10.535 17.7072 10.0117 17.9414 9.60026C18.1756 9.18886 18.6989 9.04519 19.1103 9.27937C20.794 10.2377 21.8567 12.0264 21.8567 13.9999C21.8567 15.9882 20.778 17.7881 19.0745 18.7407Z" fill="#595A5F"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M8.42718 18.5713L4.55034 17.796C3.48194 17.5823 2.71289 16.6442 2.71289 15.5546V12.4452C2.71289 11.3556 3.48194 10.4175 4.55034 10.2039L8.42718 9.42849L12.1905 5.66518C12.6368 5.21887 13.3604 5.21887 13.8067 5.66518C14.0211 5.87951 14.1415 6.1702 14.1415 6.4733V21.5265C14.1415 22.1577 13.6298 22.6694 12.9986 22.6694C12.6955 22.6694 12.4048 22.549 12.1905 22.3346L8.42718 18.5713Z" fill="#595A5F"/>
            </svg>
            <svg v-else width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M20.5171 14L18.8672 12.3501C18.4116 11.8945 18.4116 11.1558 18.8672 10.7002C19.3228 10.2446 20.0615 10.2446 20.5171 10.7002L22.167 12.3501L23.817 10.7002C24.2726 10.2446 25.0113 10.2446 25.4669 10.7002C25.9225 11.1558 25.9225 11.8945 25.4669 12.3501L23.817 14L25.4669 15.6499C25.9225 16.1055 25.9225 16.8442 25.4669 17.2998C25.0113 17.7554 24.2726 17.7554 23.817 17.2998L22.167 15.6499L20.5171 17.2998C20.0615 17.7554 19.3228 17.7554 18.8672 17.2998C18.4116 16.8442 18.4116 16.1055 18.8672 15.6499L20.5171 14Z" fill="#595A5F"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M8.16634 18.6667L4.20874 17.8751C3.11808 17.657 2.33301 16.6994 2.33301 15.5871V12.4129C2.33301 11.3006 3.11808 10.343 4.20874 10.1248L8.16634 9.33332L12.0081 5.49161C12.4637 5.036 13.2024 5.036 13.658 5.49161C13.8768 5.7104 13.9997 6.00715 13.9997 6.31657V21.6834C13.9997 22.3277 13.4773 22.8501 12.833 22.8501C12.5236 22.8501 12.2268 22.7272 12.0081 22.5084L8.16634 18.6667Z" fill="#595A5F"/>
            </svg>
          </div>
        </div>
        <div class="header-content__indicator">
          <div class="indicator__main" @click="isShowLanguageSwitcher = !isShowLanguageSwitcher" :class="{'open': isShowLanguageSwitcher}">
            <div class="current-language">
              <template v-if="$root.currentLanguage === 'en'">
                <img src="/img/en-flag.png" alt="">
              </template>
              <template v-else>
                <img src="/img/ru-flag.png" alt="">
              </template>
              <span>{{ String($root.currentLanguage).toUpperCase() }}</span>
            </div>
            <div class="indicator__main-languages" v-show="isShowLanguageSwitcher">
              <div class="language-item" @click="$root.setLanguage('ru')" v-if="$root.currentLanguage !== 'ru'">
                <img src="/img/ru-flag.png" alt="">
                <span>RU</span>
              </div>
              <div class="language-item" @click="$root.setLanguage('en')" v-if="$root.currentLanguage !== 'en'">
                <img src="/img/en-flag.png" alt="">
                <span>EN</span>
              </div>
            </div>
          </div>

        </div>
        <div @click.prevent.stop="$root.$emit('openChat')" class="header-content__indicator burger">
          <div class="indicator__main">
            <img src="/img/chat.svg" alt="">
          </div>
        </div>
        <div @click.prevent.stop="openMobileMenu = !openMobileMenu" class="header-content__indicator burger">
          <div class="indicator__main">
            <svg width="18px" height="12px" viewBox="0 0 18 12" version="1.1">
              <g id="Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Rounded" transform="translate(-885.000000, -3438.000000)">
                  <g id="Navigation" transform="translate(100.000000, 3378.000000)">
                    <g id="-Round-/-Navigation-/-menu" transform="translate(782.000000, 54.000000)">
                      <g transform="translate(0.000000, 0.000000)">
                        <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                        <path d="M4,18 L20,18 C20.55,18 21,17.55 21,17 C21,16.45 20.55,16 20,16 L4,16 C3.45,16 3,16.45 3,17 C3,17.55 3.45,18 4,18 Z M4,13 L20,13 C20.55,13 21,12.55 21,12 C21,11.45 20.55,11 20,11 L4,11 C3.45,11 3,11.45 3,12 C3,12.55 3.45,13 4,13 Z M3,7 C3,7.55 3.45,8 4,8 L20,8 C20.55,8 21,7.55 21,7 C21,6.45 20.55,6 20,6 L4,6 C3.45,6 3,6.45 3,7 Z" id="🔹-Icon-Color" fill="#595A5F"></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </div>
        </div>
        <transition name="slide-fade">
          <MobileMenu @close="openMobileMenu = false" v-if="openMobileMenu"></MobileMenu>
        </transition>
      </div>
      <div class="header-content__balance hidden-md">
        <AuthorizationBalance v-if="!authStatus"></AuthorizationBalance>
        <UserHeaderState v-else></UserHeaderState>
      </div>
    </div>
  </header>
</template>
<script>
import HeaderNotificationWrapper from './HeaderNotificationWrapper'
import AuthorizationBalance from '../components/AuthorizationBalance'
import UserHeaderState from '../components/UserHeaderState'
import MobileMenu from '../components/MobileMenu'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      openMobileMenu: false,
      isShowLanguageSwitcher: false,
      soundDisabled: localStorage.getItem('soundDisabled') ? parseInt(localStorage.getItem('soundDisabled')) : 0
    }
  },
  methods: {
    changeSoundDisabled () {
      if (this.soundDisabled) {
        localStorage.setItem('soundDisabled', 0);
        this.soundDisabled = 0
      } else {
        localStorage.setItem('soundDisabled', 1);
        this.soundDisabled = 1
      }
    }
  },
  watch: {
    '$route': function () {
      this.openMobileMenu = false
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      authStatus: 'auth/authStatus'
    })
  },
  components: {
    UserHeaderState,
    MobileMenu,
    HeaderNotificationWrapper,
    AuthorizationBalance
  }
}
</script>
<style scoped lang="scss">
  .header {
    display: flex;
    margin-right: -20px;
    position: relative;
    z-index: 21;
    border-bottom: 1px dashed #31333C;
    align-items: center;
    @media (max-width: 1200px) {
      margin-left: -20px;
    }
    .header-logo {
      padding: 16px 2px;
      padding-left: 5px;
      padding-right: 56px;
      background: #16171C;
      box-shadow: inset 0px 0px 35px rgba(43, 45, 53, 0.45);
      .mobile {
        display: none;
      }
      @media (max-width: 1600px) {
        .mobile {
          width: 50px;
          min-width: 50px;
          display: block;
        }
        .desktop {
          display: none;
        }
      }
      @media (max-width: 1200px) {
        background: none;
        box-shadow: none;
        height: 100%;
      }
      img {
        min-width: 184px;
        width: 184px;
        @media (max-width: 1600px) {
          min-width: 155px;
          width: 155px;
        }
      }
      @media (max-width: 1600px) {
        padding-right: 10px;
        padding-left: 10px;
      }
    }
    .header-content {
      flex-grow: 1;
      background: #18191D;
      padding: 27px;
      display: flex;
      align-items: center;
      @media (max-width: 1600px) {
        padding-right: 20px;
      }
      @media (max-width: 1200px) {
        padding: 20px;
      }
      .header-content__nav {
        @media (max-width: 1200px) {
          display: none;
        }
        a {
          display: flex;
          position: relative;
          align-items: center;
          text-transform: uppercase;
          .top {
            position: absolute;
            top: -37px;
            left: 0;
            width: 120px;
            max-width: 120px;
            transition: 0.2s;
            opacity: 0;
          }
          &:hover, &.active {
            .top {
              opacity: 1;
            }
          }
          span {
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            color: #595A5F;
            margin-left: 15px;
            display: block;
            transition: 0.2s;
          }
          svg {
            path {
              transition: 0.2s;
            }
          }
          &:hover, &.active {
            svg {
              path:first-child {
                fill: #FE5E3B;
                opacity: 1;
              }
              path:last-child {
                fill: #fff;
              }
            }
            span {
              color: #fff;
            }
          }
        }
      }
    }
    .header-content__indicators {
      margin-left: auto;
      display: flex;
      position: relative;
      align-items: center;
      .header-content__indicator {
        display: flex;
        cursor: pointer;
        position: relative;
        z-index: 9;
        .indicator__main {
          padding: 0 11px;
          height: 48px;
          display: flex;
          justify-content: center;
          align-items: center;
          min-width: 48px;
          border: 1.5px solid rgba(49, 51, 59, 0.8);
          border-radius: 12px;
          position: relative;
          .indicator-new {
            position: absolute;
            width: 7px;
            height: 7px;
            border-radius: 100%;
            background: #FE5E3B;
            margin-left: 15px;
            top: calc(50% + 1px);
            box-shadow: 0 0 0 0 rgba(255, 121, 63, 1);
            animation: pulse-orange 2s infinite;
            @keyframes pulse-orange {
              0% {
                transform: scale(0.95);
                box-shadow: 0 0 0 0 rgba(255, 121, 63, 0.7);
              }

              70% {
                transform: scale(1);
                box-shadow: 0 0 0 10px rgba(255, 121, 63, 0);
              }

              100% {
                transform: scale(0.95);
                box-shadow: 0 0 0 0 rgba(255, 121, 63, 0);
              }
            }
          }
          &.open {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
          }
          .indicator__main-languages {
            position: absolute;
            top: 100%;
            background: #18191D;
            border: 1.5px solid #31333B;
            border-radius: 12px;
            margin: 0;
            width: 100%;
            box-sizing: content-box;
            border-top: 1.5px solid rgba(49, 51, 59, 0.35);
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            .language-item {
              padding: 11px;
              display: flex;
              align-items: center;
              transition: 0.1s;
              font-weight: 700;
              font-size: 14px;
              line-height: 17px;
              &:hover {
                background: rgba(49, 51, 59, 0.15);
              }
              img {
                width: 25px;
                height: 25px;
                border-radius: 100%;
                margin-right: 12px;
              }
            }
          }
        }
        .current-language {
          display: flex;
          align-items: center;
          img {
            width: 25px;
            height: 25px;
            border-radius: 100%;
            margin-right: 12px;
          }
          span {
            font-weight: 700;
            font-size: 14px;
            min-width: 30px;
            line-height: 17px;
            color: #595A5F;
          }
        }
        svg {
          path {
            transition: 0.1s;
          }
        }
        &:hover {
          svg {
            path {
              fill: #FE5E3B
            }
          }
        }
        &.burger {
          display: none;
          @media (max-width: 1200px) {
            display: block;
          }
        }
      }
      .header-content__indicator + .header-content__indicator {
        margin-left: 17px;
        @media (max-width: 1600px) {
          margin-left: 8px;
        }
      }
    }
    .header-content__balance {
      margin-left: 100px;
      position: relative;
      background: #202127;
      border-radius: 10px;
      display: flex;
      @media (max-width: 1600px) {
        margin-left: 35px;
      }
      .header-content__balance-icon {
        position: absolute;
        width: 44px;
        height: 44px;
        border-radius: 100%;
        background: #FE5E3B;
        left: -22px;
        top: calc(50% - 22px);
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .header-content__balance-value {
        display: flex;
        align-items: center;
        padding: 15.5px 0;
        padding-left: 30px;
        padding-right: 40px;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        @media (max-width: 1600px) {
          padding-right: 20px;
        }
      }
      .header-content__balance-deposit {
        padding: 15.5px 33px;
        background: url(/img/button-bg.png);
        background-size: 100% 100%;
        border-radius: 8px;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;

      }
    }
  }
</style>
