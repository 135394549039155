<template>
  <div class="lose__upgrade" v-if="item">
    <div class="point top">
      <svg width="18" height="42" viewBox="0 0 18 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_29_62178)">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M2.00702 0.00488281L9 1.25687L15.993 0.00488281L9 6.99988L2.00702 0.00488281ZM0 4.99988L9 11.2569L18 4.99988L9 16.9999L0 4.99988Z" fill="#FF3535"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M8 21H10V42H8V21Z" fill="#FF3535"/>
        </g>
        <defs>
          <clipPath id="clip0_29_62178">
            <rect width="18" height="42" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    </div>
    <div class="point left">
      <svg width="18" height="42" viewBox="0 0 18 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_29_62178)">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M2.00702 0.00488281L9 1.25687L15.993 0.00488281L9 6.99988L2.00702 0.00488281ZM0 4.99988L9 11.2569L18 4.99988L9 16.9999L0 4.99988Z" fill="#FF3535"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M8 21H10V42H8V21Z" fill="#FF3535"/>
        </g>
        <defs>
          <clipPath id="clip0_29_62178">
            <rect width="18" height="42" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    </div>
    <div class="point right">
      <svg width="18" height="42" viewBox="0 0 18 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_29_62178)">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M2.00702 0.00488281L9 1.25687L15.993 0.00488281L9 6.99988L2.00702 0.00488281ZM0 4.99988L9 11.2569L18 4.99988L9 16.9999L0 4.99988Z" fill="#FF3535"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M8 21H10V42H8V21Z" fill="#FF3535"/>
        </g>
        <defs>
          <clipPath id="clip0_29_62178">
            <rect width="18" height="42" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    </div>
    <div class="point bottom">
      <svg width="18" height="42" viewBox="0 0 18 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_29_62178)">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M2.00702 0.00488281L9 1.25687L15.993 0.00488281L9 6.99988L2.00702 0.00488281ZM0 4.99988L9 11.2569L18 4.99988L9 16.9999L0 4.99988Z" fill="#FF3535"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M8 21H10V42H8V21Z" fill="#FF3535"/>
        </g>
        <defs>
          <clipPath id="clip0_29_62178">
            <rect width="18" height="42" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    </div>
    <img :src="`https://community.akamai.steamstatic.com/economy/image/${item.image}/360fx360f`" alt="">
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>
<style scoped lang="scss">
.lose__upgrade {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-height: 80%;
    max-width: 80%;
  }
  border: 5px solid rgba(255, 53, 53, 0.5);
  border-radius: 100%;
  position: relative;
  .point {
    position: absolute;
    width: 18px;
    left: calc(50% - 9px);
    &.top {
      top: -38px;
    }
    &.left {
      left: -22px;
      transform: rotate(-90deg);
      top: calc(50% - 22px);
    }
    &.right {
      left: unset;
      right: -22px;
      transform: rotate(90deg);
      top: calc(50% - 22px);
    }
    &.bottom {
      left: calc(50% - 9px);
      bottom: -38px;
      transform: rotate(-180deg);
    }
  }
}
</style>
