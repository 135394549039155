<template>
  <div class="header-content__indicator" :class="{'empty': !notifications || notifications.length === 0}" @click.prevent.stop="showNotifications">
    <transition name="fade">
      <NotificationsWrapper :notifications="notifications" v-show="isShowNotifications"></NotificationsWrapper>
    </transition>
    <div class="indicator__main">
      <div class="indicator-new" v-if="hasNewNotifications"></div>
      <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M19.7146 13.9999H21.4289C22.3756 13.9999 23.1431 14.7675 23.1431 15.7142C23.1431 16.661 22.3756 17.4285 21.4289 17.4285H6.57171C5.62493 17.4285 4.85742 16.661 4.85742 15.7142C4.85742 14.7675 5.62493 13.9999 6.57171 13.9999H8.28599L8.92402 8.2577C9.21142 5.6711 11.3978 3.71423 14.0003 3.71423C16.6028 3.71423 18.7891 5.6711 19.0765 8.2577L19.7146 13.9999Z" fill="#595A5F"/>
        <path opacity="0.3" d="M16.2863 20.8571C16.2863 19.5948 15.2629 18.5714 14.0006 18.5714C12.7382 18.5714 11.7148 19.5948 11.7148 20.8571C11.7148 22.1195 12.7382 23.1428 14.0006 23.1428C15.2629 23.1428 16.2863 22.1195 16.2863 20.8571Z" fill="#595A5F"/>
      </svg>
    </div>
  </div>
</template>
<script>
// import { GET_NOTIFICATIONS } from '../queries/index'
import moment from 'moment'
import { NOTIFY_STREAM } from '../queries/subscriptions'
import NotificationsWrapper from './NotificationsWrapper'
import { mapGetters } from "vuex";

export default {
  data () {
    return {
      isShowNotifications: false,
      hasNewNotifications: false,
      notifications: [],
      event: null
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    })
  },
  methods: {
    updateNotificationsCounter () {
      const date = this.notifications && this.notifications[0] ? this.notifications[0].createdAt : (new Date());
      localStorage.setItem('notification_last_viewed', date);
    },
    showNotifications () {
      this.updateNotificationsCounter()
      this.checkNewNotifications()
      this.isShowNotifications = true
    },
    checkNewNotifications () {
      if (!this.notifications || !this.notifications.length) {
        this.hasNewNotifications = false
        return
      }
      const lastSeen = localStorage.getItem('notification_last_viewed') ? moment(localStorage.getItem('notification_last_viewed')) : null;
      if (!lastSeen && this.notifications && this.notifications.length > 0) {
        this.hasNewNotifications = true
      }

      this.hasNewNotifications = moment(this.notifications && this.notifications[0] ? this.notifications[0].createdAt : (new Date())) > lastSeen;
    },
    hideNotify () {
      if (this.isShowNotifications) {
        this.isShowNotifications = false
      }
    }
  },
  apollo: {
    // notifications: {
    //   query: GET_NOTIFICATIONS,
    //   update: data => data.notifications
    // },
    $subscribe: {
      NotifyStream: {
        query: NOTIFY_STREAM,
        result ({ data }) {
          this.$root.$emit('SoundPlay', 'notify')
          this.notifications.unshift(data.NotifyStream)
        },
      }
    }
  },
  watch: {
    'notifications.length': function () {
      if (!this.isShowNotifications) {
        this.checkNewNotifications()
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('click', this.hideNotify)
  },
  mounted () {
    this.notifications = this.user ? this.user.notifications : []
    this.checkNewNotifications()
    window.addEventListener('click', this.hideNotify)
  },
  components: {
    NotificationsWrapper
  }
}
</script>
<style scoped lang="scss">
.header-content__indicator {
  display: flex;
  cursor: pointer;
  position: relative;
  z-index: 9999999;
    &.empty {
      @media (max-width: 768px) {
        display: none !important;
      }
  }
  .indicator__main {
    padding: 0 11px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 48px;
    border: 1.5px solid rgba(49, 51, 59, 0.8);
    border-radius: 12px;
    position: relative;
    .indicator-new {
      position: absolute;
      width: 7px;
      height: 7px;
      border-radius: 100%;
      background: #FE5E3B;
      margin-left: 15px;
      top: calc(50% + 1px);
      box-shadow: 0 0 0 0 rgba(255, 121, 63, 1);
      animation: pulse-orange 2s infinite;
      @keyframes pulse-orange {
        0% {
          transform: scale(0.95);
          box-shadow: 0 0 0 0 rgba(255, 121, 63, 0.7);
        }

        70% {
          transform: scale(1);
          box-shadow: 0 0 0 10px rgba(255, 121, 63, 0);
        }

        100% {
          transform: scale(0.95);
          box-shadow: 0 0 0 0 rgba(255, 121, 63, 0);
        }
      }
    }
    &.open {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
    .indicator__main-languages {
      position: absolute;
      top: 100%;
      background: #18191D;
      border: 1.5px solid #31333B;
      border-radius: 12px;
      width: 103%;
      left: -1%;
      border-top: 1.5px solid rgba(49, 51, 59, 0.35);
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      .language-item {
        padding: 11px;
        display: flex;
        align-items: center;
        transition: 0.1s;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        &:hover {
          background: rgba(49, 51, 59, 0.15);
        }
        img {
          width: 25px;
          height: 25px;
          border-radius: 100%;
          margin-right: 12px;
        }
      }
    }
  }
  .current-language {
    display: flex;
    align-items: center;
    img {
      width: 25px;
      height: 25px;
      border-radius: 100%;
      margin-right: 12px;
    }
    span {
      font-weight: 700;
      font-size: 14px;
      min-width: 30px;
      line-height: 17px;
      color: #595A5F;
    }
  }
  svg {
    path {
      transition: 0.1s;
    }
  }
  &:hover {
    svg {
      path {
        fill: #FE5E3B
      }
    }
  }
}
</style>
