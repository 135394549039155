<template>
  <div class="inventory__wrapper">
    <transition name="fade">
      <Loader v-if="loading"></Loader>
    </transition>
    <div class="empty-items" v-if="!total && !$apollo.loading">
      {{ $t('upgrade.empty') }}
    </div>
    <div class="inventory__wrapper-body" v-else>
      <div class="inventory__wrapper-body_items">
        <slot></slot>
      </div>
      <div class="inventory__wrapper-pagination" v-if="allPages > page">
        <div class="prev" @click="goPrev" :class="{'disabled': page === 0}">{{ $t('upgrade.prev') }}</div>
        <div class="counter">
          {{ page + 1 }}/{{ allPages }}
        </div>
        <div class="next" @click="goNext" :class="{'disabled': page + 1 === allPages}">{{ $t('upgrade.next') }}</div>
      </div>
    </div>

  </div>
</template>
<script>
import Loader from '../Loader'
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    page: {
      type: Number,
      required: true
    },
    total: {
      type: Number,
      required: true
    }
  },
  methods: {
    goPrev () {
      if (this.page === 0) return;
      this.$emit('next', this.page - 1)
    },
    goNext () {
      if (this.page + 1 === this.allPages) return;
      this.$emit('next', this.page + 1)
    }
  },
  computed: {
    allPages () {
      return Math.ceil(this.total / 15);
    }
  },
  components: {
    Loader
  }
}
</script>
<style scoped lang="scss">
.inventory__wrapper {
  .empty-items {
    width: 100%;
    text-align: center;
    left: 0;
    font-weight: 600;
    font-size: 12px;
    min-height: 450px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 19px;
    letter-spacing: 0.05em;
    height: 100%;
    text-transform: uppercase;
    color: #676974;
    @media (max-width: 992px) {
      font-size: 12px;
      text-align: center;
      padding: 0 20px;
      margin-top: 10px;
    }
  }

  margin-top: 20px;
  overflow: hidden;
  background: #1D1E23;
  border-radius: 12px;
  padding: 12px;
  position: relative;
  .inventory__wrapper-body {
    background: #131419;
    border-radius: 8px;
    padding: 19px;
    @media (max-width: 575px) {
      padding: 8px;
    }
    .inventory__wrapper-body_items {
      margin-bottom: 18px;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-gap: 10px;
      @media (max-width: 1750px) {
        grid-template-columns: repeat(4, 1fr);
      }
      @media (max-width: 1500px) {
        grid-template-columns: repeat(3, 1fr);
      }
      @media (max-width: 850px) {
        grid-template-columns: repeat(2, 1fr);
      }
    }
    .inventory__wrapper-pagination {
      background: #1D1E23;
      border-radius: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .counter {
        font-weight: 600;
        font-size: 10px;
        line-height: 12px;
        text-align: center;
        letter-spacing: 0.2em;
        color: #34353D;
      }
      .prev {
        border-right: 1px solid rgba(44, 45, 52, 0.5);
      }
      .next {
        border-left: 1px solid rgba(44, 45, 52, 0.5);
      }
      .prev, .next {
        padding: 14px;
        font-weight: 600;
        cursor: pointer;
        font-size: 10px;
        line-height: 12px;
        text-align: center;
        color: #fff;
        user-select: none;
        transition: 0.2s;
        &:hover {
          opacity: 0.8;
        }
        &.disabled {
          cursor: not-allowed;
          user-select: none;
          color: #3C3D46;
        }
      }
    }
  }
  transition: 0.2s;
  &.disabled {
    filter: blur(1px);
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      content: "";
      display: block;
      opacity: 0.6;
      background: rgba(19, 20, 25, 1);

      z-index: 999;
    }
  }
}
</style>
