<template>
  <div class="empty__inventory">
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_76_23232)">
        <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M9.99984 5H29.9998L33.3332 10.8333H6.6665L9.99984 5Z" fill="#676974"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.99984 8.33337H29.9998C31.8408 8.33337 33.3332 9.82576 33.3332 11.6667V31.6667C33.3332 33.5077 31.8408 35 29.9998 35H9.99984C8.15889 35 6.6665 33.5077 6.6665 31.6667V11.6667C6.6665 9.82576 8.15889 8.33337 9.99984 8.33337ZM14.9998 15C14.0794 15 13.3332 15.7462 13.3332 16.6667C13.3332 17.5872 14.0794 18.3334 14.9998 18.3334H24.9998C25.9203 18.3334 26.6665 17.5872 26.6665 16.6667C26.6665 15.7462 25.9203 15 24.9998 15H14.9998Z" fill="#676974"/>
      </g>
      <defs>
        <clipPath id="clip0_76_23232">
          <rect width="40" height="40" fill="white"/>
        </clipPath>
      </defs>
    </svg>
    <span>{{ text ? text : $t('profile.empty_inventory') }}</span>
  </div>
</template>
<script>
export default {
  props: {
    text: {
      type: String
    }
  }
}
</script>
<style scoped lang="scss">
.empty__inventory {
  margin-top: 140px;
  margin-bottom: 140px;
  text-align: center;
  span {
    width: 100%;
    text-align: center;
    display: block;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #676974;
  }
}
</style>
