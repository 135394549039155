export function getStatusByStatus (status) {
    const WITHDRAWING = ['creatingTrade', 'waitingAccept']
    const PROCESS = ['process']
    const SUCCESS = ['accepted', '']
    const CANCEL = [
        'declined',
        'canceled',
        'timeout',
        'invalidTradeToken',
        'userNotTradable',
        'tradeCreateError',
        'buyError',
        'unknownError'
    ]

    if (WITHDRAWING.includes(status)) {
        return 'withdrawing'
    }

    if (PROCESS.includes(status)) {
        return 'process'
    }

    if (SUCCESS.includes(status)) {
        return 'success'
    }

    if (CANCEL.includes(status)) {
        return 'cancel'
    }

    return 'process'
}
