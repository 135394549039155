<template>
  <div class="user__header-state">
    <div class="header-content__balance-icon">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.3" d="M20.5 14C21.3284 14 22 13.3284 22 12.5C22 11.6716 21.3284 11 20.5 11C19.6716 11 19 11.6716 19 12.5C19 13.3284 19.6716 14 20.5 14Z" fill="#18191D"/>
        <path opacity="0.3" d="M18.8211 1.04868L3.36632 5.18979C2.83285 5.33273 2.51627 5.88107 2.65921 6.41453L3.9533 11.2442C4.09625 11.7776 4.64458 12.0942 5.17805 11.9513L20.6329 7.81017C21.1663 7.66722 21.4829 7.11889 21.34 6.58542L20.0459 1.75579C19.9029 1.22233 19.3546 0.905743 18.8211 1.04868Z" fill="#18191D"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M22 9.33682C21.5454 9.12085 21.0368 9 20.5 9C18.567 9 17 10.567 17 12.5C17 14.433 18.567 16 20.5 16C21.0368 16 21.5454 15.8792 22 15.6632V18C22 19.1046 21.1046 20 20 20H4C2.89543 20 2 19.1046 2 18V6C2 4.89543 2.89543 4 4 4H20C21.1046 4 22 4.89543 22 6V9.33682Z" fill="#18191D"/>
      </svg>
    </div>
    <div class="header-content__balance-value">{{ user.getBalance() }}</div>
    <button class="header-content__balance-deposit" @click="toggleDeposit(true)">
      {{ $t('header.deposit') }}
    </button>
  </div>
</template>
<script>
import { BALANCE_UPDATE } from '../queries/subscriptions'
import { mapGetters, mapMutations, mapActions } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      user: 'auth/user',
    })
  },
  methods: {
    ...mapActions({
      toggleDeposit: 'main/toggleDeposit'
    }),
    ...mapMutations({
      updateBalance: 'auth/updateBalance'
    })
  },
  apollo: {
    $subscribe: {
      UserBalanceStream: {
        query: BALANCE_UPDATE,
        result ({ data }) {
          const balance = data.UserBalanceStream.balance
          if (balance < this.user.balance) {
            this.$root.$emit('SoundPlay', 'cashout')
          }
          this.updateBalance(balance)
        },
      },

    }
  }
}
</script>
<style scoped lang="scss">
.user__header-state {
  display: flex;
  .header-content__balance-icon {
    position: absolute;
    width: 44px;
    height: 44px;
    border-radius: 100%;
    background: #FE5E3B;
    left: -22px;
    top: calc(50% - 22px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .header-content__balance-value {
    display: flex;
    align-items: center;
    padding: 15.5px 0;
    padding-left: 30px;
    padding-right: 40px;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    @media (max-width: 1600px) {
      padding-right: 20px;
    }
  }
  .header-content__balance-deposit {
    padding: 15.5px 33px;
    background: url(/img/button-bg.png);
    background-size: 100% 100%;
    border-radius: 8px;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    @media (max-width: 1600px) {
      padding: 15.5px 16px;
    }
  }
}
</style>
