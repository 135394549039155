<template>
  <div class="profile__item">
<!--    <div class="profile__stock" :class="product.item.stock > 0 ? 'profile__stock&#45;&#45;' + product.item.color : ''" v-if="-->
    <div class="profile__stock" v-tooltip="{ content: $t('profile.stock'), offset: 5 }"
         :style="{
           backgroundColor: hexToRgbA('#' + product.item.color, 1),
           paddingBlock: '4px',
           paddingInline: '8px'
         }"
         v-if="
           product.status !== 'sold' &&
           product.status !== 'withdrawn' &&
           product.status !== 'accepted' &&
           product.status !== 'upgrade' &&
           product.status !== 'withdrawing' &&
           product.status !== 'creatingTrade' &&
           product.status !== 'waitingAccept'
        "
    >
      <div class="profile__stock-icon" :style="'margin-right: 5px'">
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 3L4.5 8.5L2 6" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

<!--        <svg v-else width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--          <path d="M8.5 3.5L3.5 8.5M3.5 3.5L8.5 8.5" stroke="white" stroke-opacity="0.3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--        </svg>-->
      </div>
      <div class="profile__stock-text">
        <span>x</span>
        <span>{{ product.item.stock }}</span>
      </div>
    </div>
    <div class="profile__controls">
      <button class="sell" v-if="product.status === 'sold'">
        {{ $t('profile.sold') }}
        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M14.1232 8.88289L13.6001 12.0215C13.5995 12.0251 13.5989 12.0288 13.5982 12.0324C13.5241 12.4399 13.1337 12.7102 12.7262 12.6361L4.19952 11.0858C3.91856 11.0347 3.69099 10.8285 3.61254 10.554L2.24394 5.76387C2.2248 5.69687 2.21509 5.62752 2.21509 5.55783C2.21509 5.14362 2.55087 4.80783 2.96509 4.80783H13.2595L13.7194 2.83687C13.8128 2.43636 14.1699 2.15302 14.5812 2.15302H16.1298C16.6185 2.15302 17.0147 2.54922 17.0147 3.03795C17.0147 3.52669 16.6185 3.92289 16.1298 3.92289H15.2834L14.1232 8.88289Z" fill="white"/>
          <path opacity="0.33" fill-rule="evenodd" clip-rule="evenodd" d="M5.38965 15.75C4.76833 15.75 4.26465 15.2463 4.26465 14.625C4.26465 14.0037 4.76833 13.5 5.38965 13.5C6.01097 13.5 6.51465 14.0037 6.51465 14.625C6.51465 15.2463 6.01097 15.75 5.38965 15.75ZM12.1396 15.75C11.5183 15.75 11.0146 15.2463 11.0146 14.625C11.0146 14.0037 11.5183 13.5 12.1396 13.5C12.761 13.5 13.2646 14.0037 13.2646 14.625C13.2646 15.2463 12.761 15.75 12.1396 15.75Z" fill="white"/>
        </svg>
      </button>
      <button class="withdrawed" v-else-if="product.status === 'withdrawn' || product.status === 'accepted'">
        {{ $t('profile.withdrawed') }}
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.3" d="M1.5 9.75C1.5 9.375 1.875 9 2.25 9C2.625 9 3 9.375 3 9.75C3 10 3 11.25 3 13.5C3 14.3284 3.67157 15 4.5 15H13.5C14.3284 15 15 14.3284 15 13.5V9.75C15 9.33579 15.3358 9 15.75 9C16.1642 9 16.5 9.33579 16.5 9.75V13.5C16.5 15.1569 15.1569 16.5 13.5 16.5H4.5C2.84315 16.5 1.5 15.1569 1.5 13.5C1.5 11.25 1.5 10 1.5 9.75Z" fill="white"/>
          <path opacity="0.3" d="M9.75 2.25C9.75 1.83579 9.41421 1.5 9 1.5C8.58579 1.5 8.25 1.83579 8.25 2.25V11.25C8.25 11.6642 8.58579 12 9 12C9.41421 12 9.75 11.6642 9.75 11.25V2.25Z" fill="white"/>
          <path d="M9.02718 2.53348L5.78033 5.78033C5.48744 6.07322 5.01256 6.07322 4.71967 5.78033C4.42678 5.48744 4.42678 5.01256 4.71967 4.71967L8.46967 0.969671C8.75173 0.68761 9.20523 0.675684 9.50172 0.942531L13.2517 4.31753C13.5596 4.59462 13.5846 5.06884 13.3075 5.37673C13.0304 5.68461 12.5562 5.70957 12.2483 5.43247L9.02718 2.53348Z" fill="white"/>
        </svg>
      </button>
      <button class="upgrade" v-else-if="product.status === 'upgrade'">
        {{ $t('profile.upgrade') }}
      </button>
      <button class="process" v-else-if="product.status === 'withdrawing' || product.status === 'creatingTrade' || product.status === 'waitingAccept'">
        {{ $t('profile.process') }}
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_63_2311)">
            <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M9 15.75C5.68629 15.75 3 13.0637 3 9.75C3 6.43629 5.68629 3.75 9 3.75C12.3137 3.75 15 6.43629 15 9.75C15 13.0637 12.3137 15.75 9 15.75Z" fill="black"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.75 3.79642C9.5043 3.76578 9.254 3.75 9 3.75C8.746 3.75 8.4957 3.76578 8.25 3.79642V3H7.5C7.08579 3 6.75 2.66421 6.75 2.25C6.75 1.83579 7.08579 1.5 7.5 1.5H10.5C10.9142 1.5 11.25 1.83579 11.25 2.25C11.25 2.66421 10.9142 3 10.5 3H9.75V3.79642Z" fill="black"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5325 4.89955L13.1517 4.28034C13.4446 3.98745 13.9195 3.98745 14.2123 4.28034C14.5052 4.57323 14.5052 5.04811 14.2123 5.341L13.6254 5.92796C13.3056 5.54142 12.9383 5.19564 12.5325 4.89955Z" fill="black"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.97234 5.625H9.02781C9.22376 5.625 9.38668 5.77586 9.40171 5.97124L9.71902 10.0962C9.7349 10.3027 9.58038 10.483 9.37388 10.4989C9.36431 10.4996 9.35472 10.5 9.34512 10.5H8.65503C8.44792 10.5 8.28003 10.3321 8.28003 10.125C8.28003 10.1154 8.2804 10.1058 8.28113 10.0962L8.59844 5.97124C8.61347 5.77586 8.77639 5.625 8.97234 5.625Z" fill="black"/>
          </g>
          <defs>
            <clipPath id="clip0_63_2311">
              <rect width="18" height="18" fill="white"/>
            </clipPath>
          </defs>
        </svg>
      </button>
      <template v-else>
        <div v-if="product.item.stock > 0" class="action-btn" @click="withdrawItem(product.id)" v-tooltip="{ content: $t('profile.call_withdraw'), offset: 5 }">
          <BlockLoader v-show="withdrawLoading" />
          <template v-if="!withdrawLoading">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path opacity="0.3" d="M1.5 9.75C1.5 9.375 1.875 9 2.25 9C2.625 9 3 9.375 3 9.75C3 10 3 11.25 3 13.5C3 14.3284 3.67157 15 4.5 15H13.5C14.3284 15 15 14.3284 15 13.5V9.75C15 9.33579 15.3358 9 15.75 9C16.1642 9 16.5 9.33579 16.5 9.75V13.5C16.5 15.1569 15.1569 16.5 13.5 16.5H4.5C2.84315 16.5 1.5 15.1569 1.5 13.5C1.5 11.25 1.5 10 1.5 9.75Z" fill="white"/>
              <path opacity="0.3" d="M9.75 2.25C9.75 1.83579 9.41421 1.5 9 1.5C8.58579 1.5 8.25 1.83579 8.25 2.25V11.25C8.25 11.6642 8.58579 12 9 12C9.41421 12 9.75 11.6642 9.75 11.25V2.25Z" fill="white"/>
              <path d="M9.02718 2.53348L5.78033 5.78033C5.48744 6.07322 5.01256 6.07322 4.71967 5.78033C4.42678 5.48744 4.42678 5.01256 4.71967 4.71967L8.46967 0.969671C8.75173 0.68761 9.20523 0.675684 9.50172 0.942531L13.2517 4.31753C13.5596 4.59462 13.5846 5.06884 13.3075 5.37673C13.0304 5.68461 12.5562 5.70957 12.2483 5.43247L9.02718 2.53348Z" fill="white"/>
            </svg>
          </template>
        </div>
        <div class="action-btn" @click="sellItem(product.id)" v-tooltip="{ content: $t('profile.call_sell'), offset: 5 }">
          <BlockLoader v-show="sellLoading" />
          <template v-if="!sellLoading">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_1_21735)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.6086 8.88289L13.0855 12.0215C13.0849 12.0251 13.0842 12.0288 13.0836 12.0324C13.0095 12.4399 12.619 12.7102 12.2115 12.6361L3.68487 11.0858C3.40392 11.0347 3.17635 10.8285 3.09789 10.554L1.7293 5.76387C1.71015 5.69687 1.70044 5.62752 1.70044 5.55783C1.70044 5.14362 2.03623 4.80783 2.45044 4.80783H12.7448L13.2047 2.83687C13.2982 2.43636 13.6552 2.15302 14.0665 2.15302H15.6152C16.1039 2.15302 16.5001 2.54922 16.5001 3.03795C16.5001 3.52669 16.1039 3.92289 15.6152 3.92289H14.7687L13.6086 8.88289Z" fill="white"/>
                <path opacity="0.33" fill-rule="evenodd" clip-rule="evenodd" d="M4.875 15.75C4.25368 15.75 3.75 15.2463 3.75 14.625C3.75 14.0037 4.25368 13.5 4.875 13.5C5.49632 13.5 6 14.0037 6 14.625C6 15.2463 5.49632 15.75 4.875 15.75ZM11.625 15.75C11.0037 15.75 10.5 15.2463 10.5 14.625C10.5 14.0037 11.0037 13.5 11.625 13.5C12.2463 13.5 12.75 14.0037 12.75 14.625C12.75 15.2463 12.2463 15.75 11.625 15.75Z" fill="white"/>
              </g>
              <defs>
                <clipPath id="clip0_1_21735">
                  <rect width="18" height="18" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          </template>

        </div>
      </template>
    </div>
    <ProductItem :product="getProduct"></ProductItem>
  </div>
</template>
<script>
import BlockLoader from '../../components/BlockLoader'
import ProductItem from '../ProductItem'
import { SELL_ITEMS, WITHDRAW_ITEM } from '../../queries/mutations'
import hexToRgbA from "@/helpers/hexToRgba";
export default {
  data () {
    return {
      withdrawLoading: false,
      sellLoading: false
    }
  },
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  computed: {
    getProduct () {
      return {...this.product, name: this.product.item.name, color: this.product.item.color, getImage: this.product.item.getImage}
    }
  },
  methods: {
    hexToRgbA,
    async withdrawItem (openId) {
      this.withdrawLoading = true

      await this.$apollo.mutate({
        mutation: WITHDRAW_ITEM,
        variables: {
          openId
        }
      }).then((data) => {
        this.product.withdraw = data.data.withdraw
        this.product.status = 'withdrawing'
      }).catch((error) => {
        error.graphQLErrors.map(item => {
          this.$toast.error(item.message);
        })

      }).finally(() => {
        this.withdrawLoading = false
      })
    },
    async sellItem (openId) {
      this.sellLoading = true

      await this.$apollo.mutate({
        mutation: SELL_ITEMS,
        variables: {
          openIds: [openId],
        },
      }).then((data) => {
        this.$toast.success(this.$t('open.success_sell', {amount: data.data.inventoryItemSell[0].price}));
        this.product.status = 'sold'
      }).catch((error) => {

        error.graphQLErrors.map(item => {
          this.$toast.error(item.message);
        })

      }).finally(() => {
        this.sellLoading = false
      })

    }
  },
  mounted () {
    this.$root.$on('updateInventoryStatus', (item) => {
      if (item.id === this.product.id) {
        this.product.status = item.status
        if (item.status === 'buyError') {
          this.$toast.success(this.$t('open.success_sell', { amount: 123 }));
        }
      }
    })
  },
  components: {
    BlockLoader,
    ProductItem
  }
}
</script>
<style scoped lang="scss">
.profile__item {
  position: relative;
  .profile__controls {
    position: absolute;
    right: 12px;
    z-index: 2;
    top: 10px;
    display: flex;
    .action-btn {
      background: rgba(255, 255, 255, 0.05);
      //backdrop-filter: blur(22.5px);
      width: 30px;
      cursor: pointer;
      height: 30px;
      display: flex;
      transition: 0.2s;
      position: relative;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      &:hover {
        opacity: 0.8;
      }
      &.error {
        background: #F44343;
      }
    }
    .action-btn + .action-btn {
      margin-left: 5px;
    }
    .sell {
      display: flex;
      align-items: center;
      background: #FE5E3B;
      //backdrop-filter: blur(22.5px);
      border-radius: 4px;
      padding: 6px;
      cursor: default;
      font-weight: 600;
      font-size: 10px;
      line-height: 16px;
      &:hover {
        opacity: 1;
      }
      svg {
        margin-left: 7.5px;
      }
    }
    .withdrawed {
      display: flex;
      align-items: center;
      background: #66BF57;
      backdrop-filter: blur(22.5px);
      border-radius: 4px;
      padding: 6px;
      font-weight: 600;
      cursor: default;
      font-size: 10px;
      line-height: 16px;
      &:hover {
        opacity: 1;
      }
      svg {
        margin-left: 7.5px;
      }
    }
    .upgrade {
      display: flex;
      align-items: center;
      background: #FE5E3B;
      backdrop-filter: blur(22.5px);
      border-radius: 4px;
      padding: 6px;
      font-weight: 600;
      cursor: default;
      font-size: 10px;
      line-height: 16px;
      &:hover {
        opacity: 1;
      }
      svg {
        margin-left: 7.5px;
      }
    }
    .process {
      display: flex;
      align-items: center;
      background: #CDA12F;
      backdrop-filter: blur(22.5px);
      border-radius: 4px;
      padding: 6px;
      font-weight: 600;
      font-size: 10px;
      line-height: 16px;
      color: #000000;
      cursor: default;
      &:hover {
        opacity: 1;
      }
      svg {
        margin-left: 7.5px;
      }
    }
  }
  .profile__stock {
    position: absolute;
    left: 12px;
    top: 13px;
    background-color: #5D687C;
    border: none;
    border-radius: 16px;
    display: flex;
    align-items: center;
    z-index: 2;
    cursor: default;
    .profile__stock-icon {
      margin-right: 4px;
      width: 12px;
      height: 12px;
      display: flex;
    }
    .profile__stock-text {
      display: flex;
      align-items: center;
      font-size: 12px;
      line-height: 15px;
      font-weight: 600;
    }
    .profile__stock-text > * {
      display: block;
      margin-right: 2px;
    }
    .profile__stock-text > *:last-child {
      margin-right: 0;
    }
  }
}
::v-deep {
  .product__item {
    width: auto;
    min-width: auto;
  }
}
</style>
