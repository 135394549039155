<template>
  <div class="profile__tabs">
    <div class="tabs">
      <div class="tab" @click="setTab(INVENTORY_TAB)" :class="{'active': activeTab === INVENTORY_TAB}">{{ $t('profile.inventory') }}</div>
      <div class="tab" @click="setTab(HISTORY_WITHDRAW_TAB)" :class="{'active': activeTab === HISTORY_WITHDRAW_TAB}">{{ $t('profile.history') }}</div>
    </div>
    <div class="sell">
      <transition name="fade">
        <button v-if="activeTab === INVENTORY_TAB" @click="sellAll" :disabled="isLoading">
          <BlockLoader v-if="isLoading"></BlockLoader>
          {{ $t('profile.sell_all') }}
        </button>
      </transition>
    </div>
  </div>
</template>
<script>
import { INVENTORY_TAB, HISTORY_WITHDRAW_TAB } from '../../const/PROFILE_TABS'
import { mapGetters, mapActions } from 'vuex'
import { SELL_ITEMS } from '../../queries/mutations'
import BlockLoader from '../BlockLoader'
export default {
  data () {
    return {
      isLoading: false,
      HISTORY_WITHDRAW_TAB,
      INVENTORY_TAB
    }
  },
  computed: {
    ...mapGetters({
      inventory: 'profile/inventory',
      activeTab: 'profile/activeTab'
    })
  },
  methods: {
    async sellAll () {
      this.isLoading = true

      await this.$apollo.mutate({
        mutation: SELL_ITEMS,
      }).then((data) => {
        let sum = 0
        data.data.inventoryItemSell.forEach(item => {
          this.$root.$emit('updateInventoryStatus', {
            id: item.id,
            status: 'sold'
          })
          sum += parseFloat(item.price)
        })
        this.$toast.success(this.$t('open.sell_many', {count: data.data.inventoryItemSell.length}) + ` ${parseFloat(sum).toFixed(2)} ₽`);
      }).catch((error) => {
        error.graphQLErrors.map(item => {
          this.$toast.error(item.message);
        })
      }).finally(() => {
        this.isLoading = false
      })
    },
    ...mapActions({
      setTab: 'profile/setTab'
    })
  },
  components: {
    BlockLoader
  }
}
</script>
<style scoped lang="scss">
.profile__tabs {
  display: flex;
  justify-content: space-between;
  background: #1D1E23;
  padding: 0 20px;
  align-items: center;
  margin: 15px 0;
  @media (max-width: 575px) {
    display: flex;
    flex-wrap: wrap;
  }
  .tabs {
    display: flex;
    @media (max-width: 575px) {
      width: 100%;
      order: 1;
    }
    .tab {
      padding: 20px 0;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      letter-spacing: 0.03em;
      text-transform: uppercase;
      color: #676974;
      border-bottom: 2px solid transparent;
      transition: 0.2s;
      cursor: pointer;
      &.active, &:hover {
        color: #FFFFFF;
        border-color: #FE5E3B
      }
      @media (max-width: 450px) {
        font-size: 12px;
      }
    }
    .tab + .tab {
      margin-left: 60px;
      @media (max-width: 450px) {
        margin-left: 30px;
      }
    }
  }
  .sell {
    @media (max-width: 575px) {
      width: 100%;
    }
    button {
      font-weight: 700;
      position: relative;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      letter-spacing: 0.05em;
      &[disabled] {
        color: #868894;
      }
      padding: 13.5px 21.5px;
      border-radius: 4px;
      color: #fff;
      background: #66BF57;
      @media (max-width: 575px) {
        margin-top: 15px;
        margin-bottom: 10px;
        width: 100%;
      }
    }
  }
}
</style>
