<template>
  <div class="store__item">
    <div class="store__item-img">
      <img :src="`${item.getImage}/85fx85f`" alt="">
    </div>
    <div class="store__item-info">
      <strong>{{ item.name }}</strong>
    </div>
    <div class="store__item-separator"></div>
    <div class="store__item-indicators">
      <div class="store__item-indicator">
        <div class="x-es">
          {{ item.stock }}X
        </div>
      </div>
      <div class="store__item-indicator">
        <div class="price">
          <em>₽</em> {{ parseFloat(item.price).toFixed(2) }}
        </div>
      </div>
      <div class="store__item-indicator" v-if="$root.isAuth" @click="buy">
        <button :disabled="loading || !$root.isAuth || (user && !user.tradeLink) || (user && parseFloat(user.balance) < parseFloat(item.price))">
          <BlockLoader v-if="loading"></BlockLoader>
          <img v-else src="/img/buy.svg" alt="">
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import BlockLoader from '../BlockLoader'
import { BUY_ITEM } from '../../queries/mutations'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      loading: false
    }
  },
  props: {
    item: {
      type: Object,
      required: true
    },
  },
  methods: {
    async buy () {
      if (!this.$root.isAuth || (this.user && !this.user.tradeLink) || (this.user && parseFloat(this.user.balance) < parseFloat(this.item.price)) || this.loading) return;
      this.loading = true
      await this.$apollo.mutate({
        mutation: BUY_ITEM,
        variables: {
          itemId: this.item.id
        },
      }).then(() => {
        this.$toast.success(this.$t('store.sent'));
      }).catch((error) => {
        error.graphQLErrors.map(item => {
          this.$toast.error(item.message);
        })
      }).finally(() => {
        setTimeout(() => {
          this.loading = false
        }, 500)
      })
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    })
  },
  components: {
    BlockLoader
  }
}
</script>
<style scoped lang="scss">
.store__item {
  background: #1F2025;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: 15px;
  .store__item-img {
    background: #131419;
    border-radius: 6px;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    img {
      max-height: 85px;
    }
  }
  .store__item-separator {
    height: 1px;
    width: 100%;
    background: #2D2E35;
    border-radius: 6px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .store__item-info {
    flex-grow: 1;
    overflow: hidden;
    span {
      display: block;
      width: 100%;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      color: #A7EC2E;
      margin-bottom: 5px;

    }
    strong {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      @media (max-width: 575px) {
        font-size: 12px;
      }
    }
  }
  .store__item-indicators {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .store__item-indicator {
      position: relative;
      button {
        background: #599B4E;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        min-width: 30px;
        &[disabled] {
          cursor: not-allowed;
          background: #2A2C32;
        }
        img {
          min-width: 18px;
        }
      }
      .x-es {
        padding: 6px 8px;
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        letter-spacing: 0.05em;
        background: #2A2C32;
        border-radius: 4px;
        color: #606470;
      }
      .price {

        white-space: nowrap;
        em {
          color: #FE5E3B;
          font-weight: 700;
          font-size: 12px;
          line-height: 15px;
          font-style: normal;
        }
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        letter-spacing: 0.05em;
        color: #fff;
      }
      &:last-child {
        margin-left: 5px;
      }
    }
    @media (max-width: 450px) {
      flex-wrap: wrap;
      .store__item-indicator {
        width: 100%;
        .price {
          margin-top: 10px;
          white-space: nowrap;
        }
        button {
          width: 100%;
          margin-top: 10px;
        }
      }

    }
  }
}
</style>
