<template>
  <div class="unavailable-block">
    <p v-html="$t('open.need_auth')"></p>
    <button @click="login">{{ $t('open.login') }}</button>
  </div>
</template>
<script>
import { LOGIN_URI } from '../../const/ENDPOINTS'
export default {
  methods: {
    login () {
      window.location.href = LOGIN_URI
    }
  }
}
</script>
<style scoped lang="scss">
.unavailable-block {
  button {
    background: #FE5E3B;
  }
}
</style>
