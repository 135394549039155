<template>
  <div class="no_opened_cases">
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_76_23225)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.734 13.3333H30.2664C32.0001 13.3333 33.4443 14.6621 33.5883 16.3898L34.6994 29.7231C34.8523 31.5577 33.489 33.1689 31.6544 33.3217C31.5623 33.3294 31.4699 33.3333 31.3776 33.3333H8.62288C6.78194 33.3333 5.28955 31.8409 5.28955 29.9999C5.28955 29.9075 5.29339 29.8152 5.30107 29.7231L6.41218 16.3898C6.55615 14.6621 8.00037 13.3333 9.734 13.3333ZM17.5002 16.6666C17.04 16.6666 16.6669 17.0397 16.6669 17.4999V19.1666C16.6669 19.6268 17.04 19.9999 17.5002 19.9999H22.5002C22.9605 19.9999 23.3336 19.6268 23.3336 19.1666V17.4999C23.3336 17.0397 22.9605 16.6666 22.5002 16.6666H17.5002Z" fill="#676974"/>
        <path opacity="0.3" d="M16.6668 13.3334H13.3335V11.6667C13.3335 8.90532 15.5721 6.66675 18.3335 6.66675H21.6668C24.4283 6.66675 26.6668 8.90532 26.6668 11.6667V13.3334H23.3335V11.6667C23.3335 10.7463 22.5873 10.0001 21.6668 10.0001H18.3335C17.413 10.0001 16.6668 10.7463 16.6668 11.6667V13.3334Z" fill="#676974"/>
      </g>
      <defs>
        <clipPath id="clip0_76_23225">
          <rect width="40" height="40" fill="white"/>
        </clipPath>
      </defs>
    </svg>
    <p v-html="html"></p>
    <router-link v-if="!pub" to="/">
      <button>{{ $t('profile.open') }}</button>
    </router-link>
  </div>
</template>
<script>
export default {
  props: {
    pub: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    html () {
      if (this.pub) {
        return this.$t('public.empty_open')
      }

      return this.$t('profile.empty_open')
    }
  }
}
</script>
<style scoped lang="scss">
.no_opened_cases {
  padding-top: 15px;
  text-align: center;
  p {
    margin-top: 6px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #676974;
    margin-bottom: 11px;
  }
  button {
    padding: 15px 44px;
    background: rgba(103, 105, 116, 0.15);
    border-radius: 8px;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    text-transform: uppercase;
    color: #676974;
  }
}
</style>
