<template>
  <div class="profile__inventory">
    <EmptyInventory v-if="!inventory || !inventory.items.length"></EmptyInventory>
    <div class="profile__inventory__items">
      <template v-if="inventory && inventory.items.length > 0">
        <PublicProfileInventoryItem :product="product" v-for="product in inventory.items" :key="product.id"></PublicProfileInventoryItem>
      </template>
    </div>

    <button class="load-more" v-if="showMoreEnabled" @click="showMore">
      {{ $t('profile.load_more') }}
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 14C6.10457 14 7 13.1046 7 12C7 10.8954 6.10457 10 5 10C3.89543 10 3 10.8954 3 12C3 13.1046 3.89543 14 5 14Z" fill="#83848E"/>
        <path d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z" fill="#83848E"/>
        <path d="M19 14C20.1046 14 21 13.1046 21 12C21 10.8954 20.1046 10 19 10C17.8954 10 17 10.8954 17 12C17 13.1046 17.8954 14 19 14Z" fill="#83848E"/>
      </svg>
    </button>
  </div>
</template>
<script>
import { GET_INVENTORY } from '../../queries/index'
import EmptyInventory from '../EmptyInventory'
import PublicProfileInventoryItem from '../PublicProfileInventoryItem'
const TAKE = 20;
export default {
  data () {
    return {
      page: 0,
    }
  },
  computed: {
    showMoreEnabled () {
      if (!this.inventory) {
        return false;
      }
      return this.inventory.total > this.inventory.items.length
    }
  },
  apollo: {
    inventory: {
      fetchPolicy: 'network-only',
      query: GET_INVENTORY,
      variables () {
        return {
          steamId: this.$route.params.id,
          take: TAKE,
        }
      },
      result: data => data.inventory
    },
  },
  methods: {
    async showMore () {
      this.page++
      // Fetch more data and transform the original result
      await this.$apollo.queries.inventory.fetchMore({
        // New variables
        variables: {
          steamId: this.$route.params.id,
          take: TAKE,
          skip: TAKE * this.page
        },
        // Transform the previous result with new data
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const inventory = fetchMoreResult.inventory.items

          return {
            inventory: {
              __typename: previousResult.inventory.__typename,
              // Merging the tag list
              items: [...previousResult.inventory.items, ...inventory],
              total: fetchMoreResult.total,
            },
          }
        },
      })
    },
  },
  components: {
    PublicProfileInventoryItem,
    EmptyInventory
  }
}
</script>
<style scoped lang="scss">
.profile__inventory {
  padding-bottom: 20px;
  .profile__inventory__items {
    flex-wrap: wrap;
    display: grid;
    justify-content: center;
    grid-gap: 15px;
    margin: 0 auto;
    grid-template-columns: repeat(6, 1fr);
    @media (max-width: 1500px) {
      grid-template-columns: repeat(5, 1fr);
    }
    @media (max-width: 1300px) {
      grid-template-columns: repeat(4, 1fr);
    }
    @media (max-width: 1200px) {
      grid-template-columns: repeat(5, 1fr);
    }
    @media (max-width: 992px) {
      grid-template-columns: repeat(4, 1fr);
    }
    @media (max-width: 750px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 575px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
.load-more {
  display: flex;
  background: #27282F;
  border-radius: 6px;
  margin: 0 auto;
  margin-top: 20px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 15px 36px;
  svg {
    margin-left: 8px;
  }
  color: #83858E;
}
</style>

