<template>
  <div class="profile__inventory">
    <div class="profile__inventory-list" v-if="inventory">
      <ProfileInventoryItem v-for="(product, i) in inventory.items" :key="i" :product="product"></ProfileInventoryItem>
    </div>

    <EmptyInventory v-if="inventory && !inventory.items.length"></EmptyInventory>
    <button class="load-more" v-if="showMoreEnabled" @click="showMore">
      {{ $t('profile.load_more') }}
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 14C6.10457 14 7 13.1046 7 12C7 10.8954 6.10457 10 5 10C3.89543 10 3 10.8954 3 12C3 13.1046 3.89543 14 5 14Z" fill="#83848E"/>
        <path d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z" fill="#83848E"/>
        <path d="M19 14C20.1046 14 21 13.1046 21 12C21 10.8954 20.1046 10 19 10C17.8954 10 17 10.8954 17 12C17 13.1046 17.8954 14 19 14Z" fill="#83848E"/>
      </svg>
    </button>
  </div>
</template>
<script>
import ProfileInventoryItem from './ProfileInventoryItem'
import { mapGetters } from 'vuex'
import { GET_INVENTORY } from '../../queries/index'
import { WITHDRAW_UPDATES } from '../../queries/subscriptions'
const TAKE = 20;
import EmptyInventory from '../EmptyInventory'
export default {
  data () {
    return {
      page: 0,
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    showMoreEnabled () {
      if (!this.inventory) {
        return false;
      }
      return this.inventory.total > this.inventory.items.length
    }
  },
  apollo: {
    $subscribe: {
      WithdrawUpdatesStream: {
        query: WITHDRAW_UPDATES,
        result ({ data: { WithdrawUpdatesStream } }) {
          switch (WithdrawUpdatesStream.status) {
            case 'waitingAccept':
              this.$toast.success(this.$t('open.waitingAccept'));
              break
            case 'buyError':
              this.$toast.error(this.$t('open.buyError'));
              break
            case 'tradeCreateError':
              this.$toast.error(this.$t('open.tradeCreateError'));
              break
            default:
              break
          }
          this.updateStatusWithdraw(WithdrawUpdatesStream)
        },
      }
    },
    inventory: {
      fetchPolicy: 'network-only',
      query: GET_INVENTORY,
      variables () {
        return {
          steamId: this.user.steamId,
          take: TAKE,
        }
      },
      result: data => data.inventory
    }
  },
  methods: {
    async updateStatusWithdraw (withdrawUpdate) {
      await this.$nextTick()
      if (!withdrawUpdate || !withdrawUpdate.id) return;

      const findProduct = this.inventory.items.findIndex(item => item.withdraw && item.withdraw.id === withdrawUpdate.id)
      if (findProduct > -1) {
        this.inventory.items[findProduct].status = withdrawUpdate.status
      }
    },
    async showMore () {
      this.page++
      // Fetch more data and transform the original result
      await this.$apollo.queries.inventory.fetchMore({
        // New variables
        variables: {
          steamId: this.user.steamId,
          take: TAKE,
          skip: TAKE * this.page
        },
        // Transform the previous result with new data
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const inventory = fetchMoreResult.inventory.items

          return {
            inventory: {
              __typename: previousResult.inventory.__typename,
              // Merging the tag list
              items: [...previousResult.inventory.items, ...inventory],
              total: fetchMoreResult.total,
            },
          }
        },
      })
    },
  },
  components: {
    EmptyInventory,
    ProfileInventoryItem
  }
}
</script>
<style scoped lang="scss">
.profile__inventory {
  padding-bottom: 20px;
  .profile__inventory-list {
    flex-wrap: wrap;
    display: grid;
    justify-content: center;
    grid-gap: 15px;
    margin: 0 auto;
    grid-template-columns: repeat(6, 1fr);
    @media (max-width: 1500px) {
      grid-template-columns: repeat(5, 1fr);
    }
    @media (max-width: 1300px) {
      grid-template-columns: repeat(4, 1fr);
    }
    @media (max-width: 1200px) {
      grid-template-columns: repeat(5, 1fr);
    }
    @media (max-width: 992px) {
      grid-template-columns: repeat(4, 1fr);
    }
    @media (max-width: 750px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 575px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 350px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  .load-more {
    display: flex;
    background: #27282F;
    border-radius: 6px;
    margin: 0 auto;
    margin-top: 20px;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 15px 36px;
    svg {
      margin-left: 8px;
    }
    color: #83858E;
  }
  .profile__inventory-empty {
    margin-top: 6px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #676974;
    margin-bottom: 11px;
  }
}
</style>
