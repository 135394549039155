<template>
  <div class="upgrade__inventory__wrapper" :class="{'disabled': disabled}">
    <InventoryWrapper
        v-if="inventory"
        :page="page"
        :total="inventory.total"
        @next="showMore"
        :loading="$apollo.loading"
    >
      <InventorySelectItem v-slot v-for="(product, i) in inventory.items" :key="i" :product="product"></InventorySelectItem>
    </InventoryWrapper>
  </div>
</template>
<script>
import InventoryWrapper from './InventoryWrapper'
import { mapGetters, mapActions } from 'vuex'
import InventorySelectItem from './InventorySelectItem'
import { GET_INVENTORY, /*GET_PROCESS_INVENTORY*/ } from '../../queries/index'

export default {
  data () {
    return {
      page: 0
    }
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  apollo: {
    inventory: {
      fetchPolicy: 'network-only',
      // query: GET_PROCESS_INVENTORY,
      query: GET_INVENTORY,
      variables () {
        return {
          take: 15,
          priceSort: this.inventoryFilters.priceSort,
          priceFrom: parseFloat(this.minPriceFrom),
          priceTo: parseFloat(this.inventoryFilters.priceTo),
          status: 'process',
          steamId: this.user.steamId
        }
      },
      result(data) {
        this.deleteInventory()
        return data.inventory
      }
    }
  },
  watch: {
    'inventoryFilters.priceSort': function () {
      this.deleteInventory()
      this.page = 0
    },
    'inventoryFilters.priceFrom': function () {
      this.page = 0
    },
    'inventoryFilters.priceTo': function () {
      this.page = 0
    },
    betType () {
      this.deleteInventory()
    },
    page () {
      this.deleteInventory()
    },
    status () {
      if (this.status === 'finish') {
        this.page = 0;
        this.$apollo.queries.inventory.refetch()
      }
    }
  },
  methods: {
    ...mapActions({
      deleteInventory: 'upgrade/deleteInventory'
    }),
    async showMore (page) {
      this.page = page
      // Fetch more data and transform the original result
      await this.$apollo.queries.inventory.fetchMore({
        // New variables
        variables: {
          take: 15,
          skip: 15 * this.page,
          priceSort: this.inventoryFilters.priceSort,
          priceFrom: parseFloat(this.minPriceFrom),
          priceTo: parseFloat(this.inventoryFilters.priceTo),
        },
        // Transform the previous result with new data
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const inventory = fetchMoreResult.inventory.items
          return {
            inventory: {
              __typename: previousResult.inventory.__typename,
              // Merging the tag list
              items: inventory,
              total: fetchMoreResult.total,
            },
          }
        },
      })
    },
  },
  computed: {
    ...mapGetters({
      status: 'upgrade/status',
      inventoryFilters: 'upgrade/inventoryFilters',
      config: 'main/config',
      user: 'auth/user'
    }),
    minPriceFrom() {
      return this.inventoryFilters.priceFrom < this.config.getUpgradeConfig.upgradeMinSum ?
          this.config.getUpgradeConfig.upgradeMinSum :
          this.inventoryFilters.priceFrom
    }
  },
  components: {
    InventorySelectItem,
    InventoryWrapper
  }
}
</script>
<style scoped lang="scss">
.upgrade__inventory__wrapper {
  transition: 0.2s;
  &.disabled {
    filter: blur(1px);
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      content: "";
      display: block;
      opacity: 0.6;
      background: rgba(19, 20, 25, 1);

      z-index: 999;
    }
  }
}
</style>
