<template>
  <div class="tabs__content">
    <ProfileInventory v-if="activeTab === INVENTORY_TAB"></ProfileInventory>
    <ProfileWithdrawHistory v-if="activeTab === HISTORY_WITHDRAW_TAB"></ProfileWithdrawHistory>
  </div>
</template>
<script>
import ProfileInventory from './ProfileInventory'
import ProfileWithdrawHistory from './ProfileWithdrawHistory'
import { INVENTORY_TAB, HISTORY_WITHDRAW_TAB } from '../../const/PROFILE_TABS'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      HISTORY_WITHDRAW_TAB,
      INVENTORY_TAB
    }
  },
  computed: {
    ...mapGetters({
      activeTab: 'profile/activeTab'
    })
  },
  components: {
    ProfileInventory,
    ProfileWithdrawHistory
  }
}
</script>
<style scoped lang="scss">
@media (max-width: 600px) {
  .tabs__content {
    overflow-y: auto;
  }
}
</style>
