<template>
  <button class="blocked-button" :class="{ 'blocked-button--blocked': isBlocked(caseItem) }" @click="$emit('start')">
    <div class="blocked-button__icon" v-if="isBlocked(caseItem)">
      <svg v-if="isBlockedByTime(caseItem.nextOpen)" width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.4" d="M8.00065 15.1667C11.6827 15.1667 14.6673 12.182 14.6673 8.50004C14.6673 4.81804 11.6827 1.83337 8.00065 1.83337C4.31865 1.83337 1.33398 4.81804 1.33398 8.50004C1.33398 12.182 4.31865 15.1667 8.00065 15.1667Z" fill="#8E8F9A"/>
        <path d="M10.4731 11.1214C10.3832 11.123 10.2949 11.0974 10.2198 11.0481L8.1531 9.81472C7.63977 9.50806 7.25977 8.83472 7.25977 8.24139V5.50806C7.25977 5.23472 7.48643 5.00806 7.75977 5.00806C8.0331 5.00806 8.25977 5.23472 8.25977 5.50806V8.24139C8.25977 8.48139 8.45977 8.83472 8.66643 8.95472L10.7331 10.1881C10.9731 10.3281 11.0464 10.6347 10.9064 10.8747C10.8611 10.9493 10.7975 11.011 10.7217 11.0542C10.6459 11.0974 10.5603 11.1205 10.4731 11.1214Z" fill="#8E8F9A"/>
      </svg>

      <svg v-else width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.4" d="M11.334 5.66671C11.2073 5.66671 11.0807 5.62004 10.9806 5.52004C10.8877 5.42595 10.8355 5.29899 10.8355 5.16671C10.8355 5.03442 10.8877 4.90747 10.9806 4.81337L12.3473 3.44671C11.174 2.44671 9.66065 1.83337 8.00065 1.83337C4.32065 1.83337 1.33398 4.82004 1.33398 8.50004C1.33398 12.18 4.32065 15.1667 8.00065 15.1667C11.6807 15.1667 14.6673 12.18 14.6673 8.50004C14.6673 6.84004 14.054 5.32671 13.054 4.15337L11.6873 5.52004C11.5873 5.62004 11.4607 5.66671 11.334 5.66671Z" fill="#8E8F9A"/>
        <path d="M9.16732 8.37999L8.50065 8.14665V6.66665H8.55398C8.89398 6.66665 9.16732 6.96665 9.16732 7.33332C9.16732 7.60665 9.39398 7.83332 9.66732 7.83332C9.94065 7.83332 10.1673 7.60665 10.1673 7.33332C10.1673 6.41332 9.44732 5.66665 8.55398 5.66665H8.50065V5.49999C8.50065 5.22665 8.27398 4.99999 8.00065 4.99999C7.72732 4.99999 7.50065 5.22665 7.50065 5.49999V5.66665H7.30065C6.49398 5.66665 5.83398 6.34665 5.83398 7.18665C5.83398 8.15999 6.40065 8.47332 6.83398 8.62665L7.50065 8.85999V10.34H7.44732C7.10732 10.34 6.83398 10.04 6.83398 9.67332C6.83398 9.39999 6.60732 9.17332 6.33398 9.17332C6.06065 9.17332 5.83398 9.39999 5.83398 9.67332C5.83398 10.5933 6.55398 11.34 7.44732 11.34H7.50065V11.5067C7.50065 11.78 7.72732 12.0067 8.00065 12.0067C8.27398 12.0067 8.50065 11.78 8.50065 11.5067V11.34H8.70065C9.50732 11.34 10.1673 10.66 10.1673 9.81999C10.1673 8.83999 9.60065 8.52665 9.16732 8.37999ZM7.16065 7.67332C6.93398 7.59332 6.83398 7.54665 6.83398 7.17999C6.83398 6.89332 7.04732 6.65999 7.30065 6.65999H7.50065V7.78665L7.16065 7.67332ZM8.70065 10.3333H8.50065V9.20665L8.84065 9.32665C9.06732 9.40665 9.16732 9.45332 9.16732 9.81999C9.16732 10.1 8.95398 10.3333 8.70065 10.3333ZM15.126 1.63865C15.0733 1.51588 14.9754 1.41804 14.8527 1.36532C14.7917 1.33868 14.7258 1.32506 14.6593 1.32532H11.9927C11.7193 1.32532 11.4927 1.55199 11.4927 1.82532C11.4927 2.09865 11.7193 2.32532 11.9927 2.32532H13.4527L12.3393 3.43865C12.5927 3.65865 12.826 3.89199 13.046 4.14532L14.1593 3.03199V4.49865C14.1593 4.77199 14.386 4.99865 14.6593 4.99865C14.9327 4.99865 15.1593 4.77199 15.1593 4.49865V1.83199C15.166 1.76532 15.1527 1.70532 15.126 1.63865Z" fill="#8E8F9A"/>
      </svg>
    </div>

    <div class="blocked-button__text">
      <span v-if="!isBlocked(caseItem)">Открыть</span>
      <template v-else-if="caseItem.needDeposited && caseItem.needDeposited > 0">
        <span>{{ $t('header.left') }}</span>
        <span>{{ caseItem.needDeposited }}</span>
        <span>₽</span>
      </template>
      <template v-else-if="caseItem.needPromocode">
        <span>{{ $t('header.promocode') }}</span>
      </template>
      <template v-else-if="isBlockedByTime(caseItem.nextOpen)">
        <span>{{ $t('header.after') }}</span>
        <template v-if="convertTime(caseItem.nextOpen).days > 0">
          <span>{{ convertTime(caseItem.nextOpen).days }}</span>
          <span>{{ $t('header.days') }}</span>
        </template>
        <template v-if="convertTime(caseItem.nextOpen).hours < 24 && convertTime(caseItem.nextOpen).minutes > 60">
          <span>{{ convertTime(caseItem.nextOpen).hours }}</span>
          <span>{{ $t('header.hours') }}</span>
        </template>
        <template v-if="convertTime(caseItem.nextOpen).minutes <= 60 && convertTime(caseItem.nextOpen).seconds > 60">
          <span>{{ convertTime(caseItem.nextOpen).minutes }}</span>
          <span>{{ $t('header.minutes') }}</span>
        </template>
        <template v-if="convertTime(caseItem.nextOpen).seconds <= 60">
          <span>{{ convertTime(caseItem.nextOpen).seconds }}</span>
          <span>{{ $t('header.seconds') }}</span>
        </template>
      </template>
    </div>
  </button>
</template>
<script>
export default {
  name: 'OpenEventButton',
  props: {
    caseItem: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    timer: null,
  }),
  computed: {
    isBlocked() {
      return (caseItem) =>
          (caseItem.needDeposited && caseItem.needDeposited > 0) ||
          caseItem.needPromocode ||
          this.isBlockedByTime(caseItem.nextOpen)
    },
    convertTime() {
      return (timestamp) => {
        const nextOpenDate = new Date(timestamp);
        const nowDate = new Date();
        const timeDiff = Math.abs(nowDate.getTime() - nextOpenDate.getTime());

        const days = this.convertMsToDays(timeDiff)
        const hours = this.convertMsToHours(timeDiff)
        const minutes = this.convertMsToMinutes(timeDiff)
        const seconds = this.convertMsToSeconds(timeDiff)

        return {
          days,
          hours,
          minutes,
          seconds,
        }
      }
    },
    isBlockedByTime: () => (nextOpen) => nextOpen ? Date.parse(nextOpen) > Date.now() : false,
    convertMsToDays: () => (ms) => Math.floor(ms / (1000 * 60 * 60 * 24)),
    convertMsToHours: () => (ms) => Math.floor(ms / (1000 * 60 * 60)),
    convertMsToMinutes: () => (ms) => Math.floor(ms / (1000 * 60)),
    convertMsToSeconds: () => (ms) => Math.floor(ms / 1000),
  },
  mounted() {
    this.updateCasesTimer()
  },
  methods: {
    updateCasesTimer() {
      this.timer = setInterval(() => {
        if (!this.isBlockedByTime(this.caseItem.nextOpen)) {
          this.clearTimer()
        }
        this.$forceUpdate()
      }, 1000)
    },
    clearTimer() {
      clearInterval(this.timer)
      this.timer = null
    }
  },
  destroyed() {
    this.clearTimer()
  },
  updated() {
  }
}
</script>
<style scoped lang="scss">
.blocked-button {
  border-radius: 24px;
  padding: 10px;
  font-size: 12px;
  font-weight: 700;
  line-height: 14.5px;
  background-color: #66BF57;
  display: flex;
  align-items: center;
  max-height: 35px;

  .blocked-button__icon {
    margin-right: 5px;

    svg {
      path {
        transition: 0.35s;
      }
    }
  }

  .blocked-button__text {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    line-height: 14.5px;

    span {
      transition: 0.35s;
    }

    & > span {
      margin-left: 4px;
      text-transform: uppercase;
      transition: 0.35s;
    }

    & > span:first-child {
      margin-left: 0;
    }
  }
}

.blocked-button--blocked {
  background-color: #1F2025;

  .blocked-button__text > span {
    color: #8E8F9A;
  }

  &:hover {
    .blocked-button__icon {
      svg {
        path {
          fill: #fff;
        }
      }
    }

    .blocked-button__text {
      span {
        color: #fff;
      }
    }
  }
}

.blocked-button:not(.blocked-button--blocked):hover {
  background-color: #599B4E;
}
</style>
