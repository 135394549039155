<template>
  <div class="profile__item">
    <ProductItem :product="getProduct"></ProductItem>
  </div>
</template>
<script>
import ProductItem from './ProductItem'
export default {
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  computed: {
    getProduct () {
      return {...this.product, name: this.product.item.name, color: this.product.item.color, getImage: this.product.item.getImage}
    }
  },
  components: {
    ProductItem
  }
}
</script>
<style scoped lang="scss">
::v-deep {
  .product__item {
    width: auto;
    min-width: auto;
  }
}
</style>
