import gql from 'graphql-tag'
export const GET_MY_PROFILE = gql`
  query account {
      account {
          steamId
          name
          balance
          avatar
          tradeLink
          isAdmin
          chatMuted
          isBanned
          isModerator
          notifications {
              id
              type
              createdAt
              tradeLink
              text {
                  en
                  ru
              }
              title {
                  en
                  ru
              }
          }
      }
  }
`

export const GET_WITHDRAW_STATISTICS = gql`
    query withdrawCount {
        withdrawCount
    }
`
export const GET_OPEN_STATISTICS = gql`
    query openCount {
        openCount
    }
`
export const GET_USERS_STATISTICS = gql`
    query userCount {
        userCount
    }
`
export const GET_UPGRADE_STATISTICS = gql`
    query upgradeCount {
        upgradeCount
    }
`

export const GET_CATEGORIES = gql`
    query categories {
        categories {
            id
            position
            name {ru, en}
            background
            cases {
                color
                slug
                getImage
                price
                name {ru, en}
            }
        }
    }
`

export const GET_CHAT_MESSAGES = gql`
    query chatMessages {
        chatMessages {
            id
            chatLang
            text
            createdAt
            user {
                steamId
                name
                avatar
                chatMuted
                isBanned
            }
        }
    }
`


export const GET_USER_ONLINE = gql`
    query usersOnline {
        usersOnline
    }
`

export const GET_HIGHT_LIVE_DROP = gql`
    query lastHighPriceOpens {
        lastHighPriceOpens {
            id
            price
            createdAt
            item {
                name
                getImage
                color
            }
            case {
                color
                slug
                image
                price
                name {
                    en
                    ru
                }
            }
            user {
                steamId
                name
                avatar
            }
        }
    }
`


export const GET_LIVE_DROP = gql`
    query lastOpens {
        lastOpens {
            id
            price
            createdAt
            item {
                name
                getImage
                color
            }
            case {
                color
                slug
                image
                price
                name {ru, en}
            }
            user {
                avatar
                name
                steamId
            }
        }
    }
`

export const GET_EVENT_BG = gql`
    query eventBackground {
        eventBackground
    }
`


export const GET_CASE = gql`
    query case ($slug: String!) {
        case (slug: $slug) {
            id
            color
            slug
            image
            price
            createdAt
            isEvent
            name {
                en
                ru
            }
            items {
                name
                getImage
                color
                id
                price
            }
            seo {
                title { ru en }
                description { ru en }
                keywords
            }
            nextOpen
            needPromocode
            needDeposited
        }
    }
`

export const GET_BONUS = gql`
    query getPaymentBonus {
        getPaymentBonus {
            code,
            type,
            prize
        }
    }
`

export const GET_PROFILE = gql`
    query profileStatistics ($steamId: String!) {
        profileStatistics (steamId: $steamId) {
            getFavoriteCase {
                color
                slug
                image
                price
                name {
                    en
                    ru
                }
            }
            getBestDrop {
                id
                price
                createdAt
                item {
                    name
                    image
                    color
                }
            }
            opens
            upgrades
        }
        profile (steamId: $steamId) {
            steamId
            name
            avatar
        }
    }
`


export const GET_WITHDRAWS = gql`
    query withdrawHistory ($take: Int = 10, $skip: Int = 0) {
        withdrawHistory (take: $take, skip: $skip) {
            items {
                id
                type
                buyId
                tradeofferId
                price
                status
                createdAt
                item {
                    name
                    image
                    color
                }
            }
            total
        }
    }
`

export const GET_SITE_ITEMS = gql`
    query itemsList ($take: Int = 10, $skip: Int = 0, $name: String, $priceSort: SortOrder, $priceFrom: Float, $priceTo: Float) {
        itemsList (take: $take, skip: $skip, name: $name, priceSort: $priceSort, priceFrom: $priceFrom, priceTo: $priceTo) {
            items {
                name
                getImage
                color
                id
                price
            }
            total
        }
    }
`

// export const GET_NOTIFICATIONS = gql`
//     query notifications {
//         notifications {
//             id
//             type
//             createdAt
//             text {
//                 en
//                 ru
//             }
//             title {
//                 en
//                 ru
//             }
//         }
//     }
// `

// export const GET_INVENTORY = gql`
//     query userInventory ($steamId: String!, $take: Int = 10, $skip: Int = 0) {
//         userInventory (steamId: $steamId, take: $take, skip: $skip) {
//             items {
//                 id
//                 price
//                 createdAt
//                 item {
//                     name
//                     image
//                     color
//                 }
//                 status
//                 withdraw {
//                     id
//                     buyId
//                     tradeofferId
//                     status
//                 }
//             }
//             total
//         }
//     }
// `

// export const GET_PROCESS_INVENTORY = gql`
//     query processInventory ($take: Int = 10, $skip: Int = 0, $priceSort: SortOrder, $priceFrom: Float, $priceTo: Float) {
//         processInventory (take: $take, skip: $skip, priceSort: $priceSort, priceFrom: $priceFrom, priceTo: $priceTo) {
//             items {
//                 id
//                 price
//                 createdAt
//                 item {
//                     name
//                     image
//                     color
//                 }
//                 status
//                 withdraw {
//                     id
//                     buyId
//                     tradeofferId
//                     status
//                 }
//             }
//             total
//         }
//     }
// `

export const GET_INVENTORY = gql`
    query inventory ($take: Int = 10, $skip: Int = 0, $priceSort: SortOrder, $priceFrom: Float, $priceTo: Float, $status: OpenStatusEnum, $steamId: String!) {
        inventory(take: $take, skip: $skip, priceSort: $priceSort, priceFrom: $priceFrom, priceTo: $priceTo, status: $status, steamId: $steamId) {
            items {
                id
                price
                createdAt
                item {
                    name
                    getImage
                    color
                    stock
                }
                status
                withdraw {
                    id
                    buyId
                    tradeofferId
                    status
                }
            }
            total
        }
    }
`

export const MARKET_PRICE_LIST = gql`
    query marketPrices {
        marketPrices {
            name
            getImage
            color
            id
            price
            stock
        }
    }
`



export const GET_CONFIG = gql`
    query config {
        config {
            tgLink
            vkLink
            getPaymentConfig {
                enot
                freekassa
            }
            getUpgradeConfig {
                upgradeMinSum
            },
            title { ru en }
            description { ru en }
            keywords
            siteUrl
            siteName
        }
    }
`

export const GET_EVENT_CASES = gql`
    query eventCases {
        eventCases {
            id
            isEvent
            needDeposited
            color
            slug
            image
            price
            createdAt
            name { ru en }
            getImage
            items {
                id
                name
                image
                price
                color
                stock
                getImage
            }
            seo {
                title { ru en }
                description { ru en }
                keywords
            }
            background
            nextOpen
            needPromocode
        }
    }
`
