<template>
  <div class="cases wrapper-bg">
    <div class="title-wrapper">
      <div class="title">
        {{ cases.name[$root.currentLanguage] }}
      </div>
    </div>
    <div class="cases-list">
      <router-link :to="`/case/${caseItem.slug}`" class="case__item" :class="[caseItem.color]" v-for="(caseItem, i) in cases.cases" :key="i">
        <div class="shadow" :style="{'background': `#${caseItem.color}`, 'box-shadow': `0px -7px 90px 30px ${hexToRgbA('#' + caseItem.color, 0.3)}`}"></div>
        <div class="case__item-img">
          <img :src="`${caseItem.getImage}/500fx500f`" alt="">
        </div>
        <div class="case-bg">

          <img src="/img/case-blue-bg.png"  alt="">
        </div>
        <div class="case__item-info">
          <div class="case__item-name">
            {{ caseItem.name[$root.currentLanguage] }}
          </div>
          <div class="case__item-price">
            ₽
            <span>{{ parseFloat(caseItem.price) }}</span>
          </div>
        </div>
      </router-link>
    </div>
    <img v-if="cases.background" class="cases__image" :src="`https://rustfast.org/cdn-cgi/imagedelivery/IWwC2L3JFPDEcVB1-rHJPQ/${cases.background}/public`" alt="background">
  </div>
</template>
<script>
import hexToRgbA from "@/helpers/hexToRgba";

export default {
  methods: {hexToRgbA},
  props: {
    cases: {
      type: Object,
      required: true
    }
  }
}
</script>
<style scoped lang="scss">
.cases {
  margin-bottom: 10px;
  position: relative;
  overflow: hidden;
  .cases__image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
    top: 0;
  }
  .cases-list {
    padding-right: 46px;
    padding-left: 46px;
    margin: 20px auto 0;
    display: flex;
    justify-content: center;
    grid-gap: 20px;
    flex-wrap: wrap;
    padding-bottom: 30px;
    z-index: 1;
    position: relative;

    @media (max-width: 1600px) {
      padding-right: 20px;
      padding-left: 20px;
      flex-wrap: wrap;
      grid-template-columns: repeat(4, 1fr);
    }
    @media (max-width: 992px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 575px) {
      grid-template-columns: repeat(1, 1fr);
    }
    .case__item {
      background: #131419;
      border-radius: 5px;
      height: 302px;
      transition: 0.2s;
      max-height: 302px;
      position: relative;
      padding: 20px;
      overflow: hidden;
      width: calc(20% - 16px);
      background-image: url(/img/case-borders.png);
      background-size: 98% 98%;
      background-position: center center;
      background-repeat: no-repeat;
      @media (max-width: 1780px) {
        width: calc(25% - 15px);
      }
      @media (max-width: 1600px) {
        width: calc(33.333% - 15px);
      }
      @media (max-width: 992px) {
        width: calc(50% - 15px);
      }
      @media (max-width: 575px) {
        width: 100%;
      }
      .case-bg {
        position: absolute;
        top: 10px;
        display: flex;
        justify-content: center;
        img {
          filter: grayscale(1);
        }
        width: 80%;
        left: 10%;
      }
      .case__item-img {
        margin-top: 12px;
        width: 100%;
        position: relative;
        z-index: 10;
        text-align: center;
        display: flex;
        justify-content: center;
        img {
          width: 295px;
          margin-top: -35px;
        }
        align-items: center;
      }
      .case__item-info {
        position: absolute;
        left: 0;
        padding: 0 20px;
        z-index: 2;
        bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .case__item-name {
          font-weight: 700;
          font-size: 16px;
          line-height: 19px;
        }
        .case__item-price {
          background: rgba(255, 255, 255, 0.01);
          border: 1px solid rgba(255, 255, 255, 0.15);
          border-radius: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 10px 15px;
          font-weight: 700;
          font-size: 12px;
          line-height: 15px;
          letter-spacing: 0.05em;
          margin-left: 5px;
          span {
            font-weight: 700;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 0.05em;
            margin-left: 5px;
          }
        }
      }
      &::after {
        content: "";
        display: block;
        background-size: 100% 100%;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      .shadow {
        transition: 0.2s;
        display: block;
        bottom: 0;
        left: 8%;
        width: 84%;
        height: 2px;
        position: absolute;
        background: #FE5E3B;
      }
      &.green {
        .shadow {
          background: #599B4E;
          box-shadow: 0px -7px 90px 30px rgba(89, 155, 78, 0.3);
        }
        &:hover {
          &::before {
            box-shadow: 0px -7px 200px 60px rgba(89, 155, 78, 0.6);
          }
        }
      }
      &.blue {
        &::before {
          background: #418DFF;
          box-shadow: 0px -7px 90px 30px rgba(65, 141, 255, 0.3);
        }
        &:hover {
          &::before {
            box-shadow: 0px -7px 200px 60px rgba(65, 141, 255, 0.6);
          }
        }
      }
      &.red {
        &::before {
          background: #FE5E3B;
          box-shadow: 0px -7px 90px 30px rgba(254, 94, 59, 0.3);
        }
        &:hover {
          &::before {
            box-shadow: 0px -7px 200px 60px rgba(254, 94, 59, 0.6);
          }
        }
      }
      &.gray {
        &::before {
          background: gray;
        }
      }
      &.pink {
        &::before {
          background: #FF45E1;
          box-shadow: 0px -7px 90px 30px rgba(255, 69, 225, 0.3);
        }
        &:hover {
          &::before {
            box-shadow: 0px -7px 200px 60px rgba(255, 69, 225, 0.6);
          }
        }
      }
      &.lightGreen {
        &::before {
          background: #45FFFF;
          box-shadow: 0px -7px 90px 30px rgba(69, 255, 255, 0.3);
        }
        &:hover {
          &::before {
            box-shadow: 0px -7px 200px 60px rgba(69, 255, 255, 0.6);
          }
        }
      }
      &.yellow {
        &::before {
          background: #CDA12F;
          box-shadow: 0px -7px 90px 30px rgba(205, 161, 47, 0.3);
        }
        &:hover {
          &::before {
            box-shadow: 0px -7px 200px 60px rgba(205, 161, 47, 0.6);
          }
        }
      }
      &.purple {
        &::before {
          background: #C64CFF;
          box-shadow: 0px -7px 90px 30px rgba(198, 76, 255, 0.3);
        }
        &:hover {
          &::before {
            box-shadow: 0px -7px 200px 60px rgba(198, 76, 255, 0.6);
          }
        }
      }
      &:hover {
        transform: scale(1.05);
      }
    }
  }
}
</style>
