<template>
  <div class="case__item" :class="[color]">
    <div class="shadow" :style="{'background': `#${color}`, 'box-shadow': `0px -7px 90px 30px ${hexToRgbA('#' + color, 0.3)}`}"></div>
    <div class="case__item-img">
      <img :src="image" alt="">
    </div>
    <div class="case-bg">
      <img src="/img/case-blue-bg.png" alt="">
    </div>
  </div>
</template>
<script>
import hexToRgbA from "@/helpers/hexToRgba";

export default {
  methods: {hexToRgbA},
  props: {
    color: {
      type: String,
      required: true
    },
    image: {
      type: String,
      required: true
    }
  },
}
</script>
<style scoped lang="scss">
.case__item {
  background: #131419;
  border-radius: 5px;
  height: 302px;
  transition: 0.2s;
  max-height: 302px;
  position: relative;
  padding: 20px;
  overflow: hidden;
  background-image: url(/img/case-borders.png);
  background-size: 98% 98%;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  .case-bg {
    position: absolute;
    display: flex;
    justify-content: center;
    img {
      filter: grayscale(1);
    }
    width: 80%;
    left: 10%;
  }
  .case__item-img {
    width: 100%;
    position: relative;
    margin: auto;
    z-index: 10;
    text-align: center;
    display: flex;
    justify-content: center;
    img {
      max-width: 320px;
      width: 320px;
      min-width: 320px;
      margin-top: -28px;
    }
    align-items: center;
  }
  .case__item-info {
    position: absolute;
    left: 0;
    padding: 0 20px;
    z-index: 2;
    bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .case__item-name {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
    }
    .case__item-price {
      background: rgba(255, 255, 255, 0.01);
      border: 1px solid rgba(255, 255, 255, 0.15);
      border-radius: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 15px;
      span {
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.05em;
        margin-left: 5px;
      }
    }
  }
  &::after {
    content: "";
    display: block;
    background-size: 100% 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .shadow {
    content: "";
    transition: 0.2s;
    display: block;
    bottom: 0;
    left: 8%;
    width: 84%;
    height: 2px;
    position: absolute;
    background: #FE5E3B;
  }
  &.green {
    &::before {
      background: #599B4E;
      box-shadow: 0px -7px 90px 30px rgba(89, 155, 78, 0.3);
    }
    &:hover {
      &::before {
        box-shadow: 0px -7px 200px 60px rgba(89, 155, 78, 0.6);
      }
    }
  }
  &.blue {
    &::before {
      background: #418DFF;
      box-shadow: 0px -7px 90px 30px rgba(65, 141, 255, 0.3);
    }
    &:hover {
      &::before {
        box-shadow: 0px -7px 200px 60px rgba(65, 141, 255, 0.6);
      }
    }
  }
  &.red {
    &::before {
      background: #FE5E3B;
      box-shadow: 0px -7px 90px 30px rgba(254, 94, 59, 0.3);
    }
    &:hover {
      &::before {
        box-shadow: 0px -7px 200px 60px rgba(254, 94, 59, 0.6);
      }
    }
  }
  &.pink {
    &::before {
      background: #FF45E1;
      box-shadow: 0px -7px 90px 30px rgba(255, 69, 225, 0.3);
    }
    &:hover {
      &::before {
        box-shadow: 0px -7px 200px 60px rgba(255, 69, 225, 0.6);
      }
    }
  }
  &.lightGreen {
    &::before {
      background: #45FFFF;
      box-shadow: 0px -7px 90px 30px rgba(69, 255, 255, 0.3);
    }
    &:hover {
      &::before {
        box-shadow: 0px -7px 200px 60px rgba(69, 255, 255, 0.6);
      }
    }
  }
  &.yellow {
    &::before {
      background: #CDA12F;
      box-shadow: 0px -7px 90px 30px rgba(205, 161, 47, 0.3);
    }
    &:hover {
      &::before {
        box-shadow: 0px -7px 200px 60px rgba(205, 161, 47, 0.6);
      }
    }
  }
  &.purple {
    &::before {
      background: #C64CFF;
      box-shadow: 0px -7px 90px 30px rgba(198, 76, 255, 0.3);
    }
    &:hover {
      &::before {
        box-shadow: 0px -7px 200px 60px rgba(198, 76, 255, 0.6);
      }
    }
  }
  &:hover {
    transform: scale(1.05);
  }
}
</style>
